import React from "react";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const RangeSlider = props => {
  const { Handle } = Slider;

  let { translate, rangeObject } = props;
  let { radius, handleOnChange } = rangeObject;

  const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls='rc-slider-tooltip'
        overlay={`${value}`}
        visible={dragging}
        placement='top'
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <div className='wal-form wal-form-group'>
      <label htmlFor='radius'>{translate("lawyers.radius")}</label>
      <div className='wal-form-range'>
        <Slider
          value={radius}
          min={0}
          max={100}
          trackStyle={{ backgroundColor: "#bea58b", height: 4 }}
          handleStyle={{
            backgroundColor: "#bea58b",
            borderColor: "#bea58b",
            width: 15,
            height: 15,
            boxShadow: 'none'
          }}
          onChange={handleOnChange}
          handle={handle}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
