import React from "react";

import { Document, pdfjs } from "react-pdf";
import { getApiUrl, getToken } from "../../../helpers";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/" +
  pdfjs.version +
  "/pdf.worker.min.js";

class PdfReaderModal extends React.Component {
  constructor(props) {
    super(props);
  }

  onDocumentLoadSuccess() {}

  render() {
    return (
      <div
        className="modal fade modal-ge"
        id="pdf_reader"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: 1600 }}
        >
          <div className="modal-content">
            <div className="modal-ge-wrap">
              <div className="modal-ge-top">
                <Document
                  file={{
                    url: getApiUrl() + "contracts/183/download",
                    httpHeaders: {
                      Authorization: "Bearer " + getToken(),
                    },
                  }}
                  onLoadSuccess={() => this.onDocumentLoadSuccess()}
                />
              </div>
              <div className="login-close" data-dismiss="modal">
                <i className="icon-x"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfReaderModal;
