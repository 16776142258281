import React, { useState } from "react";
import { Link } from "react-router-dom";

import { bookmarkItem, deleteBookmarkItem } from "../../../helpers/bookmarkItem";
import { getToken } from "../../../helpers";

import Image from "../../../components/shared/Image";
import CategorySvg from "../../../components/shared/CategorySvg";
import DisplayRating from "../../../components/shared/DisplayRating";
import Verify from "../../../assets/img/icons/verify.svg";

const LawfirmCard = ({ item, reloadLawfirms }) => {
  const [isBookmarked, setIsBookmarked] = useState(item.is_bookmarked);

  const displayCategories = () => {
    const categories = item.categories;
    return categories.map(category => {
      if (category.parent_id) {
        return (
          <div style={{ marginRight: 10 }} title={category.lang?.name} key={category.id}>
            <CategorySvg index={category.parent_id} width={16} />
          </div>
        );
      } else {
        return (
          <div style={{ marginRight: 10 }} title={category.lang?.name} key={category.id}>
            <CategorySvg index={category.id} width={16} />
          </div>
        );
      }
    });
  };

  const triggerLawfirmBookmark = () => {
    if (getToken()) {
      reloadLawfirms && reloadLawfirms();
      isBookmarked
        ? deleteBookmarkItem(item.id, "company", data => setIsBookmarked(data === 0 ? false : true))
        : bookmarkItem(item.id, "company", data => setIsBookmarked(data === 0 ? false : true));
    } else {
      window.$("#login").modal("toggle");
    }
  };

  return (
    <div className='lawyers-item card-container' style={{flex: 1, overflow: 'hidden'}}>
      <div className='lawyers-img lawyers-img-alt'>
        <Image src={item?.logo} alt='lawfirm' />
      </div>
      <Link to={"/lawfirms/" + item.id}>
        <div className='lawyers-content'>
          <div className='lawyers-title'>
            <div className='lawyers-title-wrap'>
              <h1>{item.name}</h1>
              <div className='verify-badge'>
                {item.approved === 1 && <img src={Verify} alt='verified' />}
              </div>
            </div>
            <div className='lawyers-type'>{displayCategories()}</div>
          </div>
          <div className='lawyers-info'>
            <div className='rated-stars'>
              <DisplayRating stars={[0, 1, 2, 3, 4]} currentRate={item.rating} />
            </div>
            <span className='type description-text'>{item.description && item.description}</span>
            <span className='distance'></span>
            <span className='city'>
              {item.address}, {item.region}
            </span>
          </div>
        </div>
      </Link>
      <div className='lawyers-bookmarks'>
        <div onClick={() => triggerLawfirmBookmark()}>
          <i className={isBookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}></i>
        </div>
      </div>
    </div>
  );
};

export default LawfirmCard;
