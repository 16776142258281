import React
    from 'react';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';
import api from "../../../../../api";

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  reloadPage() {
    let { subscription } = this.props
    window.location.reload()
  }

  onChatClick = (event) => {
    event.preventDefault();
    let currentLawyer = this.props.item
    const _this = this;

    api.post('chat/conversations', {
        participant_id: currentLawyer.id
    }).then(result => {
        window.$('#chat-payment-modal').modal('toggle');
        _this.props.history.push('/chat/' + result.data.data.id)
    }).catch(err => {})
  }

  render() {
    let { translate } = this.props
    return (
      <div className="payment-modal-wrap modal-ge-wrap">
        <div className="modal-ge-content">
          <div className="modal-ge-icon">
            <i className="icon-done-check"></i>
          </div>
          <h1>{translate("payment.success")}</h1>
            <p>{translate("payment.bought_chat")}<br />{translate("payment.wish_to_proceed")}</p>
            <a onClick={this.onChatClick} className="button button-bl">{translate("payment.go_to_chat")}</a>
        </div>
        <div className="modal-ge-close" data-dismiss="modal" onClick={() => this.reloadPage()}><i className="icon-x"></i></div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(Success);
