import {
    LAWFIRM_PACKAGES
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case LAWFIRM_PACKAGES:
        return action.payload
      default:
        return state
    }
  }
