import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getToken } from "../helpers";
import { loadProfile } from "../actions/auth";

export const useCurrentUser = () => {
  const user = useSelector((state) => state.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getToken() && !user?.id) {
      dispatch(loadProfile());
    }
  }, [dispatch, user?.id]);
};
