import React from "react";

class PaginateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  displayIcon(link) {
    return link.label == "&laquo; Previous" ? this.displayBackIcon(link) : this.displayNextIcon(link)
  }

  displayBackIcon(link) {
    let { item } = this.props
    if(link.url && item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, item.current_page - 1)} className="wal-pagination-link">
          <i className='icon-back'></i>
        </a>
      )
    }
  }

  displayNextIcon(link) {
    let { item } = this.props
    if(link.url && item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, item.current_page + 1)} className="wal-pagination-link">
          <i className='icon-next'></i>
        </a>
      )
    }
  }

  displayLabel(link) {
    let { item } = this.props
    if(item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, link.label)} className="wal-pagination-link active">{link.label}</a>
      )
    }
  }

  displayPages() {
    let links = this.props.item.meta?.links
    return (
      links && links.map((link, index) => {
        if(links[0].url !== null || links[links.length-1].url !== null) {
          return (
            <li key={index} className={`wal-pagination-item ${link.active ? 'active' : ''}`}>
              { isNaN(link.label) ? this.displayIcon(link) : this.displayLabel(link)}
            </li>
          )
        }
      })
    )
  }

  render() {
    return (
      <div className="wal-pagination-wrap">
        <ul className="wal-pagination">
          {this.props.item && this.displayPages()}
        </ul>
      </div>
    );
  }
}

export default PaginateItem
