import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import moment from "moment";

import { getToken } from "../../../helpers";
import {
  getPackageComments,
  reviewPackage,
  deletePackageComment,
  commentOnPackage,
} from "../../../actions/packagesActions";

import ReviewItemModal from "../../../components/shared/modals/ReviewItemModal";
import DeleteAlertModal from "../../../components/shared/modals/DeleteAlertModal";
import DisplayRating from "../../../components/shared/DisplayRating";
import Image from "../../../components/shared/Image";

const PackageReviews = props => {
  const dispatch = useDispatch();
  const reviewItems = useSelector(s => s.packageComments);
  const user = useSelector(s => s.currentUser);

  const [reviewComment, setReviewComment] = useState("");
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [commentsPerPage, setCommentsPerPage] = useState(5);
  const [moreCommentsLoading, setMoreCommentsLoading] = useState(false);

  const translate = props.translate;
  const id = props.id;
  const isLoggedIn = getToken();

  const displayComments = () => {
    return reviewItems.data?.map(comment => {
      return (
        <div className='comments-item' key={comment.id}>
          <div className='comments-info'>
            <div className='comments-img'>
              <Image src={comment.user.avatar} alt='person' />
            </div>
            <div className='comments-info-wrap'>
              <h1>{comment.user.name}</h1>
              <span>{moment(comment.updated_at).format("DD MMM YYYY")}</span>
            </div>
          </div>
          <div className='comments-content'>
            <p>{comment.comment}</p>
            {user.id === comment.user.id && (
              <span className='delete' onClick={() => deleteReview(comment.id)}>
                {translate("packages.delete")}
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  const postReview = reviewData => {
    window.$("#review-modal").modal("toggle");
    reviewPackage(id, reviewData, () => toast.success(translate("reviews.review_success")));
    setReviewComment("");
  };

  const postComment = () => {
    if (reviewComment === "") return toast.error("Write your comment");
    setCommentsLoading(true);
    commentOnPackage(id, reviewComment, reviewProcessing);
  };

  const deleteReview = reviewId => {
    setCommentToDelete(reviewId);
    window.$("#delete").modal("toggle");
  };

  const successDelete = () => {
    window.$("#delete").modal("toggle");
    toast.success(translate("packages.comment_deleted_success"));
    setCommentsLoading(true);
    dispatch(getPackageComments(id, commentsPerPage, () => setCommentsLoading(false)));
  };

  const reviewProcessing = () => {
    dispatch(getPackageComments(id, commentsPerPage, () => setCommentsLoading(false)));
  };

  const onLoadMoreButtonHandler = () => {
    setMoreCommentsLoading(true);
    dispatch(getPackageComments(id, commentsPerPage + 5, () => setMoreCommentsLoading(false)));
    setCommentsPerPage(commentsPerPage + 5);
  };

  return (
    <div className='panel-body nav-content'>
      <div className='main-title'>
        <h2>{translate("contracts.details.reviews")}</h2>
        {isLoggedIn && props.isPurchased && (
          <a
            onClick={() => window.$("#review-modal").modal("toggle")}
            style={{ cursor: "pointer" }}
          >
            {props.isReviewed ? translate("reviews.update_review") : translate("reviews.review_package")}
          </a>
        )}
      </div>
      <div className='profile-reviews'>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.quality")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            currentRate={props.reviews.quality}
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.professionality")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            currentRate={props.reviews.professionality}
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.usability")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            currentRate={props.reviews.usability}
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.understandability")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            currentRate={props.reviews.understandability}
          />
        </div>
      </div>
      <div className='main-title'>
        <h2>{translate("contracts.details.comments")}</h2>
      </div>
      <div className='comments'>
        {isLoggedIn && (
          <div className='comments-head'>
            <div className='comments-img'>
              <Image src={user?.avatar} alt='person' />
            </div>

            <div className='comments-input'>
              <input
                type='text'
                value={reviewComment}
                onChange={t => setReviewComment(t.target.value)}
                className='wal-form-control'
                placeholder={translate("contracts.details.write_comment")}
              />
              <div className='comments-send'>
                <div className='button button-white' onClick={() => postComment()}>
                  <i className='icon-send'></i>
                  {translate("contracts.details.post")}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='comments-body'>
          {commentsLoading ? (
            <div style={{ marginLeft: "48%", marginBottom: 20 }}>
              <ReactLoading type='spin' color='#bea58b' />
            </div>
          ) : (
            displayComments()
          )}
        </div>
        {moreCommentsLoading ? (
          <div style={{ marginLeft: "48%" }}>
            <ReactLoading type='spin' color='#bea58b' />
          </div>
        ) : reviewItems.meta?.total > reviewItems.data?.length ? (
          <div className='more-button button button-gray' onClick={() => onLoadMoreButtonHandler()}>
            {translate("packages.load_more_reviews")}
          </div>
        ) : (
          <></>
        )}
      </div>
      <DeleteAlertModal
        title={translate("contracts.details.delete_comment")}
        description={translate("contracts.details.delete_comment_description")}
        confirmDelete={() => deletePackageComment(id, commentToDelete, () => successDelete())}
      />
      <ReviewItemModal translate={translate} postReview={reviewData => postReview(reviewData)} />
    </div>
  );
};

export default PackageReviews;
