import { BASE_DEV } from "../constants/types";
import {getServerUrl, getToken} from "../helpers";

export const socketConfig = {
    broadcaster: "pusher",
    key: "9a7d98as7da98d7",
    cluster: "mt1",
    wsHost: BASE_DEV, // BASE_APP
    wsPort: 6001,
    forceTLS: true,
    encrypted: true,
    wssPort: 443,

    authEndpoint: getServerUrl() + "api/broadcasting/auth",

    auth: {
        headers: {
            Authorization: "Bearer " + getToken(),
            Accept: "application/json",
            "X-App-ID": "456456",
        },
    },
}