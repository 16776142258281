import React
  from 'react'

import Person
  from '../../assets/img/person-7.png';

import ReactLoading
  from 'react-loading';

import { fetchNotificaions }
  from "../../actions/notificaionActions";

import Image
  from "../../components/shared/Image";

import { connect }
  from "react-redux";

import moment
  from 'moment';

class NotificatonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    await this.props.fetchNotificaions(this.stopLoading.bind(this))
  }

  stopLoading(){
    this.setState({loading:false})
  }

  displayNotificaions() {
    let { translate } = this.props

    return (
      this.props.notificaionList.map((notificaion) => {
        let { title, deliver_at, receiver, message, id} = notificaion

        return (
          <div key={id} className="header-notifications-item">
            <div className="header-notifications-right">
              <div className="header-notifications-img">
                <Image
                  src={receiver.avatar}
                  alt="person"
                />
              </div>
              <div className="header-notifications-info">
                <span><span className="info">{receiver.name}</span>
                <br /> 
                {/* {translate("header.invited_to_his_case")} */}
                <span className="info">“{title}”</span></span>
                <div className="header-notifications-buttons">
                  <a className="button button-primary">{translate("header.accept")}</a>
                  <a className="button button-white">{translate("header.decline")}</a>
                </div>
              </div>
            </div>
            <div className="header-notifications-left">
              <span className="time">{moment(deliver_at).endOf('day').fromNow()}</span>
            </div>
          </div>
        )
      })
    )
  }

  render() {
    let { translate } = this.props
    if(this.state.loading){
      return(
        <div style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="red"/>
        </div>
      )
    }

    if(!this.props.notificaionList.length) {
      return (
        <div style={{marginBottom: 10}}>
          {translate("header.notification_empty")}
        </div>
      )
    }

    return (
      <div>
        <div className="header-notifications-older">
          <h3>{translate('header.earlier')}</h3>
        </div>
        {this.displayNotificaions()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    notificaionList: state.notificaionList.data
  };
}

export default connect(mapStateToProps, { fetchNotificaions })(NotificatonComponent);
