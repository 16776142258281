import React
    from 'react';

import Person
    from '../../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../../components/auth'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { Details, AddCard, Success } from "./"

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "details"
    };
  }

  changeStatus(status) {
    this.setState({status: status})
  }

  render() {
    let { status } = this.state
    return (
      <div className="modal fade modal-ge payment-modal" id="payment-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            { status === "details" && <Details {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
            { status === "addCard" && <AddCard {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
            { status === "success" && <Success {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(Modal);
