import React from 'react';

import { Route } from 'react-router-dom';

import Home from './inside/explore';

import { Contracts, ContractsDetails } from './inside/contract';

import { Lawyers, LawyersDetails } from './inside/lawyer';

import { Knowledge, SingleArticle } from './inside/knowledge';

import { SettingScreen } from './inside/settings';

import { Court } from './inside/court';

import { CurrentProfile } from './inside/profile';

import NotificationScreen from './inside/NotificationScreen';

import Header from '../components/shared/Header';

import Footer from '../components/shared/Footer';

import { subscriptionInvoices } from '../helpers/subscription';

import LoginModal from '../components/shared/modals/LoginModal';

import { getTranslate } from 'react-localize-redux';

import { fetchCurrencies } from '../actions/currencyActions';

import { loadProfile } from '../actions/auth';

import { connect } from 'react-redux';

import { getToken } from '../helpers';

import SearchModal from '../components/shared/modals/search/SearchModal';

import Chat from './inside/chat/Chat';

import UpdateSocialUserModal from '../components/shared/modals/UpdateSocialUserModal';

import ChooseSubscriptionCompany from '../components/shared/modals/ChooseSubscriptionCompany';

import CallScreen from './inside/callScreen';

import LawFirms from './inside/lawfirms';

import LawfirmDetails from './inside/lawfirms/LawfirmDetails';

import PackageDetails from './inside/packages/PackageDetails';

import DataDeletion from './inside/DataDeletion';

import { getCurrency } from '../services/storeCurrency';
import Currencies from '../constants/currencies';
import { truncate } from 'fs';

class InsideApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      enter: false,
      invoices: [],
      displayModal: null,
    };
  }

  componentDidMount() {
    let { current_user } = this.props;
    if (getToken() && !current_user) {
      this.props.loadProfile();
    }
    // if(current_user && current_user.subscribed === 1) {
    //   this.fetchData()
    //   console.log(current_user.subscribed)
    // }
    // this.checkCompanyData(current_user)
    this.checkUserData(current_user);
    this.props.fetchCurrencies();
  }

  async fetchData() {
    await subscriptionInvoices(this.fetchSubscriptionInvoices.bind(this));
  }

  fetchSubscriptionInvoices(data) {
    if (data.data?.length > 0) {
      this.setState({ invoices: [data.data[0]] });
    }
    this.setState({ displayModal: true });
    window.$('#subscription-company').modal('toggle');
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let { current_user } = newProps;
    this.checkUserData(current_user);
    // this.checkCompanyData(current_user)
    if (current_user && current_user.user_role_id === 4 && current_user.subscribed === 0) {
      this.fetchData();
    }
    if (newProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  checkUserData(current_user) {
    if (current_user) {
      let { registration_source, phone, address, nationality_id } = current_user;
      if (registration_source == 'google' || registration_source == 'facebook') {
        if (!nationality_id || !address) {
          window.$('#login-with-social').modal('toggle');
        }
      }
    }
  }

  // async checkCompanyData(current_user) {
  //   if(current_user && current_user.user_role_id === 4 && current_user.subscribed === 1) {

  //   }
  // }

  // fetchMySubscription(data) {
  //   console.log(data)
  //   if(data.data.filter((sub) => sub.canceled_at == null).length == 0) {
  //     window.$('#subscription-company').modal('toggle');
  //   }
  // }

  render() {
    let { translate, current_user } = this.props;
    let { displayModal, invoices } = this.state;

    if (window.location.pathname === '/CallScreen') {
      return <Route exact path='/callScreen' component={CallScreen} />;
    } else {
      return (
        <>
          <Header
            modalClick={toggle => this.setState({ active: toggle, enter: true })}
            {...this.props}
            translate={translate}
          />
          <Route exact path='/' component={Home} />
          <Route
            exact
            path='/invite-friends'
            render={() => <Home modalClick={toggle => this.setState({ active: toggle, enter: true })} />}
          />
          <Route exact path='/profile' component={CurrentProfile} />
          <Route exact path='/contracts' component={Contracts} />
          <Route exact path='/contracts/:id' component={ContractsDetails} />
          <Route
            path='/court'
            render={props => (
              <Court {...props} translate={translate} onClick={toggle => this.setState({ active: toggle })} />
            )}
          />
          <Route exact path='/lawyers' component={Lawyers} />
          <Route
            exact
            path='/lawyers/:id'
            render={props => <LawyersDetails {...props} currentUserAvatar={current_user} />}
          />
          <Route exact path='/lawfirms' component={LawFirms} />
          <Route exact path='/lawfirms/:id' component={LawfirmDetails} />
          <Route exact path='/packages/:id' component={PackageDetails} />
          <Route exact path='/knowledge' component={Knowledge} />
          <Route exact path='/article/:id' component={SingleArticle} />
          <Route exact path='/settings' component={SettingScreen} />
          <Route exact path='/chat/:conversation_id?' component={Chat} />
          <Route exact path='/notifications' component={NotificationScreen} />
          <Route exact path='/social-network-deletion-request' component={DataDeletion} />

          {!window.location.pathname.includes('/chat') && (
            <Footer props={this.props} translate={translate} modalClick={toggle => this.setState({ active: toggle })} />
          )}

          {!getToken() && <LoginModal active={this.state.active} enter={this.state.enter} />}

          {getToken() && (
            <>
              <UpdateSocialUserModal translate={this.props.translate} />
              {displayModal && (
                <ChooseSubscriptionCompany
                  translate={translate}
                  subCurrency={invoices[0]?.currency || Currencies[getCurrency].name}
                  firstTime={invoices.length === 0}
                />
              )}
            </>
          )}
          <SearchModal />

          {/* <FirstTimeModal /> */}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    current_user: state.currentUser,
  };
}

export default connect(mapStateToProps, { fetchCurrencies, loadProfile })(InsideApp);
