import React from "react";

import Image from "../shared/Image";

import { IMAGE_BASE } from "../../constants/types";

import { Link } from "react-router-dom";

import ContractsSearched from "../contracts/ContractsSearched";

import DisplayRating from "../shared/DisplayRating";

import ReactHtmlParser from "react-html-parser";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

class ArticleNotLogged extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.inputShareRef = React.createRef();
  }

  onClickForChat(e) {
    e.preventDefault();
    window.$("#login").modal("toggle");
  }

  copyToClipboard(e) {
    e.preventDefault();
    this.inputShareRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  render() {
    let article = this.props.article;
    let { translate } = this.props;
    return (
      <main className="main">
        <title>{article.title} | WAL</title>
        <section
          className="profile pd-rl"
          style={{ backgroundImage: `url(${IMAGE_BASE + article.image})` }}
        >
          <div className="container-fluid">
            <div className="profile-wrap">
              <div className="profile-top">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="profile-top-wrap">
                      <a
                        onClick={this.props.goBack}
                        style={{ color: "#ffffff" }}
                        className="back-button"
                      >
                        <i className="icon-back"></i>
                        {translate("articles.details.back")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-bottom">
                <div className="row align-items-end">
                  <div className="col-md-12 col-lg-8">
                    <div className="profile-info">
                      <div className="profile-info-wrap">
                        {article.categories.length > 0 && (
                          <div className="profile-type articles-type">
                            <span className="type">
                              {article.categories[0].name}
                            </span>
                            {article.categories.length > 1 && (
                              <div className="more-list">
                                <span>
                                  +
                                  {
                                    article.categories.slice(
                                      1,
                                      article.categories.length
                                    ).length
                                  }
                                  more
                                </span>
                                <ul>
                                  {article.categories
                                    .slice(1, article.categories.length)
                                    .map((item, index) => {
                                      return <li key={index}>- {item.name}</li>;
                                    })}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="profile-name">
                          <h1>{article.title}</h1>
                        </div>
                        <div className="profile-rating-state">
                          <div className="profile-rating">
                            <div className="rated-stars">
                              {[1, 2, 3, 4, 5].map((item, index) => {
                                return (
                                  <i
                                    key={index}
                                    className={
                                      index + 1 > article.rating
                                        ? "icon-star"
                                        : "icon-star-filled"
                                    }
                                  ></i>
                                );
                              })}
                            </div>
                            <span>{article.rating}</span>
                          </div>
                          <div className="profile-type">
                            <span>
                              <i className="icon-eye"></i>
                              {article.viewed}{" "}
                              {translate("articles.details.views")}
                            </span>
                          </div>
                          <div className="profile-type">
                            <span>
                              <i className="icon-chat"></i>
                              {this.props.totalReviewsNumber}{" "}
                              {translate("articles.details.comments")}
                            </span>
                          </div>
                        </div>
                        {article.hashtags && (
                          <div className="articles-tags">
                            <ul>
                              {article.hashtags.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <a href="#">{item.tag}</a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="profile-buttons">
                      <a href="#" className="button button-primary" data-toggle="modal"
                                     data-target="#login"><i className="icon-eye"></i>{translate("articles.details.read_full_article")}</a>
                      <div className="wal-icons">
                        <div className="dropdown-options contracts-options-dropdown">
                          <div data-toggle="dropdown" data-display="static">
                            <a href="#" className="wal-icons-button options">
                              <i className="icon-share"></i>
                            </a>
                          </div>
                          <ul className="dropdown-menu">
                            <div className="more-options">
                              <span>
                                {translate(
                                  "articles.details.share_this_article"
                                )}
                              </span>
                            </div>
                            <li>
                              <a href="#">
                                <i className="icon-email"></i>
                                {translate("articles.details.mail")}
                              </a>
                            </li>
                            <li>
                              <LinkedinShareButton
                                url={window.location.href}
                                className="share"
                              >
                                <a href="#">
                                  <i className="icon-linkedin"></i>
                                  {translate("articles.details.linkedin")}
                                </a>
                              </LinkedinShareButton>
                            </li>
                            <li>
                              <TwitterShareButton
                                url={window.location.href}
                                className="share"
                              >
                                <a href="#">
                                  <i className="icon-twitter"></i>
                                  {translate("articles.details.twitter")}
                                </a>
                              </TwitterShareButton>
                            </li>
                            <li>
                              <FacebookShareButton
                                url={window.location.href}
                                className="share"
                              >
                                <a href="#">
                                  <i className="icon-facebook-alt"></i>
                                  {translate("articles.details.facebook")}
                                </a>
                              </FacebookShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="profile-content mg-top-40 pd-rl">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-8">
                <div className="profile-article articles-content">
                  <h3>{translate("articles.details.description")}</h3>
                  <p>{ReactHtmlParser(article.description)}</p>
                </div>
                {article.contracts.length > 0 && (
                  <div className="main-title">
                    <h2>{translate("articles.details.linked_contracts")}</h2>
                  </div>
                )}
                <div className="profile-contracts articles-contracts">
                  {article.contracts.length > 0 && (
                    <ContractsSearched items={article.contracts} />
                  )}
                </div>
                <div className="main-title">
                  <h2>{translate("articles.details.reviews")}</h2>
                  {/* <a href="#">{translate("articles.details.view_all")}</a> */}
                </div>
                <div className="profile-reviews">
                  <div className="profile-reviews-item">
                    <span>{translate("articles.details.quality")}</span>
                    <DisplayRating
                      stars={[0, 1, 2, 3, 4]}
                      currentRate={article.review && article.review.quality}
                    />
                  </div>
                  <div className="profile-reviews-item">
                    <span>{translate("articles.details.professionality")}</span>
                    <DisplayRating
                      stars={[0, 1, 2, 3, 4]}
                      currentRate={
                        article.review && article.review.professionality
                      }
                    />
                  </div>
                  <div className="profile-reviews-item">
                    <span>{translate("articles.details.experience")}</span>
                    <DisplayRating
                      stars={[0, 1, 2, 3, 4]}
                      currentRate={article.review && article.review.experience}
                    />
                  </div>
                  <div className="profile-reviews-item">
                    <span>{translate("articles.details.helpful")}</span>
                    <DisplayRating
                      stars={[0, 1, 2, 3, 4]}
                      currentRate={article.review && article.review.helpful}
                    />
                  </div>
                </div>
                <div className="comments">
                  <div className="comments-body">
                    {this.props.reviews.map((item, index) => {
                      return (
                        <div className="comments-item">
                          <div className="comments-info">
                            <div className="comments-img">
                              <Image
                                src={item.user && item.user.avatar}
                                alt="header"
                              />
                            </div>
                            <div className="comments-info-wrap">
                              <h1>{item.user && item.user.name}</h1>
                              <div className="comments-info-bottom">
                                <div className="profile-rating">
                                  <DisplayRating
                                    stars={[0, 1, 2, 3, 4]}
                                    currentRate={item.overall_rating}
                                  />
                                  <span>{item.overall_rating}</span>
                                </div>
                                <span>{item.created_at.slice(0, 10)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="comments-content">
                            <p>{item.comment || "No comment available."}</p>
                          </div>
                        </div>
                      );
                    })}

                    {this.props.currentReviewsPage <
                      this.props.lastReviewsPage && (
                      <a
                        onClick={this.props.moreReviews}
                        className="more-button button button-gray"
                      >
                        Load more reviews
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12 col-xl-4">
                <div className="profile-aside">
                  <div className="profile-aside-title">
                    <h3>{translate("articles.details.created_by")}</h3>
                  </div>
                  <div className="profile-create-item">
                    <div className="profile-create-right">
                      <div className="lawyers-img">
                        <Image src={article.lawyer?.avatar && article.lawyer.avatar} alt="header" />
                        <div
                          className={
                            article.lawyer.is_online
                              ? "status active"
                              : "status inactive"
                          }
                        ></div>
                      </div>
                      <div className="profile-create-content">
                        <h1>{article.lawyer.name}</h1>
                        <p>{translate("articles.details.lawyer")}</p>
                      </div>
                    </div>
                    <div className="profile-create-left">
                    <Link to={`/lawyers/${article.lawyer.id}`}><i className="icon-next"></i></Link>
                    </div>
                  </div>
                  <div className="profile-aside-date">
                    <h3>{translate("articles.details.published_on")}</h3>
                    <p>{article.created_at.slice(0, 10)}</p>
                  </div>
                  <div className="profile-aside-copy">
                    <div className="copy-link">
                      <input type="text" className="wal-form-control" ref={this.inputShareRef} value={`${window.location.href}`} readOnly />
                      <a href="#" className="copy" onClick={this.copyToClipboard.bind(this)}><i className="icon-copy"></i></a>
                    </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ArticleNotLogged;
