import React from "react";

import { connect } from "react-redux";

import { loadProfile } from "../../actions/auth";

import Image from "../../components/shared/Image";

import { IMAGE_BASE } from "../../constants/types";

import ReactLoading from 'react-loading';

import { fetchNotificaions } from "../../actions/notificaionActions";

class NotificationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      search:""
    };
  }

  async componentDidMount() {
    await this.props.fetchNotificaions(this.stopLoading.bind(this), this.state.search)
  }

  stopLoading(){
    this.setState({loading:false})
  }

  displayNotificaions() {
    if(this.state.loading){
      return(
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="red"/>
        </div>
      )
    }

    return (
      this.props.notificaionList.map((notificaion) => {
        let { title, deliver_at, receiver, message } = notificaion
        return (
          <div className="notifications-item">
            <div className="notifications-item-left">
              <div className="notifications-item-img">
                <Image
                  src={receiver.avatar}
                  alt="person"
                />
              </div>
              <div className="notifications-item-content">
                <h1><span>{receiver.name}</span> invited to his case <span>"{title}”</span></h1>
                <div className="notifications-item-info">
                  <p>{message}</p>
                  <p className="time">{deliver_at.slice(0,10)}</p>
                </div>
              </div>
            </div>
            <div className="notifications-item-right">
              <div className="notifications-item-buttons">
                <button className="button button-primary">Accept</button>
                <button className="button button-white">Decline</button>
              </div>
              <div className="notifications-item-delete">
                <a href="#"><i className="icon-delete"></i></a>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  emptyNotifications() {
   return (
      <div className="wal-result-none" style={{overflowY: "inherit"}}>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="wal-result-none-wrap">
              <div className="wal-result-none-icon">
                <i className="icon-notification"></i>
              </div>
              <h1>No notifications</h1>
              <p>Sorry you dont have any notifications yet, when you do,
                notifications will be shown here.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  searchNotifications(input){
    this.setState({search:input, loading:true},()=>{
      this.props.fetchNotificaions(this.stopLoading.bind(this), this.state.search)
    })

  }

  render() {
    return (
      <main className="main">
        <title>Notifications | WAL</title>
        <section className="notifications pd-rl">
          <div className="container-fluid">
            <div className="wal-content-head">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-8">
                  <div className="wal-content-title">
                    <h1>Notifications</h1>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="wal-form wal-form-group wal-form-search">
                    <input type="search" name="Search notification"
                    placeholder="Search notification"
                    value={this.state.search}
                    onChange={(event)=> this.searchNotifications(event.target.value)}
                    className="wal-form-control"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none" />
                  </div>
                </div>
              </div>
            </div>
            <div className="notifications-list">
              <div className="notifications-list-wrap">
                {this.emptyNotifications()}
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificaionList: state.notificaionList.data
  };
}

export default connect(mapStateToProps, { fetchNotificaions })(NotificationScreen);
