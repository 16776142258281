import React from "react";

import PurchasesCard from "../settings/profile/PurchasesCard";
import ReactLoading from "react-loading";

const PurchasesSection = props => {
  return (
    <div className='tab-pane' id='about'>
      <div className='panel panel-default'>
        <div className='panel-heading'>
          <h4 className='panel-title nav-list'>
            <a
              data-toggle='collapse'
              data-target='#collapse2'
              aria-expanded='false'
              data-parent='#tabs'
            >
              {props.translate("profile.purchases")}
            </a>
          </h4>
        </div>
        <div id='collapse2' className='panel-collapse collapse' data-parent='#tabs'>
          <div className='panel-body nav-content'>
            <div className='profile-contracts'>
              {props.interactions.data &&
                props.interactions.data.map((item, index) => <PurchasesCard key={index} item={item} />)}
              {props.interactions.meta?.total > props.interactions.data?.length && (
                <>
                  {props.moreLoading ? (
                    <div style={{ marginLeft: "48%" }}>
                      <ReactLoading type='spin' color='#bea58b'/>
                    </div>
                  ) : (
                    <a onClick={props.loadMore} className='more-button button button-gray'>
                      {props.translate("lawyers.details.load_more")}
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasesSection;
