import React
  from "react";

import { connect }
  from "react-redux";

import Pdf from
  '../../assets/files/terms_and_conditions.pdf';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-page">
            <div className="settings-page-wrap">
              <h4>General Terms and Conditions</h4>
              <ul>
                <li><span>Legal Tech GmbH</span></li>
                <li>Haldenstrasse 5</li>
                <li>6340 Baar</li>
                <li><a href="mailto:info@wal.world">info@wal.world</a></li>
              </ul>
              <h3><span>1. Contracting Parties and Applicability</span></h3>
              <p>1.1. WAL International AG, Zug ("WAL" or "provider") is a provider of services in context of comparison and agency platforms as well as legal services. In connection with the use of these services by the contractual party, the provider and the contractual party conclude a contract. The contractual party and the provider are each a "party" and together the "parties" to this Agreement. The male term always includes the female term.</p>
              <p>1.2. These General Terms and Conditions ("GTC") for services in connection with the present platform are part of the contract concluded between the parties. In the event of contradictions, the individual contract takes precedence over the GTC.</p>
              <p>1.3. The provider is entitled to amend these GTC at any time. The contractual party may view the current GTC on the platform or order a copy from the provider. The contractual partner confirms that he has taken note of and accepted the current version of the GTC with every change to the content, the purchase of new services or the payment of every invoice. Section 9 of these GTC shall apply to the adjustment of services and remuneration </p>
              <h3><span>2. Definitions</span></h3>
              <p><b>Attorney (-user):</b> An attorney admitted to the bar according to the relevant national provisions, who is entitled to represent persons professionally at all instances before judicial and administrative authorities and is registered as an attorney on the platform with a valid subscription.</p>
              <p><b>Client:</b> A private person or company who uses the services of WAL and does not fall under any other category of users for the specific service.</p>
              <p><b>Content:</b> Information of the contractual party (e.g. name/company, address, telephone number, e-mail, opening hours) as well as other contents (e.g. attributes, keywords, images and texts, contracts, templates, qualification certificates, titles, etc.), which the contractual party provides to the provider in the user account whether or not for the purpose of publication on the platform, including subsequent changes and additions as well as evaluations according to section 14. Contract: The contract between the contractual party and the provider concerning the services in connection with the platform, including these GTC.</p>
              <p><b>Lawyer (-user):</b> A person who, according to the relevant national regulations, has completed and passed the study of law as a major subject and is registered as a lawyer on the platform with a valid subscription. </p>
              <p><b>Publication:</b> Online publication of content on the provider's platform.</p>
              <p><b>Platform:</b> The service platform of WAL, advertised by the provider under the domain www.wal.world (or any related website) and made available on the smartphone / tablet / web application.</p>
              <p><b>Referee (-user):</b> A person who has agreed to settle disputes and is registered on the platform as referee with a valid subscription.</p>
              <p><b>Services:</b> The free and paid services offered by the provider in connection with the platform.</p>
              <p><b>Student-Lawyer (-user):</b> A person who is matriculated at a recognized university and is studying law as a major subject.</p>
              <p><b>Support-Partner:</b> The affiliated companies and / or other third parties engaged by the provider for the provision of the services.</p>
              <p><b>User:</b> All users of the platform (Client, Attorney, Lawyer, Student-lawyer, Referee, etc.).</p>
              <div className="settings-download">
                <a href={Pdf} className="button button-primary" download><i className="icon-download"></i>Download PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(TermsAndConditions);
