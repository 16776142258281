import axios from "axios";
import get from "lodash/get";

import { getApiUrl, getToken, setToken, isAuth, deepStateMutator } from "../helpers";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: getApiUrl(),
  
  headers: {
    Authorization: isAuth() ? "Bearer " + getToken() : null,
  },

  validateStatus: function (status) {
    switch (status) {
      case 200:
        return true;
      case 401:
      default:
        return true;
    }
  },

  responseType: "json",
});

api.interceptors.response.use((response) => {
  switch (response.status) {
    case 200:
      return response;
    case 500:
      toast.error("Something went wrong!");
      return Promise.reject({ data: response.data || {}, status: response.status });
    case 429:
      toast.error("Too many attempts. Please try again in a few minutes");
      return Promise.reject({ data: response.data || {}, status: response.status });

    case 422:
      toast.error(response.data.message || "Invalid data");
      let errors = {};
      let responseErrors = get(response, "data.errors", {});
      for (let key in responseErrors) {
        errors = { ...deepStateMutator(errors, { name: key, value: responseErrors[key][0] }) };
      }

      return Promise.reject({ data: { errors } || {}, status: response.status });
    case 401:
    default:
      return Promise.reject({ data: response.data || {}, status: response.status });
  }
});

api.interceptors.request.use((config) => {
  config.headers.Authorization = isAuth() ? "Bearer " + getToken() : null;

  return config;
});

export const getDynamicData = async (basePath, filters = []) => {
  try {
    let url = basePath + "?";
    for (let element of filters) {
      if (element.value) {
        url += `${element.key}=${element.value}`;
      }
    }
    const result = await api.get(url);
    return result.data.data;
  } catch (err) {
    // console.log({ err });
  }
};

export default api;
