import React, {useState} from "react";

import Missing from "../../assets/img/missing.png";

import { IMAGE_BASE } from "../../constants/types";

const Image = (props) => {
  const [missingImage, setMissingImage] = useState(false);
  return (
    <img
      src={missingImage ? Missing : props.src ? IMAGE_BASE + props.src : Missing}
      alt={props.alt || "default"}
      onError={() => setMissingImage(true)}
    />
  );
};

export default Image;
