import React from "react";

import Image
  from '../../components/shared/Image'

import { Link }
  from "react-router-dom";
import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { fetchLawyersByRegion, fetchLawyersForMap }
  from "../../actions/lawyersActions";

import LocationSearchInput
  from '../../components/lawyers/PlacesAutoComplete';

import ReactLoading
  from 'react-loading';

import LawyerInRegion
  from '../../components/lawyers/LawyersInRegion';

import Maps
  from '../../components/lawyers/Map'

import MapDisabled
  from '../../components/shared/MapDisabled'

import MapImage
  from '../../assets/img/map.png';

class LawyersInYourRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat:null,
      long:null,
      lawyerByRegionLoading:true,
    };
  }

  async componentDidMount() {
    await this.handleCoords()
  }

  myCallback = (dataFromChild) => {
    this.setState({
      lat:dataFromChild[1].lat,
      long:dataFromChild[1].lng
    })
  }

  callLawyerRegionFunction(){
    this.props.fetchLawyersForMap(this.state.lat, this.state.long)
  }

  handleCoords(){
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        long: position.coords.longitude,
        lawyerByRegionLoading:false,
      }, this.callLawyerRegionFunction)
    });
  }

  searchAreaPress(){
    this.setState({mapLoading:true})
    setTimeout(() => {
      this.props.fetchLawyersForMap(this.state.lat, this.state.long, () => this.setState({mapLoading: false}))
    }, 1000);
  }

  displayMap() {
    let { translate, withSearchSection, cover } = this.props

    if(this.state.mapLoading|| this.state.lat == null) {
      return <MapDisabled translate={translate} />
    }

    return (
      <>
        { !withSearchSection &&
          <div className="main-title">
            <h1>{translate('lawyers.lawyers_in_your_region')}</h1>
            <Link to={`/lawyers`}>{translate("home.view_more")}</Link>
          </div>
        }
        { withSearchSection &&
          <div className="wal-content-head">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6">
                <div className="wal-content-title">
                  <h1>{translate('lawyers.lawyers_in_your_region')}</h1>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="wal-form wal-form-group wal-form-search">
                  <LocationSearchInput  callbackFromParent={this.myCallback}/>
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="wal-content-button">
                  <button onClick={()=> this.searchAreaPress()} className="button button-primary">{translate('lawyers.search')}</button>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="wal-content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-4">
              <div className="wal-results lawyers-region">
                { this.state.lawyerByRegionLoading
                  ?
                  <div style={{position: 'absolute', height: '50%',
                               width: '100%', display: 'flex',
                              justifyContent: 'center', alignItems: 'center'}}>
                  <ReactLoading type="spin" color="#bea58b"/>
                </div>
                  :
                  <div className="wal-results-wrap">
                    { this.props.lawyersForMap.data && this.props.lawyersForMap.data.length > 0
                      ?
                        this.props.lawyersForMap.data.map((item, index) => {
                          return (
                            <LawyerInRegion
                              key={index}
                              id={item.id}
                              name={item.name}
                              imageSource={item.avatar}
                              />
                          )
                        })
                      :
                       <p>{translate('lawyers.no_lawyers_on_region')}</p>
                     }
                  </div>
                  }
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-8">
                <div className="lawyers-region-map">
                  { (this.state.mapLoading|| this.state.lat == null) &&
                    <div style={{ position: 'absolute', zIndex:2, background:'rgba(0,0,0,0.5)', height: '100%',
                                  width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <ReactLoading type="spin" color="#bea58b"/>
                    </div>
                  }
                  {this.state.lat !== null ?
                    <Maps
                      lat={this.state.lat}
                      long={this.state.long}
                      lawyers={this.props.lawyersForMap.data}/>
                    :
                    <img
                      src={MapImage}
                      alt="person" />
                    }

                    {/* <div className="lawyers-region-map-button">
                      <button onClick={()=> this.searchAreaPress()}
                       className="button button-bl">{translate('lawyers.search_on_this_area')}</button>
                    </div> */}
                </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    let { translate, withSearchSection, cover } = this.props
    return (
      <section className={`wal-content ${cover} pd-rl`}>
        <div className="container-fluid">
          {this.displayMap()}
        </div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            lawyersForMap:state.lawyersForMap
          })
}

export default connect(mapStateToProps, { fetchLawyersByRegion, fetchLawyersForMap })(LawyersInYourRegion);
