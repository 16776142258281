import React from 'react';

import { Link } from 'react-router-dom';

import ContractsSmall from '../../../components/home/ContractsSmall';

import LawyerInfoWide from '../../../components/shared/LawyerInfoWide';

import Hero from '../../../assets/img/hero-img.png';

import DownloadAppSection from '../../../components/shared/DownloadAppSection';

import HeroBackground from '../../../assets/img/hero-bg.jpg';

import { getTranslate } from 'react-localize-redux';

import { fetchTopRatedLawyers } from '../../../actions/lawyersActions';

import { fetchContracts } from '../../../actions/contractsActions';

import { connect } from 'react-redux';

import _ from 'lodash';

import ReactLoading from 'react-loading';

import ContractCard from '../../../components/contracts/ContractCard';

import { numberOfLawyers } from '../../../helpers/lawyers';

import walVideoImg from '../../../assets/img/video-img.png';

import WaleXplainedVideoPopup from '../../../components/home/WalExplainedVideoPopup';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topRatedLawyersLoading: true,
      contractsLoading: true,
      numberOfLawyers: 0,
      showVideo: false,
    };
  }

  componentDidMount() {
    if (this.props.modalClick) {
      window.$('#login').modal('toggle');
      this.props.modalClick(1);
    }
    numberOfLawyers(this.fetchNumberOfLawyers.bind(this));
    this.requestData();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.allContracts !== newProps.allContracts) {
      window.slider();
    }
  }

  fetchNumberOfLawyers(data) {
    this.setState({ numberOfLawyers: data?.data?.total_number_of_lawyers });
  }

  async requestData() {
    await this.props.fetchTopRatedLawyers(this.topRatedLawyersStopLoading.bind(this));
  }

  async topRatedLawyersStopLoading() {
    this.setState({ topRatedLawyersLoading: false });
    await this.props.fetchContracts(this.contractsStopLoading.bind(this));
  }

  contractsStopLoading() {
    this.setState({ contractsLoading: false });
  }

  displaySliderItems(topRatedLawyers) {
    const lawyers = topRatedLawyers && topRatedLawyers.data;
    return (
      lawyers &&
      lawyers.map(i => {
        return <LawyerInfoWide id={i.id} name={i.name} key={i.id} imageSource={i.avatar} rating={i.rating} />;
      })
    );
  }

  displayNumerOfLawyers() {
    let { numberOfLawyers } = this.state;
    if (numberOfLawyers > 150) {
      return numberOfLawyers;
    } else {
      return 150;
    }
  }

  handleVideoClick() {
    this.setState(prevState => {
      return { ...this.state, showVideo: !prevState.showVideo };
    });
  }

  render() {
    let { translate } = this.props;
    let contracts = this.props?.allContracts && this.props.allContracts;

    return (
      <div>
        <title>{translate('home.app_title')} | WAL</title>
        {this.state.showVideo ? (
          <WaleXplainedVideoPopup handleVideoClick={this.handleVideoClick.bind(this)} />
        ) : undefined}
        <main className='main'>
          <section className='hero pd-rl' style={{ backgroundImage: `url(${HeroBackground})` }}>
            <div className='container-fluid'>
              <div className='row align-items-center hero-wrap'>
                <div className='hero-img'>
                  <img src={Hero} alt='hero-img' />
                </div>
                <div className='col-md-6 col-lg-5'>
                  <div className='hero-content'>
                    <h1>{translate('home.conent_title')}</h1>
                    <p>{translate('home.content_description')}</p>
                    <div className='hero-button'>
                      <Link className='button button-transparent button-transparent-border' to={`/lawyers`}>
                        <i className='icon-lawyers'></i>
                        {translate('home.buttons.view_lawyers')}
                      </Link>
                      <Link className='button button-transparent button-transparent-border' to={`/contracts`}>
                        <i className='icon-contracts'></i>
                        {translate('home.buttons.view_contracts')}
                      </Link>
                      <button
                        type='button'
                        className='wal-video-button mobile popup-video'
                        href='https://www.youtube.com/watch?v=wIekLj5Eehs'
                        onClick={() => this.handleVideoClick()}
                      >
                        {translate('home.wal_explained')}
                        <svg width='16' height='16'>
                          <path d='M8 1.6c1.977 0 3.622.058 4.937.174a3.067 3.067 0 0 1 2.796 3.055v6.342a3.067 3.067 0 0 1-2.796 3.055c-1.14.1-2.527.157-4.164.17L8 14.4c-1.977 0-3.622-.058-4.937-.174A3.067 3.067 0 0 1 .267 11.17V4.829a3.067 3.067 0 0 1 2.796-3.055c1.14-.1 2.527-.157 4.164-.17zm0 .8c-1.955 0-3.578.057-4.867.171A2.267 2.267 0 0 0 1.067 4.83v6.342c0 1.174.897 2.154 2.066 2.258 1.117.099 2.485.155 4.103.168L8 13.6c1.955 0 3.578-.057 4.867-.171a2.267 2.267 0 0 0 2.066-2.258V4.829a2.267 2.267 0 0 0-2.066-2.258c-1.117-.099-2.485-.155-4.103-.168zM6.883 5.818c.088 0 .174.025.247.071L9.89 7.63a.433.433 0 0 1 0 .74l-2.76 1.74a.462.462 0 0 1-.628-.129.432.432 0 0 1-.073-.24V6.259c0-.243.203-.44.454-.44z' />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-lg-7'>
                  <div className='hero-lawyers'>
                    <span>+{this.displayNumerOfLawyers()}</span>
                    <p>{translate('home.lawyers_in_wal')}</p>
                  </div>
                </div>
              </div>
              <div className='wal-video'>
                <div className='wal-video-title'>
                  <h1>{translate('home.wal_explained')}</h1>
                </div>
                <div className='wal-video-content'>
                  <button
                    type='button'
                    className='wal-video-button popup-video'
                    onClick={() => this.handleVideoClick()}
                    href='https://www.youtube.com/watch?v=wIekLj5Eehs'
                  >
                    {translate('home.wal_explained')}
                    <svg width='16' height='16'>
                      <path d='M8 1.6c1.977 0 3.622.058 4.937.174a3.067 3.067 0 0 1 2.796 3.055v6.342a3.067 3.067 0 0 1-2.796 3.055c-1.14.1-2.527.157-4.164.17L8 14.4c-1.977 0-3.622-.058-4.937-.174A3.067 3.067 0 0 1 .267 11.17V4.829a3.067 3.067 0 0 1 2.796-3.055c1.14-.1 2.527-.157 4.164-.17zm0 .8c-1.955 0-3.578.057-4.867.171A2.267 2.267 0 0 0 1.067 4.83v6.342c0 1.174.897 2.154 2.066 2.258 1.117.099 2.485.155 4.103.168L8 13.6c1.955 0 3.578-.057 4.867-.171a2.267 2.267 0 0 0 2.066-2.258V4.829a2.267 2.267 0 0 0-2.066-2.258c-1.117-.099-2.485-.155-4.103-.168zM6.883 5.818c.088 0 .174.025.247.071L9.89 7.63a.433.433 0 0 1 0 .74l-2.76 1.74a.462.462 0 0 1-.628-.129.432.432 0 0 1-.073-.24V6.259c0-.243.203-.44.454-.44z' />
                    </svg>
                  </button>
                  <div
                    className='wal-video-frame popup-video'
                    href='https://www.youtube.com/watch?v=wIekLj5Eehs'
                    onClick={() => this.handleVideoClick()}
                  >
                    <img src={walVideoImg} alt='video' />
                    <svg width='62' height='43' className='play-button'>
                      <g fill='none' fillRule='evenodd' opacity='.7'>
                        <rect fill='#4B4B4B' width='62' height='43' rx='13' />
                        <path
                          d='M26.196 12c.382 0 .717.107 1.03.288.178.101 11.132 6.844 11.53 7.258.398.417.617 1.015.617 1.653 0 .405-.1.748-.274 1.08-.142.266-.32.527-.55.717-.373.31-11.393 7.16-11.667 7.258-.223.08-.436.144-.686.144a2.156 2.156 0 0 1-1.441-.576A2.295 2.295 0 0 1 24 28.099v-13.8C24 13.03 24.983 12 26.196 12Z'
                          fill='#FFF'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='rated-lawyers mg-top-50 pd-rl'>
            <div className='container-fluid'>
              <div className='main-title'>
                <h1>{translate('home.top_rated_lawyers')}</h1>
                <Link to={`/lawyers`}>{translate('home.view_more')}</Link>
              </div>
              <div className='rated-lawyers-wrap'>
                {this.state.topRatedLawyersLoading ? (
                  <ReactLoading type='spin' color='#bea58b' />
                ) : (
                  this.displaySliderItems(this.props.topRatedLawyers)
                )}
              </div>
            </div>
          </section>

          <section className='contracts mg-top-112 pd-rl'>
            <div className='container-fluid'>
              <div className='main-title'>
                <h1>{translate('home.contracts_and_templates')}</h1>
                <Link to={`/contracts`}>{translate('home.view_more')}</Link>
              </div>
              <div className='row'>
                {this.state.contractsLoading ? (
                  <ReactLoading type='spin' color='#bea58b' />
                ) : (
                  contracts?.data &&
                  contracts.data.map((item, index) => {
                    return (
                      <div key={index} className='col-md-12 col-lg-12 col-xl-6'>
                        <ContractCard fromLandingPage={true} item={item} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </section>

          <DownloadAppSection translate={translate} />
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    topRatedLawyers: state.topRatedLawyers,
    allContracts: state.allContracts,
  };
}

export default connect(mapStateToProps, {
  fetchTopRatedLawyers,
  fetchContracts,
})(LandingPage);
