import React from "react";

import { getOrderHistory }
  from '../../../helpers/historyOrder'

import OrderCard
  from './OrderCard'

import ReactLoading
  from 'react-loading';

import NotFoundItems
  from './NotFoundItems'

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      contactsItems: []
    };
  }

  componentDidMount() {
    getOrderHistory(this.fetchOrderHistory.bind(this), { type: "contract" })
  }

  componentWillReceiveProps(newProps) {
    if( newProps.search !== this.props.search ||
        newProps.sort !== this.props.sort ||
        newProps.startDate !== this.props.startDate ||
        newProps.endDate !== this.props.endDate ||
        newProps.page !== this.props.page ) {
      this.setState({loading: true})
      getOrderHistory(this.fetchOrderHistory.bind(this),
        {
          type: "contract",
          page: newProps.page,
          search: newProps.search,
          sort: newProps.sort,
          startDate: newProps.startDate,
          endDate: newProps.endDate
        }
      )
    }
  }

  fetchOrderHistory(data) {
    this.setState({contactsItems: data, loading: false})
    this.props.addObjectItems(data)
  }

  displayOrders() {
    let { loading, contactsItems } = this.state
    let { data } = contactsItems
    let { translate } = this.props

    if(data.length > 0) {
      return (
        data.map((item) => {
          return (
            <OrderCard
              key={item.id}
              item={item}
            />
          )
        })
      )
    } else {
      return (
        <NotFoundItems
          icon={"contracts"}
          objectName={"contracts"}
          title={translate("settings.order_history.not_found_items.contacts_title")}
          description={translate("settings.order_history.not_found_items.description")}
        />
      )
    }
  }

  render() {
    let { loading } = this.state
    if(loading) {
      return (
        <div style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <div className="settings-history-list">
        <div className="settings-history-list-wrap">
          {this.displayOrders()}
        </div>
      </div>
    )
  }
}

export default Contracts;
