import React, { useState } from "react";

import DisplayRating from "../DisplayRating";

const ReviewItemModal = ({ translate, postReview }) => {
  const [quality, setQuality] = useState(3);
  const [professionality, setProfessionality] = useState(3);
  const [usability, setUsability] = useState(3);
  const [understandability, setUnderstandability] = useState(3);

  return (
    <div
      className='modal fade modal-ge currency-modal'
      id='review-modal'
      data-backdrop='static'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='currency-modal-wrap modal-ge-wrap'>
            <div className='modal-ge-top payment-modal-top'>
              <h1>{translate("lawyers.details.review")}</h1>
            </div>
            <div className='modal-ge-body currency-modal-body'>
              <div className='profile-reviews-item'>
                <span>{translate("contracts.details.quality")}</span>
                <DisplayRating
                  stars={[0, 1, 2, 3, 4]}
                  stateType={quality}
                  changeStateType={index => setQuality(index)}
                  changeMode
                />
              </div>
              <div className='profile-reviews-item'>
                <span>{translate("contracts.details.professionality")}</span>
                <DisplayRating
                  stars={[0, 1, 2, 3, 4]}
                  stateType={professionality}
                  changeStateType={index => setProfessionality(index)}
                  changeMode
                />
              </div>
              <div className='profile-reviews-item'>
                <span>{translate("contracts.details.usability")}</span>
                <DisplayRating
                  stars={[0, 1, 2, 3, 4]}
                  stateType={usability}
                  changeStateType={index => setUsability(index)}
                  changeMode
                />
              </div>
              <div className='profile-reviews-item'>
                <span>{translate("contracts.details.understandability")}</span>
                <DisplayRating
                  stars={[0, 1, 2, 3, 4]}
                  stateType={understandability}
                  changeStateType={index => setUnderstandability(index)}
                  changeMode
                />
              </div>
            </div>
            <div className='modal-ge-bottom'>
              <button
                className='button button-primary'
                onClick={() =>
                  postReview({
                    reviewComment: "empty",
                    quality,
                    professionality,
                    usability,
                    understandability,
                  })
                }
              >
                {translate("lawyers.details.review_add")}
              </button>
            </div>
            <div className='modal-ge-close' data-dismiss='modal'>
              <i className='icon-x'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewItemModal;
