import React from "react";
import Slider, { Range, SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const TwoRangeSlider = props => {
  const { Handle } = Slider;

  let { translate, priceObject } = props;
  let { priceRange, handleOnChange } = priceObject;

  const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls='rc-slider-tooltip'
        overlay={`${value}`}
        visible={dragging}
        placement='top'
        key={index}
      >
        <Handle
          value={value}
          {...restProps}
          style={{
            backgroundColor: "#bea58b",
            border: "none",
            boxShadow: "none",
            width: 15,
            height: 15,
          }}
        />
      </SliderTooltip>
    );
  };

  return (
    <div className='wal-form wal-form-group'>
      <label htmlFor='radius'>{translate("packages.price_range")}</label>
      <div className='wal-form-range'>
        <Range
          defaultValue={priceRange}
          min={0}
          max={99}
          trackStyle={[{ backgroundColor: "#bea58b", height: 4 }]}
          onChange={handleOnChange}
          handle={handle}
        />
      </div>
    </div>
  );
};

export default TwoRangeSlider;
