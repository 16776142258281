import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTranslate } from "react-localize-redux";
import ReactLoading from "react-loading";
import debounce from "lodash.debounce";

import { getLawfirmsById } from "../../../actions/lawfirmsActions";
import { fetchLawyersByFilter } from "../../../actions/lawyersActions";
import { fetchArticlesByFilter } from "../../../actions/articlesActions";
import { fetchContractsByFilter } from "../../../actions/contractsActions";
import { bookmarkItem, deleteBookmarkItem } from "../../../helpers/bookmarkItem";
import { getLawfirmPackages } from "../../../actions/packagesActions";
import { getToken } from "../../../helpers";
import api from "../../../api";

import PackageTab from "../lawyer/tabs/PackageTab";
import { PaymentModal } from "../../../components/shared/modals/payment/chat";
import { Modal } from "../../../components/shared/modals/payment";
import ReportLawyerModal from "../../../components/shared/modals/ReportLawyerModal";
import LawyerCard from "../../../components/lawyers/LawyerCard";
import KnowledgeCard from "../../../components/lawyers/KnowledgeCard";
import ContractsSearched from "../../../components/contracts/ContractsSearched";
import CategorySvg from "../../../components/shared/CategorySvg";
import Image from "../../../components/shared/Image";
import Maps from "../../../components/lawyers/Map";
import { IMAGE_BASE } from "../../../constants/types";

const LawfirmDetails = props => {
  const dispatch = useDispatch();
  const translate = useSelector(s => getTranslate(s.locale));
  const lawfirm = useSelector(s => s.lawfirmsById);
  const lawyers = useSelector(s => s.lawyersByFilter);
  const articles = useSelector(s => s.guestArticles);
  const contracts = useSelector(s => s.contractsWithFilters);
  const lawfirmPackages = useSelector(s => s.lawfirmPackages);

  const [lawyersToDisplay, setLawyersToDisplay] = useState(5);
  const [contractsToDisplay, setContractsToDisplay] = useState(4);
  const [articlesToDisplay, setArticlesToDisplay] = useState(6);
  const [packagesToDisplay, setPackagesToDisplay] = useState(4);

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [lawfirmLoading, setLawfirmLoading] = useState(true);
  const [lawyersLoading, setLawyersLoading] = useState(false);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [allLawyersLoading, setAllLawyersLoading] = useState(true);
  const [allContractsLoading, setAllContractsLoading] = useState(true);
  const [allArticlesLoading, setAllArticlesLoading] = useState(true);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [allPackagesLoading, setAllPackagesLoading] = useState(false);

  const [lawyersInput, setLawyersInput] = useState("");
  const [contractsInput, setContractsInput] = useState("");
  const [articlesInput, setArticlesInput] = useState("");
  const [packageInput, setPackageInput] = useState("");

  const [sortByArticlesValue, setSortByArticlesValue] = useState("");
  const [sortByContractsValue, setSortByContractsValue] = useState("");
  const [sortByPackagesValue, setSortByPackagesValue] = useState("-created_at");

  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    const id = props.match.params.id;

    dispatch(
      getLawfirmsById(
        id,
        () => setLawfirmLoading(false),
        data => setIsBookmarked(data)
      )
    );
    dispatch(fetchLawyersByFilter({ company: id, perPage: lawyersToDisplay }, stopLoadingAll));
    dispatch(fetchArticlesByFilter({ company_id: id, perPage: articlesToDisplay }, stopLoadingAll));
    dispatch(
      fetchContractsByFilter({ company_id: id, perPage: contractsToDisplay }, stopLoadingAll)
    );
    dispatch(
      getLawfirmPackages({ id, perPage: packagesToDisplay, sort: sortByPackagesValue }, null)
    );
    return () => {
      dispatch(fetchLawyersByFilter(() => null));
      dispatch(fetchArticlesByFilter(() => null));
      dispatch(fetchContractsByFilter(() => null));
    };
  }, []);

  const stopLoadingAll = (url, type) => {
    if (type === "lawyers") {
      setAllLawyersLoading(false);
    } else if (type === "contracts") {
      setAllContractsLoading(false);
    } else {
      setAllArticlesLoading(false);
    }
  };

  const triggerLawfirmBookmark = () => {
    const id = props.match.params.id;
    if (getToken()) {
      isBookmarked
        ? deleteBookmarkItem(id, "company", data => setIsBookmarked(data === 0 ? false : true))
        : bookmarkItem(id, "company", data => setIsBookmarked(data === 0 ? false : true));
    } else {
      window.$("#login").modal("toggle");
    }
  };

  const displayCategories = () => {
    const categories = lawfirm.categories;
    const categoryIcons = categories.map((category, index) => {
      if (category.parent_id) {
        return (
          <div
            key={category.id}
            style={index === categories.length - 1 ? null : { marginRight: 10 }}
          >
            <CategorySvg index={category.parent_id} width={16} />
          </div>
        );
      } else {
        return (
          <div
            key={category.id}
            style={index === categories.length - 1 ? null : { marginRight: 10 }}
          >
            <CategorySvg index={category.id} width={16} />
          </div>
        );
      }
    });
    const categoryIconNames = categories.map((category, index) => (
      <li key={index}>{category.lang.name}</li>
    ));
    return (
      <>
        {categoryIcons}
        <div className='lawyers-type-tooltip'>
          <div className='lawyers-type-tooltip-wrap'>
            <ul>{categoryIconNames}</ul>
          </div>
        </div>
      </>
    );
  };

  const displayOffices = () => {
    const offices = lawfirm.company_addresses;

    return offices.map(office => {
      return (
        <div key={office.id} className='lawfirm-offices-item'>
          <span>{office.address}</span>
          <a href={`https://www.google.com/maps/dir//${office.address}`} target='_blank'>
            {translate("lawfirms.getDirections")}
            <i className='icon-next'></i>
          </a>
        </div>
      );
    });
  };

  const displayLanguages = () => {
    return lawfirm.languages?.map((language, index) => {
      let comma = index !== lawfirm.languages.length - 1 ? ", " : "";
      return language.lang.name + comma;
    });
  };

  const displayLawyers = () => {
    return allLawyersLoading ? (
      <div style={{ marginLeft: "48%" }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    ) : (
      lawyers.data?.map(item => <LawyerCard key={item.id} item={item} />)
    );
  };

  const displayArticles = () => {
    return allArticlesLoading ? (
      <div style={{ marginLeft: "48%" }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    ) : (
      articles.data?.map(item => (
        <KnowledgeCard
          key={item.id}
          id={item.id}
          author={item.lawyer?.name}
          title={item.title}
          source={item.image}
          viewed={item.viewed}
          type={item?.categories[0]?.lang.name}
        />
      ))
    );
  };

  const displayContracts = () => {
    return allContractsLoading ? (
      <div style={{ marginLeft: "48%" }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    ) : (
      <ContractsSearched
        items={contracts.data || []}
        selectedContract={item => {
          setSelectedContract(item);
          setSelectedPackage(null);
        }}
        lawfirm
        // contractsToDisplay={contractsToDisplay}
      />
    );
  };

  const lawyersSearchInput = input => {
    setLawyersInput(input);
    filterSearchQuery("lawyers", input);
  };

  const contractsSearchinput = input => {
    setContractsInput(input);
    filterSearchQuery("contracts", input);
  };

  const articlesSearchinput = input => {
    setArticlesInput(input);
    filterSearchQuery("articles", input);
  };

  const packagesSearchInput = input => {
    setPackageInput(input);
    filterSearchQuery("packages", input);
  };

  const onMoreButtonClick = type => {
    const id = props.match.params.id;
    if (type === "lawyers") {
      dispatch(fetchLawyersByFilter({ company: id, perPage: lawyersToDisplay + 5 }, stopLoading));
      setLawyersLoading(true);
      setLawyersToDisplay(lawyersToDisplay + 5);
    } else if (type === "contracts") {
      dispatch(
        fetchContractsByFilter(
          {
            company_id: id,
            perPage: contractsToDisplay + 4,
            name: contractsInput,
            sort: sortByContractsValue,
          },
          stopLoading
        )
      );
      setContractsLoading(true);
      setContractsToDisplay(contractsToDisplay + 4);
    } else if (type === "packages") {
      setLoadingPackages(true);
      dispatch(
        getLawfirmPackages(
          {
            id,
            perPage: packagesToDisplay + 4,
            title: packageInput,
            sort: sortByPackagesValue,
          },
          () => setLoadingPackages(false)
        )
      );
      setPackagesToDisplay(packagesToDisplay + 4);
    } else {
      dispatch(
        fetchArticlesByFilter(
          {
            company_id: id,
            perPage: articlesToDisplay + 6,
            name: articlesInput,
            sort: sortByArticlesValue,
          },
          stopLoading
        )
      );
      setArticlesLoading(true);
      setArticlesToDisplay(articlesToDisplay + 6);
    }
  };

  const stopLoading = (url, type) => {
    if (type === "lawyers") {
      setLawyersLoading(false);
    } else if (type === "contracts") {
      setContractsLoading(false);
    } else {
      setArticlesLoading(false);
    }
  };

  const filterSearchQuery = useCallback(
    debounce((type, input) => {
      const id = props.match.params.id;
      if (type === "lawyers") {
        setAllLawyersLoading(true);
        dispatch(
          fetchLawyersByFilter(
            { company: id, perPage: lawyersToDisplay, name: input },
            stopLoadingAll
          )
        );
      } else if (type === "contracts") {
        setAllContractsLoading(true);
        dispatch(
          fetchContractsByFilter(
            {
              company_id: id,
              perPage: contractsToDisplay,
              name: input,
              sort: sortByContractsValue,
            },
            stopLoadingAll
          )
        );
      } else if (type === "packages") {
        setAllPackagesLoading(true);
        dispatch(
          getLawfirmPackages(
            {
              id,
              perPage: packagesToDisplay,
              title: input,
              sort: sortByPackagesValue,
            },
            () => setAllPackagesLoading(false)
          )
        );
      } else {
        setAllArticlesLoading(true);
        dispatch(
          fetchArticlesByFilter(
            { company_id: id, perPage: articlesToDisplay, title: input, sort: sortByArticlesValue },
            stopLoadingAll
          )
        );
      }
    }, 500),
    []
  );

  const sortByHandler = (value, type) => {
    const id = props.match.params.id;
    if (type === "contracts") {
      setAllContractsLoading(true);
      dispatch(
        fetchContractsByFilter(
          {
            company_id: id,
            perPage: contractsToDisplay,
            name: contractsInput,
            sort: value,
          },
          stopLoadingAll
        )
      );
      setSortByContractsValue(value);
    } else if (type === "packages") {
      setAllPackagesLoading(true);
      dispatch(
        getLawfirmPackages(
          {
            id,
            perPage: packagesToDisplay,
            title: packageInput,
            sort: value,
          },
          () => setAllPackagesLoading(false)
        )
      );
      setSortByPackagesValue(value);
    } else {
      setAllArticlesLoading(true);
      dispatch(
        fetchArticlesByFilter(
          {
            company_id: id,
            perPage: articlesToDisplay,
            name: articlesInput,
            sort: value,
          },
          stopLoadingAll
        )
      );
      setSortByArticlesValue(value);
    }
  };

  const goToChat = () => {
    const lawfirmChat = lawfirm.company_user;

    if (lawfirmChat.chat_available) {
      api
        .post("chat/conversations", {
          participant_id: lawfirmChat.id,
        })
        .then(result => {
          props.history.push("/chat/" + result.data.data.id);
        })
        .catch(err => {});
    } else {
      window.$("#chat-payment-modal").modal("toggle");
    }
  };

  if (lawfirmLoading) {
    return (
      <div
        style={{
          height: 500,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    );
  }

  return (
    <>
      <title>{lawfirm.name} | WAL</title>

      <main className='main'>
        <section
          className='profile pd-rl'
          style={{ backgroundImage: `url(${IMAGE_BASE + lawfirm.logo})` }}
        >
          <div className='container-fluid'>
            <div className='profile-wrap'>
              <div className='profile-top'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12'>
                    <div className='profile-top-wrap'>
                      <a
                        onClick={() => props.history.goBack()}
                        style={{ color: "#fff", cursor: "pointer" }}
                        className='back-button'
                      >
                        <i className='icon-back'></i>
                        {translate("lawyers.details.back")}
                      </a>
                      <a
                        onClick={() => triggerLawfirmBookmark()}
                        className='bookmarks'
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className={isBookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='profile-bottom'>
                <div className='row align-items-end'>
                  <div className='col-md-10 col-lg-8'>
                    <div className='profile-info lawfirm-profile-info'>
                      <div className='profile-image'>
                        <Image src={lawfirm.logo} alt='lawfirm' />
                      </div>
                      <div className='profile-info-wrap'>
                        <div className='profile-info-top'>
                          <div className='lawyers-type'>
                            {lawfirm.categories && displayCategories()}
                          </div>
                        </div>
                        <div className='profile-name'>
                          <h1>{lawfirm.name}</h1>
                        </div>
                        <div className='profile-rating-state'>
                          <div className='profile-type'>
                            <span>
                              <i className='icon-star-outline'></i> {lawfirm.rating}
                            </span>
                          </div>
                          <div className='profile-state'>
                            <i className='icon-world'></i>
                            {lawfirm.jurisdictions?.map((jur, index) => (
                              <span key={index}>
                                {jur.lang.name}
                                {index !== lawfirm.jurisdictions.length - 1 && ";"}&nbsp;
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {getToken() && (
                    <div className='col-md-2 col-lg-4'>
                      <div className='profile-buttons'>
                        <div className='wal-icons'>
                          <a className='wal-icons-button message' onClick={() => goToChat()}>
                            <i className='icon-chat'></i>
                          </a>
                          <div className='dropdown-options profile-options-dropdown'>
                            <div data-toggle='dropdown' data-display='static'>
                              <a className='wal-icons-button options'>
                                <i className='icon-options'></i>
                              </a>
                            </div>
                            <ul className='dropdown-menu'>
                              <div className='more-options'>
                                <span>{translate("lawyers.details.more_option")}</span>
                              </div>
                              <li>
                                <a
                                  onClick={() => window.$("#report").modal("toggle")}
                                  className='report-lawyer'
                                >
                                  <i className='icon-report-lawyer'></i>
                                  {translate("lawfirms.reportLawfirm")}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='profile-content mg-top-40 pd-rl'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-8'>
                <ul className='profile-tab nav nav-tabs'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      href='#about'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='about'
                    >
                      {translate("lawyers.details.about")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#lawyers'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='lawyers'
                    >
                      {translate("header.tabs.lawyers")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#contracts-templates'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='contracts-templates'
                    >
                      {translate("lawyers.details.contracts_and_templates")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#packages'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='packages'
                    >
                      {translate("packages.packages")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#knowledge'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='knowledge'
                    >
                      {translate("lawyers.details.knowledge")}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                                    <a className="nav-link" href="#company-updates" data-toggle="tab" role="tab" aria-controls="company-updates">Company updates</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#gallery" data-toggle="tab" role="tab" aria-controls="gallery">Gallery</a>
                                </li> */}
                </ul>
                <div className='profile-tab-content tab-content' id='tabs'>
                  <div className='tab-pane active' id='about'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-about'
                            aria-expanded='true'
                            data-parent='#tabs'
                          >
                            {translate("lawyers.details.about")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-about'
                        className='panel-collapse collapse show'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='profile-article'>
                            <h3>{translate("lawyers.details.field_of_practice")}</h3>
                            <ul>
                              {lawfirm.categories &&
                                lawfirm.categories.map((item, index) => {
                                  return <li key={index}>{item.lang.name}</li>;
                                })}
                            </ul>
                            <h3>{translate("lawfirms.aboutLawfirm")}</h3>
                            <p>{lawfirm.description}</p>
                            <h3>{translate("lawfirms.offices")}</h3>
                            <div className='lawfirm-offices-wrap'>
                              {lawfirm.company_addresses && displayOffices()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='lawyers'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-lawyers'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            {translate("header.tabs.lawyers")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-lawyers'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='wal-content-head'>
                            <div className='row align-items-center'>
                              <div className='col-md-12 col-lg-2'>
                                <div className='wal-content-title'>
                                  <h1>
                                    <span>{lawyers.meta?.total} total</span>
                                  </h1>
                                </div>
                              </div>
                              <div className='col-md-6 col-lg-5'>
                                <div className='wal-form-group-wrap'>
                                  <div className='wal-form wal-form-group wal-form-search'>
                                    <input
                                      type='search'
                                      name='Search Contracts'
                                      placeholder={translate("lawfirms.searchForLawyers")}
                                      className='wal-form-control'
                                      value={lawyersInput}
                                      onChange={event => lawyersSearchInput(event.target.value)}
                                    />
                                  </div>
                                  {/* <div className='wal-form-filter-icon'>
                                    <i className='icon-filters'></i>
                                  </div> */}
                                </div>
                              </div>
                              {/* <div className="col-md-6 col-lg-5">
                                                            <div className="wal-form wal-form-group wal-form-sort">
                                                                <label for="select">Sort by:</label>
                                                                <div className="wal-form-sort-wrap">
                                                                    <select className="wal-form-control">
                                                                        <option value="Select">Newest First</option>
                                                                        <option value="Select">Oldest First</option>
                                                                    </select>  
                                                                </div>
                                                            </div>
                                                        </div> */}
                            </div>
                          </div>
                          <div className='profile-lawyers'>{displayLawyers()}</div>
                          {lawyersLoading ? (
                            <div style={{ marginLeft: "48%" }}>
                              <ReactLoading type='spin' color='#bea58b' />
                            </div>
                          ) : lawyers.meta?.total > lawyers.data?.length ? (
                            <div
                              onClick={() => onMoreButtonClick("lawyers")}
                              className='more-button button button-gray'
                            >
                              {translate("lawyers.details.load_more")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='contracts-templates'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-contracts-templates'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            {translate("lawyers.details.contracts_and_templates")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-contracts-templates'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='wal-content-head'>
                            <div className='row align-items-center'>
                              <div className='col-md-12 col-lg-2'>
                                <div className='wal-content-title'>
                                  <h1>
                                    <span>{contracts.meta?.total} total</span>
                                  </h1>
                                </div>
                              </div>
                              <div className='col-md-6 col-lg-5'>
                                <div className='wal-form wal-form-group wal-form-search'>
                                  <input
                                    type='search'
                                    name='Search Contracts'
                                    placeholder={translate(
                                      "lawyers.details.search_contracts_and_templates"
                                    )}
                                    className='wal-form-control'
                                    value={contractsInput}
                                    onChange={event => contractsSearchinput(event.target.value)}
                                  />
                                </div>
                              </div>
                              <div className='col-md-6 col-lg-5'>
                                <div className='wal-form wal-form-group wal-form-sort'>
                                  <label htmlFor='select'>{translate("lawyers.sort_by")}:</label>
                                  <div className='wal-form-sort-wrap'>
                                    <select
                                      className='wal-form-control'
                                      onChange={event =>
                                        sortByHandler(event.target.value, "contracts")
                                      }
                                    >
                                      <option value=''>
                                        {translate("lawyers.details.newest_first")}
                                      </option>
                                      <option value='created_at'>
                                        {translate("lawyers.details.oldest_first")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='profile-contracts'>{displayContracts()}</div>
                          {contractsLoading ? (
                            <div style={{ marginLeft: "48%" }}>
                              <ReactLoading type='spin' color='#bea58b' />
                            </div>
                          ) : contracts.meta?.total > contracts.data?.length ? (
                            <div
                              onClick={() => onMoreButtonClick("contracts")}
                              className='more-button button button-gray'
                            >
                              {translate("lawyers.details.load_more")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <PackageTab
                    translate={translate}
                    data={lawfirmPackages}
                    packageInput={packageInput}
                    allPackagesLoading={allPackagesLoading}
                    loadingPackages={loadingPackages}
                    packagesToDisplay={packagesToDisplay}
                    onLoadMoreButton={() => onMoreButtonClick("packages")}
                    packagesSearchInput={input => packagesSearchInput(input)}
                    sortPackages={event => sortByHandler(event, "packages")}
                    selectedPackage={packageId => {
                      setSelectedPackage(packageId);
                      setSelectedContract(null);
                    }}
                  />
                  <div className='tab-pane' id='knowledge'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-knowledge'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            {translate("lawyers.details.knowledge")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-knowledge'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='profile-knowledge'>
                            <div className='wal-content-head'>
                              <div className='row align-items-center'>
                                <div className='col-md-12 col-lg-2'>
                                  <div className='wal-content-title'>
                                    <h1>
                                      <span>
                                        {articles.meta?.total} {translate("lawyers.details.total")}
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                  <div className='wal-form wal-form-group wal-form-search'>
                                    <input
                                      type='search'
                                      name='Search Articles'
                                      placeholder={translate("lawyers.details.search_for_articles")}
                                      className='wal-form-control'
                                      value={articlesInput}
                                      onChange={event => articlesSearchinput(event.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                  <div className='wal-form wal-form-group wal-form-sort'>
                                    <label htmlFor='select'>{translate("lawyers.sort_by")}:</label>
                                    <div className='wal-form-sort-wrap'>
                                      <select
                                        className='wal-form-control'
                                        onChange={event =>
                                          sortByHandler(event.target.value, "articles")
                                        }
                                      >
                                        <option value=''>
                                          {translate("lawyers.details.newest_first")}
                                        </option>
                                        <option value='created_at'>
                                          {translate("lawyers.details.oldest_first")}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              {displayArticles()}
                              <div className='col-md-12 col-lg-12 col-xl-12'>
                                {articlesLoading ? (
                                  <div style={{ marginLeft: "48%" }}>
                                    <ReactLoading type='spin' color='#bea58b' />
                                  </div>
                                ) : articles.meta?.total > articles.data?.length ? (
                                  <div
                                    onClick={() => onMoreButtonClick("articles")}
                                    className='more-button button button-gray'
                                  >
                                    {translate("lawyers.details.load_more")}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='company-updates'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-company-updates'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            Company Updates
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-company-updates'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'></div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='gallery'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#collapse-gallery'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            Gallery
                          </a>
                        </h4>
                      </div>
                      <div
                        id='collapse-gallery'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='profile-gallery'></div>
                          <a href='#' className='more-button button button-gray'>
                            Load More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-12 col-xl-4'>
                <div className='profile-aside lawfirm-profile-aside'>
                  <div className='profile-aside-content'>
                    <div className='profile-aside-content-item'>
                      <h3 className='mt-0'>{translate("lawfirms.links")}</h3>
                      <p>{lawfirm.website}</p>
                      {lawfirm.website && (
                        <a href={`//${lawfirm.website}`} className='next'>
                          <i className='icon-next'></i>
                        </a>
                      )}
                    </div>
                    {/* <div className='profile-aside-social'>
                    <ul>
                      <li>
                        <a href=''>
                          <i className='icon-linkedin'></i>
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <i className='icon-twitter'></i>
                        </a>
                      </li>
                      <li>
                        <a href=''>
                          <i className='icon-facebook-alt'></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                    <div className='profile-aside-content-item'>
                      <h3>{translate("lawyers.details.email")}</h3>
                      <p>{lawfirm.email}</p>
                    </div>
                    <h3>{translate("lawyers.details.language")}</h3>
                    {displayLanguages()}
                    {/* <h3>Location</h3>
                                    <p>Sandstrasse 10, 5412, Gebenstorf,Switzerland</p> */}
                  </div>
                  {lawfirm.latitude && lawfirm.longitude && (
                    <>
                      <div className='profile-aside-map'>
                        <Maps lat={lawfirm.latitude} long={lawfirm.longitude} lawyers={[lawfirm]} />
                      </div>

                      <a
                        href={
                          "https://www.google.com/maps/dir//" +
                          lawfirm.latitude +
                          "," +
                          lawfirm.longitude
                        }
                        target='_blank'
                        className='button button-white'
                      >
                        <i className='icon-pin'></i>
                        {translate("lawyers.details.get_direction")}
                      </a>
                    </>
                  )}
                  {/* <div className='profile-aside-copy'>
                  <div className='copy-link'>
                    <input
                      type='text'
                      className='wal-form-control'
                      value='wal.worl/as2049sc.12'
                      readonly=''
                    />
                    <a href='#' className='copy'>
                      <i className='icon-copy'></i>
                    </a>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <PaymentModal
          item={lawfirm.company_user}
          paymentType={"Message"}
          translate={translate}
          history={props.history}
          lawfirm
        />
        <Modal item={selectedContract} packageItem={selectedPackage} />
        <ReportLawyerModal lawfirmId={props.match.params.id} translate={translate} />
      </main>
    </>
  );
};

export default LawfirmDetails;
