import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import moment from "moment";
import { toast } from "react-toastify";

import {
  getLawyerReviews,
  deleteLawyerReview,
  reviewLawyer,
} from "../../../actions/lawyersActions";

import DeleteAlertModal from "../../../components/shared/modals/DeleteAlertModal";
import Image from "../../../components/shared/Image";
import DisplayRating from "../../../components/shared/DisplayRating";
import { getToken } from "../../../helpers";

const LawyerReviews = ({ translate, reviews, id }) => {
  const dispatch = useDispatch();
  const reviewItems = useSelector(s => s.lawyerReviews);
  const user = useSelector(s => s.currentUser);

  const [reviewComment, setReviewComment] = useState("");
  const [quality, setQuality] = useState(reviews.quality);
  const [professionality, setProfessionality] = useState(reviews.professionality);
  const [experience, setExperience] = useState(reviews.experience);
  const [helpful, setHelpful] = useState(reviews.helpful);

  const [commentsPerPage, setCommentsPerPage] = useState(5);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [moreCommentsLoading, setMoreCommentsLoading] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  
  const isLoggedIn = getToken();

  const displayComments = () => {
    return reviewItems.data?.map(comment => {
      return (
        <div className='comments-item' key={comment.id}>
          <div className='comments-info'>
            <div className='comments-img'>
              <Image src={comment.user.avatar} alt='person' />
            </div>
            <div className='comments-info-wrap'>
              <h1>{comment.user.name}</h1>
              <span>{moment(comment.updated_at).format("DD MMM YYYY")}</span>
            </div>
          </div>
          <div className='comments-content'>
            <p>{comment.comment}</p>
            <div style={{ marginTop: 8 }}>
              <DisplayRating stars={[0, 1, 2, 3, 4]} currentRate={comment.overall_rating} />
            </div>
            {user?.id === comment.user?.id && (
              <span className='delete' onClick={() => deleteReview(comment.id)}>
                {translate("packages.delete")}
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  const postReview = () => {
    if (reviewComment === "") return toast.error(translate("packages.please_add_comment"));
    const reviewData = { reviewComment, quality, professionality, experience, helpful };
    setCommentsLoading(true);
    reviewLawyer(id, reviewData, reviewProcessing);
    setReviewComment("");
  };

  const reviewProcessing = () => {
    dispatch(getLawyerReviews(id, commentsPerPage, () => setCommentsLoading(false)));
  };

  const deleteReview = reviewId => {
    setReviewToDelete(reviewId);
    window.$("#delete").modal("toggle");
  };

  const successDelete = () => {
    window.$("#delete").modal("toggle");
    toast.success(translate("packages.delete_review_success"));
    setCommentsLoading(true);
    dispatch(getLawyerReviews(id, commentsPerPage, () => setCommentsLoading(false)));
  };

  const onLoadMoreButtonHandler = () => {
    setMoreCommentsLoading(true);
    dispatch(getLawyerReviews(id, commentsPerPage + 5, () => setMoreCommentsLoading(false)));
    setCommentsPerPage(commentsPerPage + 5);
  };

  return (
    <div className='panel-body nav-content'>
      <div className='main-title'>
        <h2>{translate("contracts.details.reviews")}</h2>
      </div>
      <div className='profile-reviews'>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.quality")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            stateType={quality}
            changeStateType={index => setQuality(index)}
            changeMode
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.professionality")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            stateType={professionality}
            changeStateType={index => setProfessionality(index)}
            changeMode
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.experience")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            stateType={experience}
            changeStateType={index => setExperience(index)}
            changeMode
          />
        </div>
        <div className='profile-reviews-item'>
          <span>{translate("contracts.details.helpful")}</span>
          <DisplayRating
            stars={[0, 1, 2, 3, 4]}
            stateType={helpful}
            changeStateType={index => setHelpful(index)}
            changeMode
          />
        </div>
      </div>
      <div className='main-title'>
        <h2>{translate("contracts.details.comments")}</h2>
      </div>
      <div className='comments'>
        {isLoggedIn && (
          <div className='comments-head'>
            <div className='comments-img'>
              <Image src={user?.avatar} alt='person' />
            </div>

            <div className='comments-input'>
              <input
                type='text'
                value={reviewComment}
                onChange={t => setReviewComment(t.target.value)}
                className='wal-form-control'
                placeholder={translate("contracts.details.write_comment")}
              />
              <div className='comments-send'>
                <div className='button button-white' onClick={() => postReview()}>
                  <i className='icon-send'></i>
                  {translate("contracts.details.post")}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='comments-body'>
          {commentsLoading ? (
            <div style={{ marginLeft: "48%", marginBottom: 20 }}>
              <ReactLoading type='spin' color='#bea58b' />
            </div>
          ) : (
            displayComments()
          )}
        </div>
        {moreCommentsLoading ? (
          <div style={{ marginLeft: "48%" }}>
            <ReactLoading type='spin' color='#bea58b' />
          </div>
        ) : reviewItems.meta?.total > reviewItems.data?.length ? (
          <div className='more-button button button-gray' onClick={() => onLoadMoreButtonHandler()}>
            {translate("packages.load_more_reviews")}
          </div>
        ) : (
          <></>
        )}
      </div>
      <DeleteAlertModal
        title={translate("packages.delete_review")}
        description={translate("packages.delete_review_sure")}
        confirmDelete={() => deleteLawyerReview(id, reviewToDelete, () => successDelete())}
      />
    </div>
  );
};

export default LawyerReviews;
