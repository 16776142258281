import qs from "qs";
import { BASE_DEV } from "../constants/types";

export const isAuth = () => {
  let token = getToken();
  if (token) {
    if (token.split(".").length === 3) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const exp = JSON.parse(window.atob(base64)).exp;
        if (typeof exp === "number") {
          return Math.round(new Date().getTime() / 1000) < exp;
        }
      } catch (e) {
        return true;
      }
    }
    return true;
  }
  return false;
};

export const getServerUrl = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return "https://dev-api.wal.world/";
  }

  if(window.location.hostname.includes('dev')) {
    return "https://dev-api.wal.world/";
  }

  return `https://${BASE_DEV}/`;
};

export const getApiUrl = () => {
  return getServerUrl() + "api/";
};

export const getSocketUrl = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return "ws:" + getServerUrl();
  }
  return "wss:" + getServerUrl();
};

export const imageUrl = (image) => {
  return getServerUrl() + "uploads/" + image;
};

export const logoUrl = (image, thumb) => {
  return getServerUrl() + "logos/" + image;
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getToken = () => {
  return localStorage.getItem("token");
  // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd2FsLmFwcGVyLmNoXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjA3MDc1MzYxLCJleHAiOjE2Mzg2MTEzNjEsIm5iZiI6MTYwNzA3NTM2MSwianRpIjoiS01wRFE1TzEycEsxVUJRSiIsInN1YiI6MTI0LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0._hLnpnB-mtuYOpGFwP_FRRpiqioaRLQAmEsfRtslBzw"
};

export const setSubInfo = (value) => {
  localStorage.setItem("subInfo", value);
};

export const removeSubInfo = () => {
  localStorage.removeItem("subInfo");
};

export const getSubInfo = () => {
  return localStorage.getItem("subInfo");
  // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd2FsLmFwcGVyLmNoXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjA3MDc1MzYxLCJleHAiOjE2Mzg2MTEzNjEsIm5iZiI6MTYwNzA3NTM2MSwianRpIjoiS01wRFE1TzEycEsxVUJRSiIsInN1YiI6MTI0LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0._hLnpnB-mtuYOpGFwP_FRRpiqioaRLQAmEsfRtslBzw"
};

export const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setRole = (role_id) => {
  localStorage.setItem("role", role_id);
};

export const userLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return lang;
  }
  return "en";
};

export const setLang = (lang) => {
  localStorage.setItem("lang", lang);
};

export const clearSession = () => {
  localStorage.clear();
};

export const getParams = (event) => {
  let target = event.target;

  let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;

  let data = {
    value: value,
    name: target.name,
    type: target.type,
  };

  return data;
};

export const redirect = (url) => {
  window.location.href = url;
};

export const bodyClass = (className) => {
  document.body.className = className;
};

export const flyForm = (action, fields, bodyAppend) => {
  var form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", action);

  for (let name in fields) {
    var fieldInput = document.createElement("input");
    fieldInput.setAttribute("type", "hidden");
    fieldInput.setAttribute("name", name);
    fieldInput.setAttribute("value", fields[name]);
    form.appendChild(fieldInput);
  }

  if (bodyAppend) {
    document.body.appendChild(form);
  }

  return form;
};

export const getPageTitle = () => {
  return window.location.pathname.split("/")[1] || "dashboard";
};

export const filterHandler = (filters = [], reset = false) => {
  // { name, value }
  let query = reset ? {} : qs.parse(window.location.search.split("?")[1]);

  filters.map(({ name, value }) => {
    if (Array.isArray(value)) {
      if (value && value !== []) {
        query[name] = value.map((val) => val.id || val);
      } else {
        delete query[name];
      }
    } else {
      if (value && value.toString() !== "-1") {
        query[name] = value;
      } else {
        delete query[name];
      }
    }
  });

  return query;
};

export const pushHistory = (filters, history) => {
  let query = { ...filters };
  for (let key in query) {
    if (Array.isArray(query[key])) {
      if (query[key].length === 0) delete query[key];
    } else {
      if (!query[key]) delete query[key];
    }
  }
  let queryString = qs.stringify(query, { addQueryPrefix: true, skipNulls: true });
  history.push({ pathname: history.location.pathname, search: queryString });
  return { query, queryString };
};

export const parseQueryString = () => {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true });
};

export const displayArrayOfObjectCommaSeparated = (array, label = "name") => {
  let text = "";
  (array || []).map((lan, index) => {
    text += lan[label];
    if (index + 1 !== array.length) {
      text += ", ";
    }
  });
  return text;
};

export const deepStateMutator = (object, data) => {
  if (!data.name) return { ...object };

  let name = data.name.split(".");
  let firstKey = name.shift();

  if (Array.isArray(name) && name.length > 0) {
    name = name.join(".");
    return { ...object, [firstKey]: { ...deepStateMutator(object[firstKey] || {}, { name, value: data.value }) } };
  }

  if (data.value === undefined) {
    delete object[firstKey];
    return { ...object };
  }

  return { ...object, [firstKey]: data.value };
};

export const appendParam = (params, name, value) => value !== undefined && params.append(name, value);

export const jsUcFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const displayDateFormat = "d MMMM yyyy";
export const sendDateFormat = "dd MM yyyy";
export const apiDateFormat = "";
