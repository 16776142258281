import React from 'react';

import OnlinePin from '../../assets/img/icons/pin-online.png';

import OfflinePin from '../../assets/img/icons/pin-offline.png';

import UnknownPin from '../../assets/img/icons/pin-unknown.png';

import mapStyle from './mapStyle.json';

import { Gmaps, Marker, InfoWindow, Circle } from 'react-gmaps';

const exampleMapStyles = [
  { elementType: 'labels.text.fill', stylers: [{ color: '#000' }] }, //TOPHANE
  { elementType: 'labels.text.stroke', stylers: [{ color: '#000' }] }, //TOPHANE outline

  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }], //rrugte
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#D9DDE1' }], //rrugat e autostrades outline
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }], //rrugt e gjona poget
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#D9DDE1' }], //rrugat e autostrades outline
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#f8c967' }], //rrugat e autostrades
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#D9DDE1' }], //rrugat e autostrades outline
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{ color: '#D9DDE1' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#D9DDE1' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#797777' }], //titujt e rrugve
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#dfd2ae' }], //ni vi e holle transit
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#8f7d77' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#ebe3cd' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#dfd2ae' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#b9d3c2' }], //uji
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#000' }], //teksti i ujit
  },
  { elementType: 'labels', featureType: 'poi', stylers: [{ visibility: 'off' }] },
];

const coords = {
  lat: 51.5258541,
  lng: -0.08040660000006028,
};

const params = { v: '3.exp', key: 'AIzaSyASiEPBzDacos5pKZ4KVaCUvTse8YzhuYA' };

class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      long: null,
      lat: null,
    };
  }

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: true,
    });
  }

  onDragEnd(e) {
    // console.log(e);
  }

  onCloseClick() {
    // console.log("onCloseClick");
  }

  onClick(e) {
    // console.log("onClick", e);
  }

  componentDidMount() {
    this.handleCoords();
  }

  handleCoords = () => {
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
  };

  render() {
    let { lat, long } = this.state;

    return (
      <Gmaps
        mapContainerStyle={mapStyle}
        width={'100%'}
        height={'100%'}
        lat={this.props.lat || lat}
        lng={this.props.long || long}
        zoom={15}
        params={params}
        options={{
          styles: mapStyle,
        }}
        onMapCreated={this.onMapCreated}
      >
        {this.props.lawyers &&
          this.props.lawyers.map((item, index) => {
            return [
              <Marker
                icon={item.is_online ? OnlinePin : OfflinePin}
                lat={item.latitude}
                lng={item.longitude}
                draggable={false}
                // onClick={() => console.log("ok", item.latitude)}
              />,
              <InfoWindow
                lat={item.latitude}
                lng={item.longitude}
                content={item.name}
                // onCloseClick={this.onCloseClick}
              />,
            ];
          })}
      </Gmaps>
    );
  }
}

export default Maps;
