import React
  from "react";

import { connect }
  from "react-redux";

import { cancelSubscription, mySubscription }
 from "../../helpers/subscription";

import { CardForm, CardItem, EmptyCards }
  from './payment';

import { getCards, deleteCard }
  from '../../helpers/payments'

import ReactLoading
  from 'react-loading';

import DeleteAlertModal
  from '../../components/shared/modals/DeleteAlertModal';

import { SuccessAlert }
  from '../../components/settings/shared';

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      errors: null,
      formMode: "new",
      cards: [],
      deleteItem: null,
      errorUpdated: false,
      subscriptions: [],
    };
  }

  componentDidMount() {
    getCards(this.catchResult.bind(this))
    mySubscription(data => this.setState({subscriptions: data?.data}))
  }

  catchResult(data) {
    this.setState({cards: data.data, loading: false})
  }

  triggerFormMode(mode) {
    let _this = this
    this.setState({formMode: mode})

    if(mode == 'success') {
      getCards(this.catchResult.bind(this))
      this.setState({successUpdated: true})
      setTimeout(function(){ _this.setState({successUpdated: false}) }, 3000);
    }
  }

  displayCards() {
    let { cards, loading } = this.state

    return (
      cards.map((card, index) => {
        return (
          <CardItem
            key={index}
            card={card}
            setDeleteItem={(itemId) => this.setDeleteItemFromModal(itemId)}
          />
        )
      })
    )
  }

  setDeleteItemFromModal(itemId) {
    this.setState({deleteItem: itemId})
    window.$('#delete').modal('toggle');
  }

  confirmDelete(e) {
    e.preventDefault()
    let { deleteItem } = this.state
    this.setState({loading: true})

    window.$('#delete').modal('toggle');
    deleteCard(deleteItem, this.successDeleteCard.bind(this))
  }

  successDeleteCard() {
    getCards(this.catchResult.bind(this));
    this.state.subscriptions?.forEach(id => cancelSubscription(id, null, null))
  }

  displayCardsSection() {
    const { currentUser, translate } = this.props
    let { error, errors, type, formMode, successUpdated } = this.state
    let { cards, loading } = this.state

    if(loading && translate){
      return(
        <div style={{display: 'flex', justifyContent: 'center', marginTop: "30%", alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b" height={'5%'} width={'5%'} />
        </div>
      )
    }

    if(cards.length > 0) {
      return (
        <div className="settings-payments-wrap">
          <div className="settings-payments-main-title">
            <h2>Cards</h2>
          </div>
          <div className="wal-cards-wrap">
            {this.displayCards()}
          </div>
          { formMode == "new" || formMode == "success" ?
            <div className="wal-cards-add-new">
              <button onClick={() => this.triggerFormMode("create")} className="button button-white">
                <i className="icon-plus"></i>{translate("settings.payments.add_new_payment_method")}
              </button>
            </div>
            :
            <CardForm
              triggerFormMode={this.triggerFormMode.bind(this)}
            />
          }
        </div>
      )
    } else {
      return (
        <EmptyCards
          translate={translate}
          formMode={formMode}
          triggerFormMode={this.triggerFormMode.bind(this)}
        />
      )
    }
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, type, formMode, successUpdated, cards } = this.state
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          {this.displayCardsSection()}
        </div>
        <DeleteAlertModal
          title={translate("settings.payments.are_you_sure")}
          description={cards.length === 1 ? translate("settings.payments.subscription_will_cancel") : translate("settings.payments.description")}
          confirmDelete={this.confirmDelete.bind(this)}
        />
        { successUpdated &&
          <SuccessAlert
            translate={translate}
            message={translate("settings.payments.card_added_successfully")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(Payments);
