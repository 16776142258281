import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import moment from "moment";
import debounce from "lodash.debounce";
import { getTranslate } from "react-localize-redux";

import {
  getPackagesById,
  getPackageItems,
  getPackageComments,
} from "../../../actions/packagesActions";

import { bookmarkItem, deleteBookmarkItem } from "../../../helpers/bookmarkItem";

import { WEB_URL } from "../../../constants/types";
import { getToken } from "../../../helpers";
import { Modal } from "../../../components/shared/modals/payment";
import ContractsSearched from "../../../components/contracts/ContractsSearched";
import Image from "../../../components/shared/Image";
import PackageReviews from "./PackageReviews";

const PackageDetails = props => {
  const dispatch = useDispatch();
  const translate = useSelector(s => getTranslate(s.locale));
  const packageById = useSelector(s => s.packagesById); //package is reserved
  const items = useSelector(s => s.packageItems);

  const [pageLoading, setPageLoading] = useState(true);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [contractsPerPage, setContractsPerPage] = useState(4);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [contractSearch, setContractSearch] = useState("");
  const [contractSearchLoading, setContractSearchLoading] = useState(false);
  const [contractSort, setContractSort] = useState("created_at");
  const [selectedContract, setSelectedContract] = useState(null);

  const inputShareRef = useRef();
  const isLoggedIn = getToken();

  useEffect(() => {
    const id = props.match.params.id;
    dispatch(
      getPackagesById(
        id,
        () => setPageLoading(false),
        data => setIsBookmarked(data)
      )
    );
    dispatch(getPackageItems(id, contractsPerPage, null));
    dispatch(getPackageComments(id, 5));
  }, []);

  const displayLanguages = () => {
    return packageById.languages?.map((language, index) => {
      let comma = index !== packageById.languages.length - 1 ? ", " : "";
      return language.lang.name + comma;
    });
  };

  const displayContracts = () => {
    const itemsFiltered = items.data?.filter(item => item.contract !== null);
    const contracts = itemsFiltered?.map(item => item.contract);

    return contractSearchLoading ? (
      <div style={{ marginLeft: "48%" }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    ) : (
      <ContractsSearched
        items={contracts}
        lawfirm
        selectedContract={contractItem => setSelectedContract(contractItem)}
      />
    );
  };

  const triggerPackageBookmark = () => {
    const id = props.match.params.id;
    if (getToken()) {
      isBookmarked
        ? deleteBookmarkItem(id, "package", data => setIsBookmarked(data === 0 ? false : true))
        : bookmarkItem(id, "package", data => setIsBookmarked(data === 0 ? false : true));
    } else {
      window.$("#login").modal("toggle");
    }
  };

  const onMoreButtonClick = () => {
    const id = props.match.params.id;
    setContractsLoading(true);
    dispatch(
      getPackageItems(id, contractsPerPage + 4, contractSearch, contractSort, () =>
        setContractsLoading(false)
      )
    );
    setContractsPerPage(contractsPerPage + 4);
  };

  const filterSearchQuery = useCallback(
    debounce(input => {
      const id = props.match.params.id;
      setContractSearchLoading(true);
      dispatch(
        getPackageItems(id, contractsPerPage, input, contractSort, () =>
          setContractSearchLoading(false)
        )
      );
    }, 500),
    []
  );

  const contractSearchInput = input => {
    setContractSearch(input);
    filterSearchQuery(input);
  };

  const sortByHandler = value => {
    const id = props.match.params.id;
    setContractSearchLoading(true);
    dispatch(
      getPackageItems(id, contractsPerPage, contractSearch, value, () =>
        setContractSearchLoading(false)
      )
    );
    setContractSort(value);
  };

  const copyToClipboard = e => {
    e.preventDefault();
    inputShareRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  const onBuyButton = () => {
    setSelectedContract(null);
    if (isLoggedIn) {
      window.$("#payment-modal").modal("toggle");
    } else {
      window.$("#login").modal("toggle");
    }
  };

  if (pageLoading) {
    return (
      <div
        style={{
          height: 500,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    );
  }

  return (
    <>
      <title>{packageById?.title} | WAL</title>

      <main className='main'>
        <section className='profile profile-package pd-rl'>
          <div className='container-fluid'>
            <div className='profile-wrap'>
              <div className='profile-top'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12'>
                    <div className='profile-top-wrap'>
                      <a
                        onClick={() => props.history.goBack()}
                        style={{ color: "#fff", cursor: "pointer" }}
                        className='back-button'
                      >
                        <i className='icon-back'></i>
                        {translate("lawyers.details.back")}
                      </a>
                      <a
                        className='bookmarks'
                        onClick={() => triggerPackageBookmark()}
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className={`${isBookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}`}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='profile-bottom'>
                <div className='row align-items-end'>
                  <div className='col-md-12 col-lg-8'>
                    <div className='profile-info'>
                      <div className='profile-info-wrap'>
                        <div className='profile-type'>
                          <span>
                            <i className='icon-contracts'></i> {items.meta?.total}{" "}
                            {translate("packages.documents")}
                          </span>
                        </div>
                        <div className='profile-name'>
                          <h1>{packageById.title}</h1>
                        </div>
                        <div className='profile-rating-state'>
                          <div className='profile-type'>
                            <span>
                              <i className='icon-star-outline'></i> {packageById.rating}
                            </span>
                          </div>
                          {/* <div className='profile-type'>
                          <span>
                            <i className='icon-time-used'></i>14
                          </span>
                        </div> */}
                          <div className='profile-type'>
                            <span>
                              <i className='icon-bought-items'></i>
                              {packageById.bought_count}
                            </span>
                          </div>
                          <div className='profile-type'>
                            <span>
                              <i className='icon-language'></i>
                              {displayLanguages()}
                            </span>
                          </div>
                          <div className='profile-type'>
                            <span>
                              <i className='icon-world'></i>
                              {packageById.jurisdictions?.map((jur, index) => (
                                <span key={index}>
                                  {jur.lang.name}
                                  {index !== packageById.jurisdictions.length - 1 && ";"}&nbsp;
                                </span>
                              ))}
                            </span>
                          </div>
                        </div>
                        <div className='profile-tags'>
                          <ul>
                            {packageById.hashtags?.map(tag => (
                              <li key={tag.id}>
                                <a className='customHashtags'>{tag.tag}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-4'>
                    <div className='profile-buttons'>
                      {packageById.is_purchased ? (
                        <a
                          href={`${WEB_URL}/packages/${packageById.id}/download?token=${isLoggedIn}`}
                          className='button button-primary'
                        >
                          <i className='icon-download'></i>
                          {translate("packages.download_this_package")}
                        </a>
                      ) : (
                        <a onClick={() => onBuyButton()} className='button button-primary'>
                          <i className='icon-purchase'></i>
                          {translate("packages.buy_package_for")} {packageById.price}
                        </a>
                      )}
                      <div className='wal-icons'>
                        <div className='dropdown-options contracts-options-dropdown'>
                          <div data-toggle='dropdown' data-display='static'>
                            <a href='#' className='wal-icons-button options'>
                              <i className='icon-options'></i>
                            </a>
                          </div>
                          <ul className='dropdown-menu'>
                            <div className='more-options'>{/* <span>More options</span> */}</div>
                            <li>
                              <a href='#' className='report-lawyer'>
                                {/* <i className='icon-email'></i>Share via mail */}
                              </a>
                            </li>
                            {/* <li>
                            <a
                              href='#'
                              className='report-lawyer'
                              data-toggle='modal'
                              data-target='#report'
                            >
                              <i className='icon-contracts-report'></i>Report contract
                            </a>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='profile-content mg-top-40 pd-rl'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-8'>
                <ul className='profile-tab nav nav-tabs'>
                  <li className='nav-item'>
                    <a
                      className={`nav-link active`}
                      href='#about'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='about'
                    >
                      {translate("lawyers.details.about")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link`}
                      href='#documents'
                      data-toggle='tab'
                      role='tab'
                      aria-selected='true'
                      aria-controls='documents'
                    >
                      {translate("packages.documents")}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='#reviews'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='reviews'
                    >
                      {translate("contracts.details.reviews")}
                    </a>
                  </li>
                </ul>
                <div className='profile-tab-content tab-content' id='tabs'>
                  <div className='tab-pane active' id='about'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#about-collapse'
                            aria-expanded='true'
                            data-parent='#tabs'
                          >
                            {translate("lawyers.details.about")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='about-collapse'
                        className='panel-collapse collapse show'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='profile-article'>
                            <h3>{translate("contracts.details.description")}</h3>
                            <p>{packageById.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='documents'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#documents-collapse'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            {translate("packages.documents")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='documents-collapse'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <div className='panel-body nav-content'>
                          <div className='wal-content-head'>
                            <div className='row align-items-center'>
                              <div className='col-md-12 col-lg-2'>
                                <div className='wal-content-title'>
                                  <h1>
                                    <span>
                                      {items.meta?.total} {translate("lawyers.details.total")}
                                    </span>
                                  </h1>
                                </div>
                              </div>
                              <div className='col-md-6 col-lg-5'>
                                <div className='wal-form wal-form-group wal-form-search'>
                                  <input
                                    type='search'
                                    name='Search Contracts'
                                    placeholder={translate(
                                      "lawyers.details.search_contracts_and_templates"
                                    )}
                                    className='wal-form-control'
                                    value={contractSearch}
                                    onChange={event => contractSearchInput(event.target.value)}
                                  />
                                </div>
                              </div>
                              <div className='col-md-6 col-lg-5'>
                                <div className='wal-form wal-form-group wal-form-sort'>
                                  <label htmlFor='select'>{translate("lawyers.sort_by")}:</label>
                                  <div className='wal-form-sort-wrap'>
                                    <select
                                      className='wal-form-control'
                                      onChange={event =>
                                        sortByHandler(event.target.value, "contracts")
                                      }
                                    >
                                      <option value='-created_at'>
                                        {translate("lawyers.details.newest_first")}
                                      </option>
                                      <option value='created_at'>
                                        {translate("lawyers.details.oldest_first")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='profile-contracts'>{displayContracts()}</div>
                          {contractsLoading ? (
                            <div style={{ marginLeft: "48%" }}>
                              <ReactLoading type='spin' color='#bea58b' />
                            </div>
                          ) : items.meta?.total > items.data?.length ? (
                            <div
                              onClick={() => onMoreButtonClick()}
                              className='more-button button button-gray'
                            >
                              {translate("lawyers.details.load_more")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='reviews'>
                    <div className='panel panel-default'>
                      <div className='panel-heading'>
                        <h4 className='panel-title nav-list'>
                          <a
                            data-toggle='collapse'
                            data-target='#reviews-collapse'
                            aria-expanded='false'
                            data-parent='#tabs'
                          >
                            {translate("contracts.details.reviews")}
                          </a>
                        </h4>
                      </div>
                      <div
                        id='reviews-collapse'
                        className='panel-collapse collapse'
                        data-parent='#tabs'
                      >
                        <PackageReviews
                          translate={translate}
                          id={packageById.id}
                          isPurchased={packageById.is_purchased}
                          reviews={packageById.review}
                          isReviewed={packageById.auth_user_has_review_on_package}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-12 col-xl-4'>
                <div className='profile-aside'>
                  <div className='profile-aside-title'>
                    <h3>Created by</h3>
                  </div>
                  <div className='profile-create-item'>
                    <div className='profile-create-right'>
                      <div className='lawyers-img'>
                        <Image src={packageById.lawyer?.avatar} alt='person' />
                        <div
                          className={`status ${
                            packageById.lawyer?.is_online ? "active" : "inactive"
                          }`}
                        ></div>
                      </div>
                      <div className='profile-create-content'>
                        <h1>{packageById.lawyer?.name}</h1>
                        <p>{translate("search.lawyer")}</p>
                      </div>
                    </div>
                    <div className='profile-create-left'>
                      <Link to={`/lawyers/${packageById.lawyer?.id}`}>
                        <div>
                          <i className='icon-next'></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {packageById.company && (
                    <div className='profile-create-item'>
                      <div className='profile-create-right'>
                        <div className='profile-create-img'>
                          <Image src={packageById.company?.logo} alt='person' />
                        </div>

                        <div className='profile-create-content'>
                          <h1>{packageById.company?.name}</h1>
                          <p>{translate("lawyers.details.company")}</p>
                        </div>
                      </div>
                      <div className='profile-create-left'>
                        <Link to={`/lawfirms/${packageById.company?.id}`}>
                          <div>
                            <i className='icon-next'></i>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className='profile-aside-date'>
                    <h3>{translate("packages.created_on")}</h3>
                    <p>{moment(packageById.created_at).format("DD MMM YYYY")}</p>
                  </div>
                  <div className='profile-aside-copy'>
                    <div className='copy-link'>
                      <input
                        type='text'
                        ref={inputShareRef}
                        className='wal-form-control'
                        value={window.location.href}
                        readOnly
                      />
                      <a className='copy' onClick={e => copyToClipboard(e)}>
                        <i className='icon-copy'></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          item={selectedContract && selectedContract}
          packageItem={!selectedContract && packageById}
        />
      </main>
    </>
  );
};

export default PackageDetails;
