import React from "react";

import VisaWhite from "../../../../../assets/img/visa-white.svg";

import MasterCardWhite from "../../../../../assets/img/master-card-white.svg";

import { fetchContract } from "../../../../../actions/contractsActions";

import { getCards, purchaseEntry } from "../../../../../helpers/payments";

import { addSubscription } from "../../../../../helpers/subscription";

import { getTranslate } from "react-localize-redux";

import { connect } from "react-redux";

import ReactLoading from "react-loading";

import { fetchCurrencies } from "../../../../../actions/currencyActions";

import { getCurrency } from "../../../../../services/storeCurrency";

import CURRENCIES from "../../../../../constants/currencies";

import { toast } from "react-toastify";

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cards: [],
      currentCardSelected: null,
      payLoading: false,
    };
  }

  componentDidMount() {
    this.props.fetchCurrencies();
    getCards(this.catchResult.bind(this));
  }

  catchResult(data) {
    this.setState({ cards: data.data, loading: false });
  }

  goNext() {
    window.$("#payment-details").modal("toggle");
    setTimeout(function () {
      window.$("#payment-success").modal("toggle");
    }, 250);
  }

  goToCreateNewCard() {
    window.$("#payment-details").modal("toggle");
    setTimeout(function () {
      window.$("#payment-addCard").modal("toggle");
    }, 250);
  }

  onChangeRadioValue(id) {
    this.setState({ currentCardSelected: id });
  }

  displayCards() {
    let { cards, loading } = this.state;

    if (loading) {
      return (
        <div
          style={{
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type="spin"
            color="#bea58b"
            height={"10%"}
            width={"10%"}
          />
        </div>
      );
    }

    return cards.map((card, index) => {
      let { card_number, card_owner, exp_month, exp_year, id, type } = card;
      return (
        <div key={index} className="payment-radio" onChange={() => this.onChangeRadioValue(id)}>
          <input
            type="radio"
            id={`${id}-chat`}
            value={id}
            className="radio"
            name="chat-payment-item"
          />
          <label htmlFor={`${id}-chat`}>
            <div className="payment-radio-icon">
              <img src={(type = "Visa" ? VisaWhite : MasterCardWhite)} />
            </div>
            <div className="payment-radio-content">
              <h3>{card_owner}</h3>
              <span className="card-number">**** **** **** {card_number}</span>
            </div>
          </label>
        </div>
      );
    });
  }

  purchaseAction() {
    let { currentCardSelected } = this.state;
    let entityType = this.props.paymentType === "Call" ? "call" : "chat";
    if (!currentCardSelected) {
      this.setState({ error: "Not selected any card" });
    } else {
      this.setState({ error: null, payLoading: true });
      purchaseEntry(
        {
          cardId: currentCardSelected,
          entityType,
          entityId: this.props.item.id,
        },
        this.successCatchResult.bind(this),
        this.handleErrors.bind(this)
      );
    }
  }

  successCatchResult(data) {
    if(this.props.paymentType !== "Call"){
      this.props.changeStatus("success");
    }else {
      this.props.goToCall();
      setTimeout(() => window.location.reload(), 5000);
    }
  }

  handleErrors(data) {
    this.setState({ error: data.errors, loading: false });
    if(data.code === 'payment_intent_authentication_failure') {
      this.setState({payLoading: false});
      toast.error('Payment failed due to no confirmation');
    }
  }

  displayPayButton() {
    let { translate } = this.props;
    let { payLoading } = this.state;
    if (payLoading) {
      return (
        <div
          style={{
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type="spin"
            color="#bea58b"
            height={"10%"}
            width={"10%"}
          />
        </div>
      );
    }

    return (
      <button
        className="button button-primary"
        onClick={() => this.purchaseAction()}
      >
        <i className="icon-purchase"></i>
        {translate("payment.pay_now")}
      </button>
    );
  }

  displayHeaderInfo() {
    let { translate, item, currencies, paymentType, lawfirm } = this.props;
    let currentCurrency = CURRENCIES[getCurrency];
    let paymentInfo =
      lawfirm ? "payment.chatting_a_lawfirm":
      paymentType === "Call"
        ? "payment.calling_a_lawyer"
        : "payment.chatting_a_lawyer";

    if (currencies["contact_lawyer"]) {
      return (
        <div className="payment-modal-info">
          <div className="payment-modal-info-wrap">
            <h2>
              {paymentType} with {item.name}
            </h2>
            <p>{translate(paymentInfo)}</p>
          </div>
          <div className="payment-modal-info-price">
            <span>
              {currencies["contact_lawyer"][currentCurrency.name]}.-{" "}
              {currentCurrency.name.toUpperCase()}
            </span>
          </div>
        </div>
      );
    }
  }

  render() {
    let { translate } = this.props;
    let { error } = this.state;
    return (
      <div className="payment-modal-wrap modal-ge-wrap">
        <div className="modal-ge-top payment-modal-top">
          <h1>{translate("payment.title")}</h1>
        </div>
        <div className="modal-ge-body">
          <div className="payment-modal-title">
            <h3>{translate("payment.order_summery")}</h3>
          </div>
          {this.displayHeaderInfo()}
          <div className="payment-modal-title">
            <h3>{translate("payment.payment_method")}</h3>
          </div>
          {error && <p style={{ marginBottom: 10, color: "red" }}>{error}</p>}
          {this.displayCards()}
          <div className="payment-modal-add">
            <button
              className="button button-white"
              onClick={() => this.props.changeStatus("addCard")}
            >
              <i className="icon-plus"></i>
              {translate("payment.add_new_payment_method")}
            </button>
          </div>
        </div>
        <div className="modal-ge-bottom">{this.displayPayButton()}</div>
        <div className="modal-ge-close" data-dismiss="modal">
          <i className="icon-x"></i>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    current_user: state.currentUser,
    currencies: state.currencies,
  };
}

export default connect(mapStateToProps, { fetchCurrencies, fetchContract })(
  PaymentDetails
);
