import React, { useState, useEffect } from "react";

import Person from "../../../../assets/img/person-1.png";

import VisaWhite from "../../../../assets/img/visa-white.svg";

import MasterCardWhite from "../../../../assets/img/master-card-white.svg";

import { LoginForm, RegisterForm, ForgotPasswodForm } from "../../../../components/auth";

import { fetchContract } from "../../../../actions/contractsActions";

import { getCards, purchaseEntry } from "../../../../helpers/payments";

import { addSubscription } from "../../../../helpers/subscription";

import { getTranslate } from "react-localize-redux";

import { useDispatch, useSelector } from "react-redux";

import ReactLoading from "react-loading";

import { getCurrency } from "../../../../services/storeCurrency";

import CURRENCIES from "../../../../constants/currencies";

import { toast } from "react-toastify";

const Details = props => {
  const dispatch = useDispatch();
  const current_user = useSelector(s => s.currentUser);
  const currencies = useSelector(s => s.currencies);

  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [currentCardSelected, setCurrentCardSelected] = useState(null);
  const [payLoading, setPayLoading] = useState(false);
  const [error, setError] = useState("");

  const { translate } = props;

  useEffect(() => {
    getCards(data => catchResult(data));
  }, []);

  const catchResult = data => {
    setCards(data.data);
    setLoading(false);
  };

  //   const goNext = () => {
  //     window.$('#payment-details').modal('toggle');
  //     setTimeout(function(){
  //       window.$('#payment-success').modal('toggle');
  //     }, 250);
  //   }

  //  const goToCreateNewCard = () => {
  //     window.$('#payment-details').modal('toggle');
  //     setTimeout(function(){
  //       window.$('#payment-addCard').modal('toggle');
  //     }, 250);
  //   }

  const onChangeRadioValue = id => {
    setCurrentCardSelected(id);
  };
  
  const displayCards = () => {
    if (loading) {
      return (
        <div
          style={{
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' height={"10%"} width={"10%"} />
        </div>
      );
    }

    return cards.map((card, index) => {
      let { card_number, card_owner, exp_month, exp_year, id, type } = card;
      return (
        <div key={index} className='payment-radio' onClick={() => onChangeRadioValue(id)}>
          <input type='radio' id={id} value={id} className='radio' name='payment-item' onChange={() => null} checked={id === currentCardSelected}/>
          <label htmlFor={id}>
            <div className='payment-radio-icon'>
              <img src={(type = "Visa" ? VisaWhite : MasterCardWhite)} />
            </div>
            <div className='payment-radio-content'>
              <h3>{card_owner}</h3>
              <span className='card-number'>**** **** **** {card_number}</span>
            </div>
          </label>
        </div>
      );
    });
  };

  const purchaseAction = () => {
    let { subscription, plan, interval, packageItem } = props;

    if (!currentCardSelected) {
      setError("Not selected any card");
    } else {
      setError(null);
      setPayLoading(true);
      if (subscription) {
        addSubscription(
          { card_id: currentCardSelected, plan: plan.id, interval: interval },
          () => successCatchResult(),
          (err) => handleErrors(err)
        );
      } else if (packageItem) {
        purchaseEntry(
          { cardId: currentCardSelected, entityType: "package", entityId: props.packageItem?.id },
          () => successCatchResult(),
          (err) => handleErrors(err)
        );
      } else {
        purchaseEntry(
          { cardId: currentCardSelected, entityType: "contract", entityId: props.item?.id },
          () => successCatchResult(),
          (err) => handleErrors(err)
        );
      }
    }
  };

  const successCatchResult = data => {
    props.changeStatus("success");
  };

  const handleErrors = data => {
    setError(data?.errors);
    setLoading(false);
    if(data.code === 'payment_intent_authentication_failure') {
      setPayLoading(false);
      toast.error('Payment failed due to no confirmation');
    }
  };

  const displayPayButton = () => {
    let { translate } = props;
    if (payLoading) {
      return (
        <div
          style={{
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' height={"10%"} width={"10%"} />
        </div>
      );
    }

    return (
      <button className='button button-primary' onClick={() => purchaseAction()}>
        <i className='icon-purchase'></i>
        {translate("payment.pay_now")}
      </button>
    );
  };

  const displayHeaderInfo = () => {
    let { subscription, plan, translate, item, packageItem } = props;
    let currentCurrency = CURRENCIES[getCurrency];

    if (currencies["download_contract"]) {
      return (
        <div className='payment-modal-info'>
          <div className='payment-modal-info-wrap'>
            <h2>{subscription ? plan.name : packageItem ? packageItem?.title : item?.name}</h2>
            <p>
              {subscription
                ? translate("payment.subscription")
                : packageItem
                ? translate("packages.package")
                : translate("payment.contract")}
            </p>
          </div>
          <div className='payment-modal-info-price'>
            {subscription ? (
              <span>
                {currentCurrency.name.toUpperCase()} {plan.price}
              </span>
            ) : packageItem ? (
              <span>
                {currentCurrency.name.toUpperCase()} {packageItem?.price}
              </span>
            ) : (
              <span>
                {currencies["download_contract"][currentCurrency?.name]} -{" "}
                {currentCurrency?.name.toUpperCase()}
              </span>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='payment-modal-wrap modal-ge-wrap'>
      <div className='modal-ge-top payment-modal-top'>
        <h1>{translate("payment.title")}</h1>
      </div>
      <div className='modal-ge-body'>
        <div className='payment-modal-title'>
          <h3>{translate("payment.order_summery")}</h3>
        </div>
        {displayHeaderInfo()}
        <div className='payment-modal-title'>
          <h3>{translate("payment.payment_method")}</h3>
        </div>
        {error && <p style={{ marginBottom: 10, color: "red" }}>{error}</p>}
        {displayCards()}
        <div className='payment-modal-add'>
          <button className='button button-white' onClick={() => props.changeStatus("addCard")}>
            <i className='icon-plus'></i>
            {translate("payment.add_new_payment_method")}
          </button>
        </div>
      </div>
      <div className='modal-ge-bottom'>{displayPayButton()}</div>
      <div className='modal-ge-close' data-dismiss='modal'>
        <i className='icon-x'></i>
      </div>
    </div>
  );
};

export default Details;
