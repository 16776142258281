import {
    FILTERED_LAWFIRMS
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case FILTERED_LAWFIRMS:
        return action.payload
      default:
        return state
    }
  }