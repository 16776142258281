import React
  from "react";

import { connect }
  from 'react-redux';

import { getTranslate }
  from 'react-localize-redux';

import Image
  from "../../../components/shared/Image";

import ReactLoading
  from 'react-loading';

import { ContentForm, EmptyResult }
  from '../../../components/shared/filters';

import { fetchArticlesByFilter }
  from "../../../actions/articlesActions";

import { Link }
  from "react-router-dom";

import ArticleCard
  from '../../../components/knowledge/ArticleCard';

import { getLanguages, getSubCategories }
  from '../../../helpers/getFilterData'

import PaginateItem
  from '../../../components/shared/PaginateItem';

import { SelectItem }
  from '../../../components/shared/forms';

import { Knowledge }
  from "../../../components/shared/sliders";

const FILTERS = { selectedLanguage: "", selectedCategories: [], rating: null }

class KnowledgePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: '',
      selectedLanguage: '',
      loading: true,
      filterSorts: [
        {id: "created_at", name: this.props.translate("lawyers.oldest")},
        {id: "-created_at", name: this.props.translate("lawyers.newest")}
      ],
      action: "link",
      filterSubCategories:[],
      filterLanguages:[],
      searchQuery:'',
      rating:5,
      reloadData: false,
      filtersApplied: false,
      searchLoading: !this.props.guestArticles.data,
      selectedSort: "",
      pathParams: props.history.location.search,
      ...FILTERS
    };
  }

   componentDidMount() {
    this.requestData()

    // if(this.state.pathParams !== "") {
    //   let onlyParams = this.state.pathParams.split('?')[1]
    //   let currentItem = ""
    //   let searchQuery = ""
    //   let selectedSort = ""
    //   let rating = null
    //   let filtersApplied = false
    //   let page = 1
    //   let selectedCategories = []

    //    onlyParams.split("&").forEach(function (kv) {
    //     currentItem = kv.split("=", 2);
    //     if(currentItem[0] === "page") { page = currentItem[1] }
    //     if(currentItem[0].includes("categories")) { selectedCategories.push(parseInt(currentItem[1])); filtersApplied = true }
    //     if(currentItem[0] === "rating") { rating = currentItem[1]; filtersApplied = true }
    //     if(currentItem[0] === "sort") { selectedSort = currentItem[1] }
    //     if(currentItem[0] === "title") { searchQuery = currentItem[1].replace(/%20/g, " ") }
    //   });
    //   this.setState({page, searchQuery, filtersApplied, rating, selectedSort, selectedCategories})
    //   this.requestWithFilters({page: page, title: searchQuery, rating, sort: selectedSort, categories: selectedCategories})
    // } 
  }

   requestData()  {
     if(!this.props.guestArticles.data) {
      this.props.fetchArticlesByFilter({perPage: 10}, this.stopLoading.bind(this))
    }
    getLanguages(this.fillLanguages.bind(this))
    getSubCategories(this.fillSubCategories.bind(this))
  }

  componentWillUnmount() {
    if(this.state.reloadData || this.state.filtersApplied) {
      // this.props.fetchTopRatedContracts(this.stopTopRatingLoading.bind(this));
      this.props.fetchArticlesByFilter(
        { page: this.state.page },
        // this.contractSearchLoadingFunction.bind(this)
      );
    }
  }

  stopLoading() {
    this.setState({loading: false, searchLoading: false})
  }

  fillLanguages(data){
    this.setState({
      filterLanguages:data.data,
    })
  }

  fillSubCategories(data) {
    this.setState({
      filterSubCategories: data.data,
    })
  }

  changeStateValues(event) {
    const { name, value } = event.currentTarget;
    this.setState({[name]: value});
  }

  changeStateMultiSelectedCategories(selectedItems) {
    let selectedIds = selectedItems.map(function(item) { return item.id })
    this.setState({action: "nav", selectedCategories: selectedIds})
  }

  pressApplyFilters(){
    let { page, rating, searchQuery, selectedCategories } = this.state
    this.setState({action: "nav", searchLoading:true, filtersApplied: true})
    this.requestWithFilters({ page: page, rating, title: searchQuery, categories: selectedCategories, perPage: 10 })
  }

  requestWithFilters(filterParams) {
    this.props.fetchArticlesByFilter (
      filterParams,
      this.knowledgeSearchLoadingFunction.bind(this)
    )
  }

  knowledgeSearchLoadingFunction(url){
    let seperateParams = url.split('?')[1]

    if(this.state.action !== "link") {
      this.props.history.push(`/knowledge?${seperateParams}`)
    }
    setTimeout(() => {
      this.setState({searchLoading: false})
    }, 200);
  }

  resetFilterState(){
    this.resetRequestFilters()
  }

  resetRequestFilters() {
    let { page, rating, searchQuery, selectedCategories } = this.state
    this.setState({page, searchLoading: true, ...FILTERS})
    this.requestWithFilters({ name: this.state.searchQuery, sort: this.state.selectedSort, page: page, categories: [], perPage: 10})
    this.setState({action: "nav", filtersApplied: false})
  }

  displayArticles() {
    let { searchLoading } = this.state
    let { translate, guestArticles } = this.props
    let articles = guestArticles.data

    if(searchLoading) {
      return (
        <div style={{ position: 'absolute', height: '50%', width: '100%',
                      display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }
    if(articles && articles.length > 0) {
      return (
        <div className="wal-results-wrap">
          {articles.map((item, index) => {
            return (
              <ArticleCard item={item} key={item.id} />
            )
          })}
        </div>
      )
    } else {
      return (
        <EmptyResult
          title={translate('articles.no_results_found')}
          description={translate('articles.empty_description')}
          icon={"filters"}
        />
      )
    }
  }

  requestNewPageData(e, page) {
    e.preventDefault()
    let { rating, searchQuery, selectedCategories } = this.state
    this.setState({action: "nav", searchLoading: true, page: page})
    let filterParams = { page: page, title: searchQuery, categories: selectedCategories, perPage: 10 }
    this.props.fetchArticlesByFilter(
      filterParams,
      this.knowledgeSearchLoadingFunction.bind(this)
    )
  }

  changeSortBy(event) {
    let { page, rating, selectedSort, searchQuery, selectedCategories } = this.state
    const { name, value } = event.currentTarget;
    this.setState({action: "nav", selectedSort: value, searchLoading: true})
    this.requestWithFilters({ page: page, title: searchQuery, sort: value, categories: selectedCategories })
  }

  searchInputFinishedWriting(input){
    let { page, rating, searchQuery, selectedCategories } = this.state
    input.length > 0 && this.setState({reloadData: true})
    this.setState({action: "nav", searchQuery: input, searchLoading:true})
    this.requestWithFilters({ page: page, rating, title: input, categories: selectedCategories })
  }

  render() {
    let { translate, guestArticles } = this.props
    let articles = guestArticles.data


    return (
      <>
        <title>{translate('articles.knowledge')} | WAL</title>
        <main className="main">

        <Knowledge
          title={translate('articles.aticles_of_the_Week')}
          cover={"mg-top-180"}
        />

        <section className="wal-content mg-top-112 pd-rl">
          <div className="container-fluid">
            <div className="wal-content-head">
              <div className="row align-items-center">
                  <div className="col-md-12 col-lg-4">
                    <div className="wal-content-title">
                      <h1>{translate('articles.title')} <span>({guestArticles.meta?.total})</span></h1>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="wal-form wal-form-group wal-form-search">
                      <input
                        type="search"
                        name="Search articles"
                        placeholder={translate('articles.search_articles')}
                        className="wal-form-control"
                        value={this.state.searchQuery}
                        onChange={(event)=> this.searchInputFinishedWriting(event.target.value)}
                        autoComplete="false"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="wal-form wal-form-group wal-form-sort">
                      <div className="wal-form-sort-wrap">
                        <SelectItem
                          field={{styles: "wal-form-sort", label: translate('contracts.sort_by'), name: "selectedSort", value: this.state.selectedSort, onChange: this.changeSortBy.bind(this), options: this.state.filterSorts}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wal-content-body">
                <div className="row">
                  <ContentForm
                    selectedFields={[
                      // {label: translate('lawyers.languages'), name: "selectedLanguage", value: this.state.selectedLanguage, onChange: this.changeStateValues.bind(this), options: this.state.filterLanguages}
                    ]}
                    multiSelect={ this.state.filterSubCategories.length > 0 ? {items: this.state.filterSubCategories, onChange: this.changeStateMultiSelectedCategories.bind(this), currentSelected: this.state.selectedCategories} : null }
                    translate={translate}
                    rateObject={{rating: this.state.rating, changeRating: (value) => this.setState({rating: value})}}
                    pressApplyFilters={this.pressApplyFilters.bind(this)}
                    resetFilterState={this.resetFilterState.bind(this)}
                    filtersApplied={this.state.filtersApplied}
                  />
                <div className="col-md-12 col-lg-12 col-xl-8">
                  <div className="wal-results">
                    {this.displayArticles()}
                  </div>
                  <PaginateItem
                    item={this.props.guestArticles}
                    requestNewPageData={this.requestNewPageData.bind(this)}
                    searchLoading={this.state.searchLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )};
}

function mapStateToProps(state){
    return ({
              translate: getTranslate(state.locale),
              guestArticles: state.guestArticles,
              topArticles: state.articles
            })
    }

export default connect(mapStateToProps, {fetchArticlesByFilter})(KnowledgePage);
