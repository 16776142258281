import React
    from 'react';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { AddCard, Success } from "../"
import CompanyDetails from "./CompanyDetails"

class CompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status || "details"
    };
  }

  changeStatus(status) {
    this.setState({status: status})
  }

  render() {
    let { status } = this.state
    return (
      <div className="modal fade modal-ge payment-modal" id="subscrption-company-modal" data-backdrop="static" tabIndex="-1" data-keyboard="false" role="dialog" aria-hidden="true" data-keyboard="false">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            { status === "details" && <CompanyDetails {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
            { status === "addCard" && <AddCard {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} notClose /> }
            { status === "success" && <Success {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(CompanyModal);
