import React
  from 'react'
import Slider
  from 'react-rangeslider'

class EmptyResult extends React.Component {
  render() {
    let title="No results found"
    let description = "Try adjusting your filter to find what you looking for."
    let { icon, action } = this.props
    return (
      <div className="wal-result-none">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="wal-result-none-wrap">
                <div className="wal-result-none-icon">
                  <i className="icon-type-contracts"></i>
                  <i className={`icon-${icon}`}></i>
                </div>
                <h1>{this.props.title || title }</h1>
                <p>{this.props.description || description }</p>
                {action && <a href={`${action.goTo}`} className="button button-white">{action.label}</a>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmptyResult
