import React, {useEffect} from "react";

import Image from "../../components/shared/Image";

import { Link } from "react-router-dom";

const LawyerInfoWide = (props) => {
  useEffect(() => window.slider(), []);
  return (
    <Link to={"/lawyers/" + props.id} key={props.index} className='rated-lawyers-item'>
      <Image src={props.imageSource} alt='lawyer' />
      <div className='rated-lawyers-content'>
        <h1>{props.name}</h1>
        <div className='rated-lawyers-bottom'>
          <div className='rated-stars'>
            {[0, 1, 2, 3, 4].map(index => {
              return (
                <i
                  key={index}
                  className={
                    index + 1 <= Math.round(parseInt(props.rating))
                      ? "icon-star-filled"
                      : "icon-star"
                  }
                ></i>
              );
            })}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LawyerInfoWide;
