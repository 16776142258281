import api from "../api";
import { LAWFIRMS, FILTERED_LAWFIRMS, TOP_LAWFIRMS, LAWFIRMS_BY_ID } from "../constants/types";
import { WEB_URL } from "../constants/types";
import { getLanguageAsNumber } from "../services/storeLanguage";

export const getLawfirms = () => {
  return async dispatch => {
    let url = `${WEB_URL}/all-companies?language_id=${getLanguageAsNumber}`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: LAWFIRMS, payload: res.data });
      })
      .catch(err => {});
  };
};

export const getTopLawfirms = () => {
  return async dispatch => {
    let url = `${WEB_URL}/all-companies?top_rated=1&per_page=10`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: TOP_LAWFIRMS, payload: res.data.data });
      })
      .catch(err => {});
  };
};

export const getLawfirmsById = (id, stopLoading, bookmarkFunction) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-companies/${id}?language_id=${getLanguageAsNumber}`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: LAWFIRMS_BY_ID, payload: res.data.data });
        stopLoading();
        bookmarkFunction && bookmarkFunction(res.data.data.is_bookmarked)
      })
      .catch(err => {});
  };
};

export const getLawfirmsByFilter = (params, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-companies?language_id=${getLanguageAsNumber}&`;

    if (params) {
      if (params.page && params.page !== "" && params.page !== null) {
        url += `page=${params.page}&`;
      }
      if (params.rating && params.rating !== "") {
        url += `rating=${params.rating}&`;
      }
      if (params.bar_verified && params.bar_verified !== "") {
        url += `bar_verified=${params.bar_verified}&`;
      }
      if (params.category && params.category !== "" && params.category !== null) {
        url += `category_id=${params.category}&`;
      }
      if (params.language && params.language !== "" && params.language !== null) {
        url += `language_id=${params.language}&`;
      }
      if (params.radius && params.radius !== "" && params.radius !== 30) {
        url += `radius=${params.radius}&`;
      }
      if (params.name && params.name !== "") {
        url += `name=${params.name}&`;
      }
      if (params.sort && params.sort !== "") {
        url += `sort=${params.sort}&`;
      }
      if (params.categories && params.categories.length > 0) {
        let categoryLink = "";
        params.categories.map((cat, index) => {
          return (categoryLink = categoryLink + `categories_id[${index}]=${cat}&`);
        });
        url += categoryLink;
      }
      if (params.jurisdictions && params.jurisdictions.length > 0) {
        let jurisdictions = "";
        params.jurisdictions.map((cat, index) => {
          return (jurisdictions = jurisdictions + `jurisdictions[${index}]=${cat}&`);
        });
        url += jurisdictions;
      }
    }

    await api
      .get(url)
      .then(res => {
        dispatch({ type: FILTERED_LAWFIRMS, payload: res.data });
        stopLoading();
      })
      .catch(err => {});
  };
};
