import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { getTranslate } from 'react-localize-redux';

import { fetchTopRatedLawyers, fetchLawyersByFilter } from '../../../actions/lawyersActions';

import { fetchContractsByFilter, fetchTopRatedContracts } from '../../../actions/contractsActions';

import { useDispatch, useSelector } from 'react-redux';

import { getTopLawfirms, getLawfirmsByFilter } from '../../../actions/lawfirmsActions';

import { fetchArticlesByFilter, fetchArticlesForSlider } from '../../../actions/articlesActions';

import _ from 'lodash';

import ReactLoading from 'react-loading';

import { RecommendedLawyers, Knowledge } from '../../../components/shared/sliders';

import LawyersInYourRegion from '../../../components/shared/LawyersInYourRegion';

import CourtsCases from '../../../components/home/CourtsCases';

import ContractAndTemplatesOfUser from '../../../components/home/ContractAndTemplatesOfUser';

import { lawyersRecommended } from '../../../actions/lawyersActions';

import { getSubInfo, removeSubInfo } from '../../../helpers';

import WaleXplainedVideoPopup from '../../../components/home/WalExplainedVideoPopup';
import walVideoImg from '../../../assets/img/video-img.png';

const HomePage = () => {
  const dispatch = useDispatch();
  const translate = useSelector(s => getTranslate(s.locale));
  const currentUser = useSelector(s => s.currentUser);
  const recommendedLawyers = useSelector(s => s.lawyersRecommended);

  const [loading, setLoading] = useState(!recommendedLawyers.data);
  const [showVideo, setShowVideo] = useState(false);
  // const [recommendedLawyers, setRecommendedLawyers] = useState(null);

  useEffect(() => {
    requestData();
  }, []);

  const requestData = async () => {
    window.slider();
    // if(document.referrer === window.location || !window.location.includes(window.location.origin)) {
    //   await dispatch(getTopLawfirms());
    //   await dispatch(getLawfirmsByFilter({sort: '-created_at'}, () => null));
    //   await dispatch(fetchTopRatedLawyers(null, () => null));
    //   await dispatch(fetchLawyersByFilter(null, () => null));
    //   await dispatch(fetchContractsByFilter(null, () => null));
    //   await dispatch(fetchTopRatedContracts(null, () => null));
    //   await dispatch(fetchArticlesByFilter(null, () => null));
    //   await dispatch(fetchArticlesForSlider(null, () => null));
    // }
    if (!recommendedLawyers.data) {
      await dispatch(lawyersRecommended(stopLoading));
    }
  };

  const stopLoading = () => {
    setLoading(false);
    checkSubscription();
  };

  const checkSubscription = () => {
    if (currentUser && !getSubInfo()) {
      window.$('#choose_subscription').modal('toggle');
    }
  };

  if (loading) {
    return (
      <div style={{ marginLeft: '48%', marginTop: '15%' }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    );
  }

  return (
    <div>
      <title>{translate('home.app_title')} | WAL</title>
      {showVideo ? <WaleXplainedVideoPopup handleVideoClick={() => setShowVideo(false)} /> : undefined}
      <main className='main'>
        <div className='wal-video-home'>
          <div className='wal-video-content'>
            <button
              type='button'
              className='wal-video-button-home popup-video'
              onClick={() => setShowVideo(true)}
              href='https://www.youtube.com/watch?v=wIekLj5Eehs'
            >
              {translate('home.wal_explained')}
              <svg width='16' height='16'>
                <path d='M8 1.6c1.977 0 3.622.058 4.937.174a3.067 3.067 0 0 1 2.796 3.055v6.342a3.067 3.067 0 0 1-2.796 3.055c-1.14.1-2.527.157-4.164.17L8 14.4c-1.977 0-3.622-.058-4.937-.174A3.067 3.067 0 0 1 .267 11.17V4.829a3.067 3.067 0 0 1 2.796-3.055c1.14-.1 2.527-.157 4.164-.17zm0 .8c-1.955 0-3.578.057-4.867.171A2.267 2.267 0 0 0 1.067 4.83v6.342c0 1.174.897 2.154 2.066 2.258 1.117.099 2.485.155 4.103.168L8 13.6c1.955 0 3.578-.057 4.867-.171a2.267 2.267 0 0 0 2.066-2.258V4.829a2.267 2.267 0 0 0-2.066-2.258c-1.117-.099-2.485-.155-4.103-.168zM6.883 5.818c.088 0 .174.025.247.071L9.89 7.63a.433.433 0 0 1 0 .74l-2.76 1.74a.462.462 0 0 1-.628-.129.432.432 0 0 1-.073-.24V6.259c0-.243.203-.44.454-.44z' />
              </svg>
            </button>
          </div>
        </div>
        <RecommendedLawyers recommendedLawyers={recommendedLawyers} />
        <LawyersInYourRegion cover={'mg-top-160'} />
        <Knowledge title={translate('header.tabs.knowledge')} cover={'mg-top-112'} viewMore />
        {/* <CourtsCases /> */}
        <ContractAndTemplatesOfUser />
      </main>
    </div>
  );
};

// function mapStateToProps(state) {
//   return {
//     current_user: state.currentUser,
//     recommendedLawyers: state.lawyersRecommended
//   };
// }

export default HomePage;
