import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getBookmarkedPackages, getBookmarkedLawfirms } from "../../actions/profile";

import PackageCard from "../../pages/inside/packages/PackageCard";
import LawfirmCard from "../../pages/inside/lawfirms/LawfirmCard";
import LawyerCard from "../../components/lawyers/LawyerCard";
import KnowledgeCard from "../lawyers/KnowledgeCard";
import ContractsSearched from "../../components/contracts/ContractsSearched";
import { Modal } from "../shared/modals/payment";

const BookmarksSection = props => {
  const dispatch = useDispatch();
  const bookmarkedPackages = useSelector(s => s.bookmarkedPackages);
  const bookmarkedLawfirms = useSelector(s => s.bookmarkedLawfirms);

  const [bookmarkedPackagesPerPage, setBookmarkedPackagesPerPage] = useState(3);
  const [bookmarkedLawfirmsPerPage, setBookmarkedLawfirmsPerPage] = useState(3);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const articles = props.articleBookmarks.data;

  useEffect(() => {
    dispatch(getBookmarkedPackages(bookmarkedPackagesPerPage, null));
    dispatch(getBookmarkedLawfirms(bookmarkedLawfirmsPerPage, null));
  }, []);

  const loadMoreBookmarkedPackages = () => {
    setBookmarkedPackagesPerPage(bookmarkedPackagesPerPage + 3);
    dispatch(getBookmarkedPackages(bookmarkedPackagesPerPage + 3, null));
  };

  const loadMoreBookmarkedLawfirms = () => {
    setBookmarkedLawfirmsPerPage(bookmarkedLawfirmsPerPage + 3);
    dispatch(getBookmarkedLawfirms(bookmarkedLawfirmsPerPage + 3, null));
  };

  return (
    <div className='tab-pane active' id='bookmarks'>
      <div className='panel panel-default'>
        <div className='panel-heading'>
          <h4 className='panel-title nav-list'>
            <a
              data-toggle='collapse'
              data-target='#collapse1'
              aria-expanded='true'
              data-parent='#tabs'
            >
              {props.translate("profile.favorites")}
            </a>
          </h4>
        </div>
        <div id='collapse1' className='panel-collapse collapse show' data-parent='#tabs'>
          <div className='panel-body nav-content'>
            {props.contractBookmarks?.length > 0 ||
            props.lawyerBookmarks?.length > 0 ||
            props.articleBookmarks?.length > 0 ||
            bookmarkedPackages.data?.length > 0 ||
            bookmarkedLawfirms.data?.length > 0 ? (
              <>
                <div className='profile-article'>
                  <h1>{props.translate("profile.lawyers")}</h1>
                </div>
                <div className='profile-lawyers'>
                  {props.lawyerBookmarks.map((item, index) => {
                    return <LawyerCard item={item} key={index} />;
                  })}
                  {props.lawyersLastPage > props.lawyersPage && (
                    <a onClick={props.lawyerMoreClick} className='more-button button button-gray'>
                      {props.translate("lawyers.details.load_more")}
                    </a>
                  )}
                </div>
                {bookmarkedLawfirms.data?.length > 0 && (
                  <>
                    <div className='profile-article' style={{ marginTop: 20 }}>
                      <h1>{props.translate("header.tabs.lawfirms")}</h1>
                    </div>
                    <div className='profile-lawyers'>
                      {bookmarkedLawfirms.data.map(item => (
                        <LawfirmCard key={item.id} item={item} />
                      ))}
                      {bookmarkedLawfirms.meta?.total > bookmarkedLawfirmsPerPage && (
                        <a
                          onClick={() => loadMoreBookmarkedLawfirms()}
                          className='more-button button button-gray'
                        >
                          {props.translate("lawyers.details.load_more")}
                        </a>
                      )}
                    </div>
                  </>
                )}
                {props.contractBookmarks?.length > 0 && (
                  <>
                    <div className='profile-article'>
                      <h1>{props.translate("profile.contracts")}</h1>
                    </div>

                    <div className='profile-contracts'>
                      <ContractsSearched
                        items={props.contractBookmarks}
                        selectedContract={contractItem => {setSelectedContract(contractItem); setSelectedPackage(null)}}
                      />
                    </div>
                    {props.contractsLastPage > props.bookmarkContractsPage && (
                      <a
                        onClick={props.moreContractsBookmarksClick}
                        className='more-button button button-gray'
                      >
                        {props.translate("lawyers.details.load_more")}
                      </a>
                    )}
                  </>
                )}
                {bookmarkedPackages.data?.length > 0 && (
                  <>
                    <div className='profile-article' style={{ marginTop: 40 }}>
                      <h1>{props.translate("packages.packages")}</h1>
                    </div>
                    <div className='profile-contracts'>
                      {bookmarkedPackages.data.map(item => (
                        <PackageCard item={item} key={item.id} translate={props.translate} selectedPackage={packageItem => {setSelectedPackage(packageItem); setSelectedContract(null)}} />
                      ))}
                      {bookmarkedPackages.meta?.total > bookmarkedPackagesPerPage && (
                        <a
                          onClick={() => loadMoreBookmarkedPackages()}
                          className='more-button button button-gray'
                        >
                          {props.translate("lawyers.details.load_more")}
                        </a>
                      )}
                    </div>
                  </>
                )}
                {articles?.length > 0 && (
                  <>
                    <div className='profile-article' style={{ marginTop: 40 }}>
                      <h1>{props.translate("search.articles")}</h1>
                    </div>

                    <div className='row'>
                      {articles.map(item => (
                        <KnowledgeCard
                          key={item.id}
                          id={item.id}
                          author={item.lawyer?.name}
                          title={item.title}
                          source={item.image}
                          viewed={item.viewed}
                          type={item.categories && item.categories[0].lang.name}
                        />
                      ))}
                    </div>
                    {props.articleBookmarks.meta?.total > props.articlesPerPage && (
                      <a
                        onClick={props.articlesMoreClick}
                        className='more-button button button-gray'
                      >
                        {props.translate("lawyers.details.load_more")}
                      </a>
                    )}
                  </>
                )}
              </>
            ) : (
              <div id='collapse2' className='panel-collapse collapse' data-parent='#tabs'>
                <div className='panel-body nav-content'>
                  <div className='wal-result-none'>
                    <div className='row justify-content-center'>
                      <div className='col-md-8 col-lg-6'>
                        <div className='wal-result-none-wrap'>
                          <div className='wal-result-none-icon'>
                            <i className='icon-bookmarks'></i>
                          </div>
                          <h1>{props.translate("profile.no_bookmarks_yet")}</h1>
                          <p>{props.translate("profile.bookmark_info")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal item={selectedContract} packageItem={selectedPackage}/>
    </div>
  );
};

export default BookmarksSection;
