import React
  from 'react';

import DisplayRating from '../../shared/DisplayRating';

import Image
  from '../../../components/shared/Image'

import moment
  from 'moment';

class ContractComment extends React.Component {
  render() {
    let { comment } = this.props
    return (
      <div className="comments-item">
        <div className="comments-info">
          <div className="comments-img">
            <Image src={comment.user && comment.user.avatar} alt="currentUser" />
          </div>
          <div className="comments-info-wrap">
            <h1>{comment.user && comment.user.name}</h1>
            <span>{moment(comment.updated_at).format("DD MMM YYYY")}</span>
          </div>
        </div>
        { this.props.currentUser &&
          <div className="comments-content">
            <p>{comment.comment}</p>
            { comment.user && this.props.currentUser.id == comment.user.id &&
              <span className="delete" onClick={() => this.props.setDeleteItem(comment.id)}>{this.props.translate("packages.delete")}</span>
            }
          </div>
        }
      </div>
    )
  }
}

export default ContractComment
