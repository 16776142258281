import React from "react";

const ConfirmChangeType = (props) => {
  return (
    <div className="modal fade modal-ge" id="change-type" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="payment-modal-wrap modal-ge-wrap">
                    <div className="modal-ge-content">
                        <h1>{props.translate("modals.change_type_user.title")}</h1>
                        <p>{props.translate("modals.change_type_user.first_description")} {props.profileType == "company" ? "user" : "company"} ? {props.translate("modals.change_type_user.second_description")}</p>
                        <div className="modal-ge-bottom-buttons">
                            <a href="#" className="button button-white" data-dismiss="modal">{props.translate("modals.cancel")}</a>
                            <a href="#" className="button button-primary" onClick={props.approve.bind(this)}>{props.translate("modals.change")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ConfirmChangeType;
