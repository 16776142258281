import { applyMiddleware, compose, createStore } from "redux";
import { initialize, addTranslationForLanguage } from "react-localize-redux";

import reducers from "../reducers";

import reduxThunk from "redux-thunk";

import { default as en } from "../translations/en.json";
import { default as de } from "../translations/de.json";
import { default as tr } from "../translations/tr.json";
import { default as es } from "../translations/es.json";
import { default as it } from "../translations/it.json";
import { default as fr } from "../translations/fr.json";
import { default as por } from "../translations/por.json";

// const createStoreWithMiddleware = compose(applyMiddleware(reduxThunk))(createStore);
// const store = createStoreWithMiddleware(reducers);

const enhancers = [applyMiddleware(reduxThunk)];

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

const store = createStore(reducers, composeEnhancers(...enhancers));

const languages = ["en", "de", "tr", "es", "it", "fr", "por"];
let defaultLanguage = localStorage.getItem("language");

store.dispatch(
  initialize(languages, { defaultLanguage: defaultLanguage || "en" })
);

store.dispatch(addTranslationForLanguage(en, "en"));
store.dispatch(addTranslationForLanguage(de, "de"));
store.dispatch(addTranslationForLanguage(tr, "tr"));
store.dispatch(addTranslationForLanguage(es, "es"));
store.dispatch(addTranslationForLanguage(it, "it"));
store.dispatch(addTranslationForLanguage(fr, "fr"));
store.dispatch(addTranslationForLanguage(por, "por"));

export default store;
