import React from "react";

import Person from "../../../assets/img/person-1.png";

import { LoginForm, RegisterForm, ForgotPasswodForm } from "../../../components/auth";

import { getTranslate } from "react-localize-redux";

import { reportLawyer } from "../../../helpers/reportLawyer";

import { toast } from "react-toastify";

class ReportLawyerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      reason: "Inappropriate content",
    };
  }

  reportLawyer() {
    let { lawyerId, lawfirmId } = this.props;
    const type = lawfirmId ? "law_firm" : "lawyer";
    if (this.state.description.trim() == "") {
      toast.error("Message is blank");
    } else {
      reportLawyer(this.state.reason, this.state.description, type, lawyerId || lawfirmId);
      this.setState({ description: "", reason: "" });
      window.$("#report").modal("toggle");
    }
  }

  selectReason(data) {
    this.setState({ reason: data });
  }

  handleOnChange(evt) {
    const { value } = evt.target;
    this.setState({ description: value });
  }

  render() {
    const { translate, lawfirmId } = this.props;
    return (
      <div
        className='modal fade modal-ge'
        id='report'
        data-backdrop='static'
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-ge-wrap'>
              <div className='modal-ge-top'>
                <h1>
                  {lawfirmId
                    ? translate("lawfirms.reportLawfirm")
                    : translate("lawyers.details.report_lawyer")}
                </h1>
                <p>{translate("lawyers.details.report_explain_reason")}</p>
              </div>
              <div className='modal-ge-body'>
                <div className='wal-form wal-form-group'>
                  <label htmlFor='select'>{translate("lawyers.details.report_reason")}</label>
                  <div className='wal-form-sort-wrap'>
                    <select
                      onChange={event => this.selectReason(event.target.value)}
                      className='wal-form-control'
                    >
                      <option value='Inappropriate content'>
                        {translate("lawyers.details.report_inappropriate")}
                      </option>
                      <option value='Pretending to be someone else'>
                        {translate("lawyers.details.report_pretending")}
                      </option>
                      <option value='Hate speech'>
                        {translate("lawyers.details.report_hate")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className='wal-form wal-form-group'>
                  <label htmlFor='select'>{translate("lawyers.details.report_tell_more")}</label>
                  <textarea
                    className='wal-form-control'
                    name='more'
                    onChange={event => this.handleOnChange(event)}
                    value={this.state.description}
                    placeholder={translate("lawyers.details.report_explain_more")}
                  ></textarea>
                </div>
              </div>
              <div className='modal-ge-bottom modal-ge-bottom-buttons'>
                <a className='button button-white' data-dismiss='modal'>
                  {translate("settings.edit_profile.cancel")}
                </a>
                <a onClick={() => this.reportLawyer()} className='button button-bl'>
                  {lawfirmId
                    ? translate("lawfirms.reportLawfirm")
                    : translate("lawyers.details.report_lawyer")}
                </a>
              </div>
              <div className='modal-ge-close' data-dismiss='modal'>
                <i className='icon-x'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportLawyerModal;
