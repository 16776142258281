import React
  from 'react';

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

import Person
  from '../../../assets/img/person-7.png';

import Velocity
  from '../../../assets/img/logo-black.svg';

import ContractComment
  from './ContractComment';

import { getContractComments, createContractComments, deleteContractComment, reviewContract }
  from '../../../helpers/getComments'

import Image
  from "../../../components/shared/Image";

import { connect }
  from "react-redux";

import DeleteAlertModal
  from '../../../components/shared/modals/DeleteAlertModal';

class ContractContentComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      message: "",
      loading: true,
      deleteItem: null
    };
  }

  componentDidMount() {
    let { contractId } = this.props
    getContractComments(contractId, this.fillComments.bind(this))
  }

  fillComments(data) {
    this.setState({
      comments:data.data,
      loading: false
    })
  }

  setDeleteItemFromModal(itemId) {
    this.setState({deleteItem: itemId})
    window.$('#delete').modal('toggle');
  }

  displayComments() {
    let { translate } = this.props
    let { comments, loading } = this.state
    if(loading) {
      return (
        <div style={{marginLeft: "43%"}}>
          <ReactLoading type="spin" color="#bea58b" height={'5%'} width={'5%'} />
        </div>
      )
    } else {
      if(comments.length > 0) {
        return (
          comments.map((comment) => {
            return (
              <ContractComment
                key={comment.id}
                comment={comment}
                currentUser={this.props.currentUser}
                setDeleteItem={(itemId) => this.setDeleteItemFromModal(itemId)}
                translate={this.props.translate}
              />
            )
          })
        )
      } else {
        return (
          <div style={{ height: '20%', width: '100%',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p>{translate('contracts.details.not_found_comments')}</p>
          </div>
        )
      }
    }
  }

  requestCreateMessage(e) {
    e.preventDefault()
    let { contractId } = this.props
    if(this.state.message.trim() == "") {
      alert("Comment message not blank")
    } else {
      this.setState({loading: true, message: ""})
      createContractComments(contractId, this.state.message, this.checkCreateComments.bind(this))
    }
  }

  checkCreateComments() {
    let { contractId } = this.props
    getContractComments(contractId, this.fillComments.bind(this), this.successDeleteComment.bind(this))
  }

  confirmDelete(e) {
    e.preventDefault()
    let { deleteItem } = this.state
    let { contractId } = this.props

    window.$('#delete').modal('toggle');
    deleteContractComment(contractId, deleteItem, this.successDeleteComment.bind(this))
  }

  successDeleteComment() {
    this.checkCreateComments()
  }

  render() {
    let { currentUser, translate, isPurchased } = this.props
    return(
      <div>
        <div className="main-title">
          <h2>{translate('contracts.details.comments')}</h2>
        </div>
        <div className="comments">
          { currentUser &&
            <div className="comments-head">
              <div className="comments-img">
                <Image
                  src={currentUser.avatar}
                  alt="currentUser"
                />
              </div>
              <div className="comments-input">
                <input
                  type="text"
                  className="wal-form-control"
                  placeholder={translate('contracts.details.write_comment')}
                  value={this.state.message}
                  onChange={(e) => this.setState({message: e.target.value})}
                />
                <div className="comments-send">
                  <a href="/#" className="button button-white" onClick={(e) => this.requestCreateMessage(e)}>
                    <i className="icon-send"></i>{translate('contracts.details.post')}
                  </a>
                </div>
              </div>
            </div>
          }
          <div className="comments-body">
            {this.displayComments()}
          </div>
        </div>
        <DeleteAlertModal
          title={translate('contracts.details.delete_comment')}
          description={translate('contracts.details.delete_comment_description')}
          confirmDelete={this.confirmDelete.bind(this)}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  };
}

export default connect(mapStateToProps, {})(ContractContentComment)
