import React, { useState } from "react";
import ReactLoading from "react-loading";

import PackageCard from "../../packages/PackageCard";

const PackageTab = props => {
  const {
    translate,
    data,
    packagesSearchInput,
    allPackagesLoading,
    packageInput,
    onLoadMoreButton,
    sortPackages,
    loadingPackages,
    packagesToDisplay,
    selectedPackage,
  } = props;

  const displayPackages = () => {
    return allPackagesLoading ? (
      <div style={{ marginLeft: "48%" }}>
        <ReactLoading type='spin' color='#bea58b' />
      </div>
    ) : (
      data.data?.map(item => (
        <PackageCard
          item={item}
          translate={translate}
          key={item.id}
          id={item.id}
          selectedPackage={packageId => selectedPackage(packageId)}
        />
      ))
    );
  };

  return (
    <div className='tab-pane' id='packages'>
      <div className='panel panel-default'>
        <div className='panel-heading'>
          <h4 className='panel-title nav-list'>
            <a
              data-toggle='collapse'
              data-target='#collapse4'
              aria-expanded='false'
              data-parent='#tabs'
            >
              {translate("packages.packages")}
            </a>
          </h4>
        </div>
        <div id='collapse4' className='panel-collapse collapse' data-parent='#tabs'>
          <div className='panel-body nav-content'>
            <div className='profile-contracts'>
              <div className='wal-content-head'>
                <div className='row align-items-center'>
                  <div className='col-md-12 col-lg-2'>
                    <div className='wal-content-title'>
                      <h1>
                        <span>
                          {data.meta?.total} {translate("lawyers.details.total")}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-5'>
                    <div className='wal-form wal-form-group wal-form-search'>
                      <input
                        type='search'
                        name='Search Packages'
                        placeholder={translate("packages.search_packages")}
                        className='wal-form-control'
                        value={packageInput}
                        onChange={event => packagesSearchInput(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-5'>
                    <div className='wal-form wal-form-group wal-form-sort'>
                      <label htmlFor='select'>{translate("lawyers.sort_by")}:</label>
                      <div className='wal-form-sort-wrap'>
                        <select
                          onChange={event => sortPackages(event.target.value)}
                          className='wal-form-control'
                        >
                          <option value='-created_at'>
                            {translate("lawyers.details.newest_first")}
                          </option>
                          <option value='created_at'>
                            {translate("lawyers.details.oldest_first")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='wal-results-wrap'>{displayPackages()}</div>
              {loadingPackages ? (
                <div style={{ marginLeft: "48%" }}>
                  <ReactLoading type='spin' color='#bea58b' />
                </div>
              ) : data.meta?.total > packagesToDisplay ? (
                <div onClick={() => onLoadMoreButton()} className='more-button button button-gray'>
                  {translate("lawyers.details.load_more")}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageTab;
