import React
  from "react";

import { connect }
  from "react-redux";

import Pdf
  from '../../assets/files/privacy_policy.pdf';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-page">
            <div className="settings-page-wrap">
              <h4>Privacy Policy</h4>
              <ul>
                <li><span>Legal Tech GmbH</span></li>
                <li>Haldenstrasse 5</li>
                <li>6340 Baar</li>
                <li><a href="mailto:info@wal.world">info@wal.world</a></li>
              </ul>
              <h3><span>What are cookies and what do they do?</span></h3>
              <p>Cookies are text files that are placed on websites on the internet. Each cookie includes the name of the website and a unique ID. We use cookies on this website.</p>
              <p>Cookies track things like how long a visitor spends on our website and what visitors do on our web-site (which pages they visit and which buttons they click on, for example). Cookies collect this in-formation and share it with us, the website owner.</p>
              <p>Cookies help us understand who uses our website and how we can improve it. For instance, we can understand which pages of our website are most popular with certain kinds of visitors. This allows us to understand what visitors like about our website and how we can make it more attrac-tive for other visitors.</p>
              <h3><span>How do we use cookies?</span></h3>
              <p>As we set out above, we use cookies on the WAL website. Cookies help us to understand how our users interact with our website. Most of the cookies we use on our website are ‘persistent’ cook-ies. This means that they remain on your device until you erase them or they expire. The rest of these cookies are ‘session’ based which are more temporary in duration. This means they will be erased when you close your internet browser.</p>
              <h3><span>We use the following types of cookies:</span></h3>
              <p>Necessary cookies: Necessary cookies are placed on our website by default. You can turn off these cookies on your device if you don’t want us to use necessary cookies but it might impact your abil-ity to browse our website. We need necessary cookies to help us to run our website, and to help us detect any problems with it. For example, we use a cookie that tells us how busy the server is based on the number of visitors and that tells us if any users experience problems so that we can resolve the problems as soon as possible. We also use cookies to remember our users while they are browsing our website, its pages, links and subdomains. For example, we use necessary cookies to help recognize you and provide you with content as you browse the website. We may also use a cookie that tracks your cookie privacy preferences so that we do not place analytics and advertising cookies, which we’ll talk about below, if you don’t want us to. This particular type of cookie will remain on your device even after you’ve stopped browsing the website so we can make sure we comply with our legal and regulatory obligations.</p>
              <p>Analytics and advertising cookies: These allow us to recognize and count the number of visitors to our website, and see how visitors browse our website, so we can understand how our website content is performing and improve it where necessary. We use third party cookies for this purpose and we’ve explained in the table below the specific cookies we use. Advertising and analytics cook-ies also allow us to see what pages, links and sub-domains visitors have visited so we can provide more relevant ads. We may share this information with other organisations, such as Google, Face-book and LinkedIn, for the same purpose.</p>
              <div className="settings-download">
                <a href={Pdf} className="button button-primary" download><i className="icon-download"></i>Download PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(PrivacyPolicy);
