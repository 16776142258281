import {
    USER_LAWYER_INTERACTIONS
  } from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case USER_LAWYER_INTERACTIONS:
      return action.payload
    default:
      return state
  }
}
