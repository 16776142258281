import { PACKAGE_REVIEWS } from "../constants/types";

export default function (state = [], action) {
  switch (action.type) {
    case PACKAGE_REVIEWS:
      return action.payload;
    default:
      return state;
  }
}
