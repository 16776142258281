import React from 'react';

import { Link } from 'react-router-dom';

import HomePageUser from './HomePageUser';

import LandingPage from './LandingPage';

import { getToken } from '../../../helpers';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { modalClick } = this.props;

    return getToken() ? <HomePageUser /> : <LandingPage modalClick={modalClick} />;
  }
}

export default HomePage;
