import { combineReducers } from "redux";

import { localeReducer as locale } from "react-localize-redux";

import currentUser from "./currentUserReducer";

import topRatedLawyersReducer from "./topRatedLawyers";

import lawyerRecommendedReducer from "./lawyerRecommended";

import topRatedContractsReducer from "./topRatedContracts";

import allContractsReducer from "./allContracts";

import contractsWithFiltersReducer from "./contractsWithFiltersReducer";

import lawyersByRegionReducer from "./lawyersByRegion";

import lawyersByFilterReducer from "./lawyersByFilter";

import allLawyersReducer from "./allLawyers";

import lawyersForMapReducer from "./lawyersForMap";

import countriesReducer from "./countriesReducer";

import interestsReducer from "./interestsReducer";

import lawyerByIdReducer from "./lawyerById";

import contractReducer from "./contractReducer";

import lawyersContractsReducer from "./lawyerContracts";

import lawyersArticlesReducer from "./lawyerArticles";

import articleReducer from "./articleReducer";

import currentUserContractsReducer from "./currentUserContracs";

import currentUserBookmarksLawyersReducer from "./currentUserBookmarksLawyers";

import currentUserBookmarksArticlesReducer from "./currentUserBookmarksArticles";

import currentUserBookmarksContractsReducer from "./currentUserBookmarksContracts";

import guestArticlesReducer from "./guestAritcleReducer";

import searchResultsHomeReducer from "./searchResultsHome";

import articleReviewsReducer from "./articleReviews";

import articleForSlider from "./articleForSliderReducer";

import orderStatistic from "./orderStatisticReducer";

import chat from "./chat";

import notificaionListReducer from "./notificaionItems";

import purchasedContracts from "./purchasedContracts";

import currencyReducer from "./currencyReducer";

import allLawfirms from "./allLawfirms";

import filteredLawfirms from "./filteredLawfirms";

import topLawfirms from "./topLawfirms";

import lawfirmsById from "./lawfirmsById";

import packagesById from "./packagesById";

import packageItems from "./packageItems";

import packageReviews from "./packageReviews";

import packages from "./packages";

import purchasedPackages from "./purchasedPackages";

import lawyerInteractions from "./lawyerInteractions";

import bookmarkedPackages from "./bookmarkedPackages";

import bookmarkedLawfirms from "./bookmarkedLawfirms";

import lawyerReviews from "./lawyerReviews";

import packageComments from "./packageComments";

import lawyerPackages from "./lawyerPackages";

import lawfirmPackages from "./lawfirmPackages";

const appReducer = combineReducers({
  locale,
  currentUser: currentUser,
  topRatedLawyers: topRatedLawyersReducer,
  lawyersRecommended: lawyerRecommendedReducer,
  allContracts: allContractsReducer,
  contract: contractReducer,
  topRatedContracts: topRatedContractsReducer,
  contractsWithFilters: contractsWithFiltersReducer,
  lawyersByRegion: lawyersByRegionReducer,
  lawyersByFilter: lawyersByFilterReducer,
  allLawyers: allLawyersReducer,
  lawyersForMap: lawyersForMapReducer,
  countries: countriesReducer,
  interests: interestsReducer,
  lawyerById: lawyerByIdReducer,
  lawyersContracts: lawyersContractsReducer,
  lawyersArticles: lawyersArticlesReducer,
  lawyerReviews: lawyerReviews,
  articles: articleReducer,
  currentUserContracts: currentUserContractsReducer,
  currentUserBookmarksLawyers: currentUserBookmarksLawyersReducer,
  currentUserBookmarksArticles: currentUserBookmarksArticlesReducer,
  currentUserBookmarksContracts: currentUserBookmarksContractsReducer,
  guestArticles: guestArticlesReducer,
  searchResultsHome: searchResultsHomeReducer,
  articleReviews: articleReviewsReducer,
  articleForSlider: articleForSlider,
  orderStatistic: orderStatistic,
  chat: chat,
  purchasedContracts: purchasedContracts,
  notificaionList: notificaionListReducer,
  currencies: currencyReducer,
  lawfirms: allLawfirms,
  filteredLawfirms: filteredLawfirms,
  topLawfirms: topLawfirms,
  lawfirmsById: lawfirmsById,
  packagesById: packagesById,
  packageItems: packageItems,
  packageReviews: packageReviews,
  packageComments: packageComments,
  packages: packages,
  purchasedPackages: purchasedPackages,
  lawyerPackages: lawyerPackages,
  lawfirmPackages: lawfirmPackages,
  lawyerInteractions: lawyerInteractions,
  bookmarkedPackages: bookmarkedPackages,
  bookmarkedLawfirms: bookmarkedLawfirms,
});

export default appReducer;
