import { loadStripe } from "@stripe/stripe-js";
import { isDev } from "../constants/dev";

const key = isDev
  ? "pk_test_51GsrJ1Cp5OMD8EAvr4RqKOIMtLHj8EBlARbEr24Lo3pCnQclRGszGZoTRuBGRCpDKamnA9kqmiXJRmQf5XKo5t4700EWNHjgZw"
  : "pk_live_51GsrJ1Cp5OMD8EAvEvtHlJstOiHw9MOKaeAX1YxklqtUbQirydHNvVHmOEk7JmedW1rFRk4SEDs9J0Tajpb5PJ0u00PzhvT6W9";

const stripePromise = loadStripe(key);

export const confirmPaymentByStripe = async intent => {
  const stripe = await stripePromise;

  let result = await stripe.confirmCardPayment(intent);

  if (result.error) throw result.error;

  return result;
};
