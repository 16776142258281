import {
    LAWYER_REVIEWS
  } from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case LAWYER_REVIEWS:
      return action.payload
    default:
      return state
  }
}
