import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/index';

import { useCurrentUser } from './hooks/useCurrentUser';
import { useEcho } from './hooks/useEcho';
import { useNotifications } from './hooks/useNotifications';

const App = () => {
  const echoLoading = useEcho();
  const isTokenFound = useNotifications();

  useCurrentUser();

  if (echoLoading) return null;

  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route path='/' component={Home} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
