import { isDev } from "./dev";

export const BASE_DEV = "dev-api.wal.world"
export const BASE_APP = "wal.apper.ch"

export const WEB_URL = `https://${BASE_DEV}/api`
export const IMAGE_BASE = `https://${BASE_DEV}/uploads`

export const TOP_RATED_LAWYERS = "TOP_RATED_LAWYERS";
export const ALL_CONTRACTS = "ALL_CONTRACTS";
export const TOP_RATED_CONTRACTS = "TOP_RATED_CONTRACTS";
export const CONTRACTS_WITH_FILTERS = "CONTRACTS_WITH_FILTERS";
export const CONTRACT = "CONTRACT";
export const LAWYERS_BY_REGION = "LAWYERS_BY_REGION";
export const LAWYERS_BY_FILTER = "LAWYERS_BY_FILTER";
export const LAWYERS = "LAWYERS";
export const LAWYER_PACKAGES = "LAWYER_PACKAGES";
export const LAWFIRMS = "LAWFIRMS";
export const LAWFIRMS_BOOKMARKED = "LAWFIRMS_BOOKMARKED";
export const LAWYER_REVIEWS = "LAWYER_REVIEWS";
export const LAWFIRM_PACKAGES = "LAWFIRM_PACKAGES";
export const TOP_LAWFIRMS = "TOP_LAWFIRMS";
export const LAWFIRMS_BY_ID = "LAWFIRMS_BY_ID";
export const PACKAGES = "PACKAGES";
export const PACKAGES_BY_ID = "PACKAGES_BY_ID";
export const PACKAGE_REVIEWS = "PACKAGE_REVIEWS";
export const PACKAGE_ITEMS = "PACKAGE_ITEM";
export const PACKAGE_COMMENTS = "PACKAGE_COMMENTS";
export const PACKAGES_PURCHASED = "PACKAGES_PURCHASED";
export const PACKAGES_BOOKMARKED = "PACKAGES_BOOKMARKED";
export const FILTERED_LAWFIRMS = "FILTERED_LAWFIRMS";
export const LAWYERS_FOR_MAP = "LAWYERS_FOR_MAP";
export const CURRENT_USER = "CURRENT_USER";
export const LOG_OUT = "LOG_OUT";
export const COUNTRY_ITEMS = "COUNTRY_ITEMS";
export const INTEREST_ITEMS = "INTEREST_ITEMS";
// export const GOOGLE_CLIENT_ID = '207643332324-srtcsdpft2sied6ktpftt32p7nqt4eem.apps.googleusercontent.com'
export const GOOGLE_CLIENT_ID =
  "635992794046-d92nmtue7j0gj2hacsl791ff7t1446lj.apps.googleusercontent.com";
export const FACEBOOK_CLIENT_ID = "555261932254248";
export const LAWYER_BY_ID = "LAWYER_BY_ID";
export const FETCH_LAWYER_CONTRACTS = "FETCH_LAWYER_CONTRACTS";
export const FETCH_LAWYER_ARTICLES = "FETCH_LAWYER_ARTICLES";
export const FETCH_LAWYER_RECOMMENDED = "FETCH_LAWYER_RECOMMENDED";
export const ARTICLES = "ARTICLES";
export const CONTRACTS_PER_PAGE = "CONTRACTS_PER_PAGE";
export const CURRENT_USER_BOOKMARKS = "CURRENT_USER_BOOKMARKS";
export const CURRENT_USER_CONTRACTS = "CURRENT_USER_CONTRACTS";
export const CURRENT_USER_BOOKMARKS_LAWYERS = "CURRENT_USER_BOOKMARKS_LAWYERS";
export const CURRENT_USER_BOOKMARKS_ARTICLES =
  "CURRENT_USER_BOOKMARKS_ARTICLES";
export const CURRENT_USER_BOOKMARKS_CONTRACTS =
  "CURRENT_USER_BOOKMARKS_CONTRACTS";
export const USER_LAWYER_INTERACTIONS = "USER_LAWYER_INTERACTIONS";
export const EMPTY_STATE = "EMPTY_STATE";
export const GUEST_ARTICLES = "GUEST_ARTICLES";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const GET_ARTICLE_REVIEWS = "GET_ARTICLE_REVIEWS";
export const ARTICLES_FOR_SLIDER = "ARTICLES_FOR_SLIDER";
export const ORDER_STATISTIC = "ORDER_STATISTIC";
export const NOTIFICAIONS_LIST = "NOTIFICAIONS_LIST";

export const PURCHASED_CONTRACTS = "PURCHASED_CONTRACTS";
export const PURCHASED_CONTRACTS_LOADING = "PURCHASED_CONTRACTS_LOADING";
export const CURRENCY = "CURRENCY";
