import React, { useEffect } from "react";

import DisplayRating from "../DisplayRating";
import Image from "../Image";
import { Link } from "react-router-dom";

const TopLawfirms = ({ lawfirms, translate }) => {
  useEffect(() => window.slider(), []);

  const displayTopLawfirms = () => {
    const mappedTopLawfirms = lawfirms.map(lawfirm => {
      return (
        <div key={lawfirm.id} className='rated-lawfirms-item'>
          <div className='rated-lawfirms-img'>
            <Image src={lawfirm.logo} alt='lawfirm' />
          </div>
          <Link to={"lawfirms/" + lawfirm.id}>
            <div className='rated-lawfirms-content'>
              <h1>{lawfirm.name}</h1>
              <DisplayRating stars={[0, 1, 2, 3, 4]} currentRate={lawfirm.rating} />
            </div>
          </Link>
        </div>
      );
    });
    return mappedTopLawfirms;
  };
  return (
    <>
      <div className='rated-lawfirms-wrap'>{displayTopLawfirms()}</div>
      <div className='rated-lawyers-controls'>
        <button className='rated-button-prev'>
          <i className='icon-back'></i>
        </button>
        <button className='rated-button-next'>
          <i className='icon-next'></i>
        </button>
      </div>
    </>
  );
};

export default TopLawfirms;
