import { useEffect, useState } from 'react';
import { onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';

import { setUserFcmToken } from '../actions/auth';

import { addNewMessageToConversationFromNotificationSuccess } from '../actions/chat';

import { fetchToken, messaging } from '../config/firebase';

export const useNotifications = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [channel, setChannel] = useState(null);

  const channelId = useSelector(s => s.chat.channel);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isTokenFound) {
      fetchToken(setTokenFound, token => dispatch(setUserFcmToken(token)));
      //   if (token) dispatch(setUserFcmToken(token));
    }
  }, [isTokenFound, dispatch]);

  useEffect(() => {
    if (!channel) {
      const channel = new BroadcastChannel('sw-messages');
      setChannel(channel);
      channel.addEventListener('message', event => {
        onNotification(event.data.payload);
      });
    }

    return () => {
      if (channel) channel.removeEventListener('message', null);
    };
  }, [channel]);

  // onMessage(messaging, (payload) => {
  //   onNotification(payload);
  // });

  const onNotification = payload => {
    switch (payload.data.notification_type) {
      case 'chat_notification':
        let message = JSON.parse(payload.data.message);
        if (+channelId !== +message.conversation_id) {
          dispatch(addNewMessageToConversationFromNotificationSuccess(message));
        }
    }
  };

  return isTokenFound;
};
