import React
    from 'react';

import LogoWhite
    from '../../assets/img/logo-white.svg';

import DownloadApp
    from '../../assets/img/download-app.png';

class DownloadAppSection extends React.Component {
  render() {
    let { translate } = this.props
    return(
      <section className="download-app mg-top-260 pd-rl">
        <div className="container-fluid">
          <div className="download-app-wrap">
            <div className="row download-app-reverse">
              <div className="col-md-6 col-lg-5">
                <div className="download-app-img">
                  <img src={DownloadApp} alt="download-app" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 col-lg-7">
                <div className="download-app-content">
                  <img src={LogoWhite} alt="logo" />
                  <h1>{translate('home.download_section.section_title')}</h1>
                  <p>{translate('home.download_section.section_description')}</p>
                  <p>{translate('home.download_section.section_description2')}</p>
                </div>
                <div className="download-app-buttons">
                  <a href="https://apps.apple.com/us/app/wal-app/id1521842254" target="_blank" className="button button-transparent button-transparent-border"><i className="icon-appstore"></i>{translate('footer.downloads.app_store')}</a>
                  <a href="https://play.google.com/store/apps/details?id=com.walmobile" target="_blank" className="button button-transparent button-transparent-border"><i className="icon-playstore"></i>{translate('footer.downloads.play_store')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DownloadAppSection
