import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function getContractComments(itemId, catchResult) {
  let url = `${WEB_URL}/contracts/${itemId}/comments?sort=-created_at`;

  api.get(url)
    .then((data) => {
      catchResult(data.data);
    })
    .catch((err) => {
    });
}

export async function createContractComments(itemId, message, successCreateMessageResult) {
  let url = `${WEB_URL}/contracts/${itemId}/comments`;
  let data = {
    "comment": message
  }

  api.post(url, qs.stringify(data))
    .then((data) => {
      successCreateMessageResult();
    })
    .catch((err) => {
    });
}

export async function deleteContractComment(itemId, messageId, successDeleteMessageResult) {
  let url = `${WEB_URL}/contracts/${itemId}/comments/${messageId}`;

  api.delete(url)
    .then((data) => {
      successDeleteMessageResult();
    })
    .catch((err) => {
    });
}

export async function reviewContract(contract_id, state, successHandler) {
  let data = {
      "quality": parseInt(state.quality),
      "professionality": parseInt(state.professionality),
      "usability": parseInt(state.usability),
      "understandability": parseInt(state.understandability)
  }

  let url = `${WEB_URL}/contracts/${contract_id}/reviews`;
  api.post(url, qs.stringify(data))
    .then((data) => {
      successHandler();
    })
    .catch((err) => {
    });
}
