import React from "react";
import moment
  from 'moment';

class OrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
    };
  }

  displayTitle() {
    let { item } = this.props
    let { entity_type } = item
    if(entity_type == "contract") {
      return item.entity?.contract.name
    } else if(entity_type == "chat") {
      return `Chating with ${item.owner_item_user.name}`
    } else if(entity_type == "call") {
      return `Call with ${item.owner_item_user.name}`
    }
  }

  render() {
    let { item } = this.props
    return (
      <div className="settings-history-item">
        <div className="settings-history-item-left">
          <div className="settings-history-item-icon">
            <i className={`icon-${item.entity_type == "contract" ? "contracts" : item.entity_type}`}></i>
          </div>
          <div className="settings-history-item-content">
            <h3>{this.displayTitle()}</h3>
            <div className="settings-history-item-info">
              <span>{moment(item.created_at).format("DD.MM.YYYY")}</span>
              <span>{moment(item.created_at).format('LT')}</span>
            </div>
          </div>
        </div>
        <div className="settings-history-item-right">
          <div className="settings-history-item-price">
            <span>{parseInt(item.amount)}.- {item.currency}</span>
          </div>
          <div className="settings-history-item-action">
            <a href="#" className="wal-icons-button" data-toggle="modal" data-target="#settings-order-contracts"><i className="icon-eye"></i></a>
            {/*<a href="#" className="wal-icons-button delete"><i className="icon-delete"></i></a>*/}
          </div>
        </div>
      </div>
    )
  }
}

export default OrderCard;
