import React from "react";

import RightContentSide from "./RightContentSide";

import ContractContentComment from "./ContractContentComment";

import DisplayRating from "../../../components/shared/DisplayRating";

import ReactHtmlParser from "react-html-parser";

import { getToken } from "../../../helpers";

import ReviewItemModal from "../../shared/modals/ReviewItemModal";

import { reviewContract } from "../../../helpers/getComments";

import { toast } from "react-toastify";

class ContentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      professionality: props.contract.review.professionality,
      quality: props.contract.review.quality,
      usability: props.contract.review.usability,
      understandability: props.contract.review.understandability,
    };
  }
  postReview(reviewData) {
    const {translate} = this.props
    window.$("#review-modal").modal("toggle");
    reviewContract(this.props.contract.id, reviewData, () => toast.success(translate("reviews.review_success")));
  }
  render() {
    let { contract, translate } = this.props;
    const isLoggedIn = getToken();
    return (
      <section className='profile-content mg-top-40 pd-rl'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-8'>
              <div className='profile-article'>
                <h3>{translate("contracts.details.description")}</h3>
                <p>{ReactHtmlParser(contract.description)}</p>
              </div>
              <div className='main-title'>
                <h2>{translate("contracts.details.reviews")}</h2>
                {isLoggedIn && contract.is_downloadable && (
                  <a
                    onClick={() => window.$("#review-modal").modal("toggle")}
                    style={{ cursor: "pointer" }}
                  >
                    {contract.auth_user_has_review_on_contract
                      ? translate("reviews.update_review")
                      : translate("reviews.review_contract")}
                  </a>
                )}
              </div>
              <div className='profile-reviews'>
                <div className='profile-reviews-item'>
                  <span>{translate("contracts.details.quality")}</span>
                  <DisplayRating
                    stars={[0, 1, 2, 3, 4]}
                    currentRate={this.state.quality}
                  />
                </div>
                <div className='profile-reviews-item'>
                  <span>{translate("contracts.details.professionality")}</span>
                  <DisplayRating
                    stars={[0, 1, 2, 3, 4]}
                    currentRate={this.state.professionality}
                  />
                </div>
                <div className='profile-reviews-item'>
                  <span>{translate("contracts.details.usability")}</span>
                  <DisplayRating
                    stars={[0, 1, 2, 3, 4]}
                    currentRate={this.state.usability}
                  />
                </div>
                <div className='profile-reviews-item'>
                  <span>{translate("contracts.details.understandability")}</span>
                  <DisplayRating
                    stars={[0, 1, 2, 3, 4]}
                    currentRate={this.state.understandability}
                  />
                </div>
              </div>
              <ContractContentComment
                contractId={contract.id}
                translate={translate}
                isPurchased={contract.is_downloadable}
                reviewState={this.state}
              />
            </div>
            <RightContentSide
              lawyer={contract.lawyer}
              company={contract.company}
              translate={translate}
            />
            <ReviewItemModal
              translate={translate}
              postReview={reviewData => this.postReview(reviewData)}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default ContentSection;
