export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_MESSAGES = "GET_MESSAGES";
export const MORE_MESSAGES = "MORE_MESSAGES";
export const HAS_MORE_MESSAGES = "HAS_MORE_MESSAGES";
export const CONVERSATION_LOADING = "CONVERSATION_LOADING";
export const MESSAGES_LOADING = "MESSAGES_LOADING";
export const SELECT_CONVERSATION = "SELECT_CONVERSATION";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const GET_CONVERSATION = "GET_CONVERSATION";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";

export const ADD_NEW_MESSAGE_TO_CONVERSATION_FROM_NOTIFICATION_SUCCESS =
  "ADD_NEW_MESSAGE_TO_CONVERSATION_FROM_NOTIFICATION_SUCCESS";
export const ADD_NEW_MESSAGE_TO_CONVERSATION_SUCCESS =
  "ADD_NEW_MESSAGE_TO_CONVERSATION_SUCCESS";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";

export const SET_CHANNEL_ID = "SET_CHANNEL_ID";
// WS TYPES
export const WS_ADD_MESSAGE = "WS_ADD_MESSAGE";
export const WS_TYPING = "WS_TYPING";
export const WS_CONVERSATION_UPDATE = "WS_CONVERSATION_UPDATE";
export const WS_CONVERSATION_DELETE = "WS_CONVERSATION_DELETE";
export const WS_CONVERSATION_CREATED = "WS_CONVERSATION_CREATED";
export const WS_DELETE_MESSAGE = "WS_DELETE_MESSAGE";

// Modals
export const MESSAGE_DELETE_MODAL_OPEN = "MESSAGE_DELETE_MODAL_OPEN";
export const MESSAGE_DELETE_MODAL_CLOSE = "MESSAGE_DELETE_MODAL_CLOSE";
export const CONTRACTS_MODAL_OPEN = "CONTRACTS_MODAL_OPEN";
export const CONTRACTS_MODAL_CLOSE = "CONTRACTS_MODAL_CLOSE";
export const CONVERSATION_DELETE_MODAL_OPEN = "CONVERSATION_DELETE_MODAL_OPEN";
export const CONVERSATION_DELETE_MODAL_CLOSE =
  "CONVERSATION_DELETE_MODAL_CLOSE";

export const MESSAGE_REPORT_MODAL_OPEN = "MESSAGE_REPORT_MODAL_OPEN";
export const MESSAGE_REPORT_MODAL_CLOSE = "MESSAGE_REPORT_MODAL_CLOSE";
export const MESSAGE_REPORT_MODAL_REASONS = "MESSAGE_REPORT_MODAL_REASONS";
