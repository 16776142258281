import api from "../api";

import {
  WEB_URL,
  ARTICLES,
  GUEST_ARTICLES,
  ARTICLES_FOR_SLIDER
} from "../constants/types";

import { getLanguageAsNumber } from "../services/storeLanguage";

export function fetchArticles(stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-articles?sort=-created_at&language_id=${getLanguageAsNumber}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: ARTICLES,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function fetchArticlesForSlider(stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-articles?articles_of_the_week=1&per_page=8&page=1&language_id=${getLanguageAsNumber}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: ARTICLES_FOR_SLIDER,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function fetchArticlesByFilter(params=null, stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-articles?sort=-created_at&language_id=${getLanguageAsNumber}&`;

    if(params) {
      if(params.page && params.page !== "" && params.page !== null) { url = url + `page=${params.page}&` }
      if(params.rating && params.rating !== "" && params.rating !== null ) { url = url + `rating=${params.rating}&` }
      if(params.title && params.title !== "") { url = url + `title=${params.title}&` }
      if(params.sort && params.sort !== "" && params.sort !== null) { url = url + `sort=${params.sort}&` }
      if(params.company_id && params.company_id !== "" && params.company_id !== null) { url += `company_id=${params.company_id}&` }
      if (params.perPage && params.perPage !== "" && params.perPage !== null) {
        url += `per_page=${params.perPage}&`;
      }
      if(params.categories && params.categories.length > 0) {
        var categoryLink = ""
        params.categories.map((cat, index) => {
          return categoryLink = categoryLink + `categories[${index}]=${cat}&`
        })
        url = url + categoryLink
      }
    }

    api.get(url)
      .then((data) => {
        dispatch({
          type: GUEST_ARTICLES,
          payload: data.data,
        });
        stopLoading && stopLoading(url, 'articles')
      })
      .catch((err) => {});
  };
}
