import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDB2kBGYNujV55Qf8j8aIXNVwGKaou6oU4',
  authDomain: 'wal-world.firebaseapp.com',
  projectId: 'wal-world',
  storageBucket: 'wal-world.appspot.com',
  messagingSenderId: '583585180951',
  appId: '1:583585180951:web:8d73381c0140c7fbc71b52',
  measurementId: 'G-L9DDXLFEXM',
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const fetchToken = async (setTokenFound, save) => {
  return getToken(messaging, {
    vapidKey: 'BLuPoSzSZ0BndU3eGyjLJkj1lu9eVahlUN5uoHxYIL6PHAEFBT7CFuGak8PxBQ2kkgrakXQIZtjtyfiX0JAi7bA',
  })
    .then(currentToken => {
      if (currentToken) {
        setTokenFound(true);
        save(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      console.log('onMessage', { payload });
      resolve(payload);
    });
  });
