import React from "react";

import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import Image from "../shared/Image";

import { IMAGE_BASE } from "../../constants/types";

import DisplayRating from "../shared/DisplayRating";

import {reviewArticle} from '../../helpers/articleReview'

import { bookmarkItem, deleteBookmarkItem } from '../../helpers/bookmarkItem'

import ReactLoading from 'react-loading';

import { Modal } from "../shared/modals/payment";

// import { getToken } from "../../helpers";

import ContractsSearched from "../contracts/ContractsSearched";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

import ReactHtmlParser
  from 'react-html-parser'


const TYPE_OF_REVIEWS = [{title: "Quality", value: "quality"},
                         {title: "Professionality", value: "professionality"},
                         {title: "Experience", value: "experience"},
                         {title: "Helpful", value: "helpful"}]

class ArticleLogged extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality:1,
      professionality:1,
      experience:1,
      helpful:1,
      comment:'',
      currentPage:1,
      selectedContract: null
    };

    this.inputShareRef = React.createRef();
  }

  applyReview(){
    if(this.state.comment === ''){
      alert("Review cannot be blank!")
    } else {
      this.setState({comment:'', quality:1, professionality:1, experience:1, helpful:1})
      let id = this.props.article.id
      reviewArticle(this.state, id)
      this.props.reloadArticleAfterReview()
    }
  }
  
  bookmarkArticle(){
    let {is_bookmarked, id} = this.props.article
    is_bookmarked ? deleteBookmarkItem(id, "article", this.catchResult.bind(this))
                  : bookmarkItem(id, "article", this.catchResult.bind(this))

  }

  catchResult(){
    this.props.bookmarkItem()
  }

  copyToClipboard(e) {
    e.preventDefault();
    this.inputShareRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  DisplayRatingTypes() {
    return (
      TYPE_OF_REVIEWS.map((review, index) => {
        let { title, value } = review
        return (
          <div className="profile-reviews-item" key={index}>
            <span>{review.title}</span>
            <DisplayRating
              stars={[1,2,3,4,5]}
              changeMode
              stateType={this.state[`${value}`]}
              changeStateType={(index) => this.setState({[`${value}`]: index})}
            />
          </div>
        )
      })
    )
  }

  displayCommentButton() {
    let { translate } = this.props
    if(this.props.reviewsLoading) {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading height={40} width={40} type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      return (
        <button onClick={()=> this.applyReview()}
        className="button button-primary"><i className="icon-send"></i>{translate('articles.details.send_review')}</button>
      )
    }
  }

  displayComments() {
    let { translate } = this.props
    if(this.props.reviews.length === 0) {
      return (
        <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p>{translate('articles.details.not_found_review')}</p>
        </div>
      )
    }

    if(this.props.reviewsLoading) {
      return (
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      return (
        <div className="comments">
            <div className="comments-body">
              {this.props.reviews.map((item, index)=>{
                return (
                  <div className="comments-item" key={index}>
                    <div className="comments-info">
                      <div className="comments-img">
                        <Image src={item.user && item.user.avatar} alt="header" />
                      </div>
                      <div className="comments-info-wrap">
                        <h1>{item.user && item.user.name}</h1>
                        <div className="comments-info-bottom">
                          <div className="profile-rating">
                            <DisplayRating
                              stars={[0,1,2,3,4]}
                              currentRate={item.overall_rating}
                            />
                            <span>{item.overall_rating}</span>
                          </div>
                          <span>{item.created_at.slice(0,10)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="comments-content">
                      <p>{item.comment || "No comment available."}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            {this.props.currentReviewsPage < this.props.lastReviewsPage &&
              <a onClick={this.props.moreReviews}
              className="more-button button button-gray">Load more reviews</a>
            }
        </div>
      )
    }
  }

  render() {
    let article = this.props.article
    let { translate } = this.props

    return (
      <main className="main">
        <title>{article.title} | WAL</title>
        <section className="profile pd-rl"
            style={{ backgroundImage: `url(${IMAGE_BASE + article.image})` }}
            >
          <div className="container-fluid">
              <div className="profile-wrap">
                <div className="profile-top">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="profile-top-wrap">
                        <a onClick={this.props.goBack} style={{color:'#ffffff', cursor: 'pointer'}}
                            className="back-button">
                            <i className="icon-back"></i>{translate('articles.details.back')}</a>
                        { <a onClick={()=> this.bookmarkArticle()} className="bookmarks">
                            <i className={article.is_bookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}></i></a> }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-bottom">
                  <div className="row align-items-end">
                    <div className="col-md-12 col-lg-8">
                       <div className="profile-info">
                         <div className="profile-info-wrap">
                           {article.categories.length > 0 &&
                             <div className="profile-type articles-type">
                               <span className="type">{article.categories[0].lang.name}</span>
                                {article.categories.length > 1 &&
                                  <div className="more-list">
                                     <span>+{article.categories.slice(1, article.categories.length).length}
                                     more</span>
                                     <ul>
                                       {article.categories.slice(1, article.categories.length).map((item, index)=>{
                                          return <li key={index}>- {item.lang.name}</li>
                                       })}
                                     </ul>
                                   </div>
                                 }
                             </div>
                           }
                           <div className="profile-name">
                             <h1>{article.title}</h1>
                           </div>
                           <div className="profile-rating-state">
                             <div className="profile-rating">
                               <DisplayRating
                                 stars={[0,1,2,3,4]}
                                 currentRate={article.rating}
                               />
                               <span>{article.rating}</span>
                             </div>
                             <div className="profile-type">
                               <span><i className="icon-eye"></i>{article.viewed} {translate('articles.details.views')}</span>
                             </div>
                             <div className="profile-type">
                               <span><i className="icon-chat"></i>{this.props.totalReviewsNumber} {translate('articles.details.comments')}</span>
                             </div>
                           </div>
                           {article.hashtags &&
                             <div className="articles-tags">
                               <ul>
                                {article.hashtags.map((item, index)=>{
                                    return <li key={index}><a href="#">{item.tag}</a></li>
                                })}
                               </ul>
                             </div>
                           }
                       </div>
                     </div>
                   </div>
                   <div className="col-md-6 col-lg-4">
                    <div className="profile-buttons">
                      <div className="wal-icons">
                        <div className="dropdown-options contracts-options-dropdown">
                          <div data-toggle="dropdown" data-display="static">
                            <a href="#" className="wal-icons-button options"><i className="icon-share"></i></a>
                          </div>
                          <ul className="dropdown-menu">
                            <div className="more-options">
                              <span>{translate('articles.details.share_this_article')}</span>
                            </div>
                            <li>
                              <a href="#"><i className="icon-email"></i>{translate('articles.details.mail')}</a>
                            </li>
                            <li>
                              <LinkedinShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-linkedin"></i>{translate('articles.details.linkedin')}</a>
                              </LinkedinShareButton>
                            </li>
                            <li>
                              <TwitterShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-twitter"></i>{translate('articles.details.twitter')}</a>
                              </TwitterShareButton>
                            </li>
                            <li>
                              <FacebookShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-facebook-alt"></i>{translate('articles.details.facebook')}</a>
                              </FacebookShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="profile-content mg-top-40 pd-rl">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-8">
                <div className="profile-article articles-content">
                  <p>{ReactHtmlParser(article.description)}</p>
                </div>
                <div className="profile-article articles-content">
                  {ReactHtmlParser(article.content)}
                </div>
                {article.contracts.length > 0 &&
                  <> 
                    <div className="main-title">
                      <h2>{translate('articles.details.linked_contracts')}</h2>                  
                    </div>
                    <div className="profile-contracts articles-contracts">
                    <ContractsSearched items={article.contracts} selectedContract={(item) => this.setState({selectedContract: item})}/>
                    </div>
                  </>
                }
                <div className="main-title">
                  <h2>{translate('articles.details.rate_it')}</h2>
                </div>
                <div className="profile-reviews-wrap">
                  <div className="profile-reviews-top">
                    <div className="profile-reviews">
                      {this.DisplayRatingTypes()}
                    </div>
                    <div className="profile-reviews-input">
                      <div className="comments-img">
                      <Image src={this.props.current_user.avatar} alt="header" />
                      </div>
                      <div className="comments-input">
                        <textarea name="comment"
                        value={this.state.comment}
                        onChange={(value)=> this.setState({comment:value.target.value})}
                        className="wal-form-control"
                        placeholder={translate('articles.details.write_a_review')}></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="comments-send">
                    {this.displayCommentButton()}
                  </div>
                </div>
                <div className="main-title">
                  <h2>{translate('articles.details.reviews')}</h2>
                </div>
                {this.displayComments()}
              </div>
              <div className="col-md-12 col-lg-12 col-xl-4">
                <div className="profile-aside">
                 <div className="profile-aside-title">
                   <h3>{translate('articles.details.created_by')}</h3>
                 </div>
                 <div className="profile-create-item">
                   <div className="profile-create-right">
                     <div className="lawyers-img">
                      <Image src={article.lawyer.avatar && article.lawyer.avatar} alt="header" />
                      <div className={article.lawyer.is_online ? "status active" : "status inactive"}></div>
                     </div>
                     <div className="profile-create-content">
                       <h1>{article.lawyer.name}</h1>
                       <p>{translate('articles.details.lawyer')}</p>
                     </div>
                   </div>
                   <div className="profile-create-left">
                   <Link to={`/lawyers/${article.lawyer.id}`}><i className="icon-next"></i></Link>
                   </div>
                 </div>
                   <div className="profile-aside-date">
                     <h3>{translate('articles.details.published_on')}</h3>
                     <p>{article.created_at.slice(0,10)}</p>
                   </div>
                   <div className="profile-aside-copy">
                    <div className="copy-link">
                      <input type="text" className="wal-form-control" ref={this.inputShareRef} value={`${window.location.href}`} readOnly />
                      <a className="copy" onClick={this.copyToClipboard.bind(this)}><i className="icon-copy"></i></a>
                    </div>
                 </div>
               </div>
              </div>
            </div>
          </div>
        </section>
        <Modal item={this.state.selectedContract} />
      </main>
  )};
}

function mapStateToProps(state){
    return ({
                current_user: state.currentUser,
            })
    }

export default connect(mapStateToProps, {})(ArticleLogged);
