import React, {useState} from "react";

import ContractsSearched from "../../components/contracts/ContractsSearched";
import { Modal } from "../shared/modals/payment";

const ContractsSection = (props) => {
  const [selectedContract, setSelectedContract] = useState(null);
  return (
    <div className="tab-pane" id="contracts">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 className="panel-title nav-list">
            <a
              data-toggle="collapse"
              data-target="#collapse3"
              aria-expanded="false"
              data-parent="#tabs"
            >
              {props.translate("profile.contracts")}
            </a>
          </h4>
        </div>
        <div
          id="collapse3"
          className="panel-collapse collapse"
          data-parent="#tabs"
        >
          <div className="panel-body nav-content">
            {props.contracts.length > 0 ? (
              <>
                <ContractsSearched items={props.contracts} selectedContract={item => setSelectedContract(item)}/>
                {props.userContractsLastPage > props.contractsPage && (
                  <a
                    onClick={props.userMoreContracts}
                    className="more-button button button-gray"
                  >
                    {props.translate("profile.load_more_contracts")}
                  </a>
                )}
              </>
            ) : (
              <div
                id="collapse3"
                className="panel-collapse collapse"
                data-parent="#tabs"
              >
                <div className="panel-body nav-content">
                  <div className="wal-result-none">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-lg-6">
                        <div className="wal-result-none-wrap">
                          <div className="wal-result-none-icon">
                            <i className="icon-contracts"></i>
                          </div>
                          <h1>
                            {props.translate(
                              "profile.have_no_contracts_purchased"
                            )}
                          </h1>
                          <p>{props.translate("profile.purchased_info")}</p>
                          <a href="/contracts" className="button button-white">
                            {props.translate(
                              "profile.go_to_contract_and_templates"
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal item={selectedContract} />
    </div>
  );
};

export default ContractsSection;
