import { WEB_URL, CURRENT_USER, LOG_OUT } from "../constants/types";
import { toast } from "react-toastify";
import { SET_USER_FCM_TOKEN_SUCCESS } from "../constants/index";

import api from "../api";

import qs from "qs";

import { setToken, removeToken, setUser } from "../helpers";

export function login(email, password, stopLoading, handleErrors) {
  return async function (dispatch) {
    let data = {
      email: email,
      password: password,
    };

    let url = `${WEB_URL}/login`;
    api
      .post(url, qs.stringify(data))
      .then((data) => {
        let roleId = data.data.data.user.user_role_id;
        if (roleId === 1 || roleId === 2 || roleId === 3) {
          window.location.replace("https://app.wal.world/login");
        } else {
          setToken(data.data.data.token);
          setUser(data.data.data.user);
          dispatch({
            type: CURRENT_USER,
            payload: data.data.data.user,
          });
          stopLoading();
          window.location.reload();
        }
      })
      .catch((err) => {
        stopLoading();
        handleErrors(err.data);
      });
  };
}

export function signUp(registerParams, stopLoading, handleErrors) {
  return async function (dispatch) {
    let url = `${WEB_URL}/user/register`;
    api
      .post(url, registerParams)
      .then((data) => {
        setToken(data.data.data.token);
        setUser(data.data.data.user);
        dispatch({
          type: CURRENT_USER,
          payload: data.data.data.user,
        });
        stopLoading();
        window.location.reload();
      })
      .catch((err) => {
        stopLoading();
        handleErrors(err.data);
      });
  };
}

export function facebookLogin(fbToken, stopLoading) {
  return async function (dispatch) {
    let data = {
      access_token: fbToken,
    };

    let url = `${WEB_URL}/login/facebook`;
    api
      .post(url, qs.stringify(data))
      .then((data) => {
        setToken(data.data.data.token);
        setUser(data.data.data.user);
        dispatch({
          type: CURRENT_USER,
          payload: data.data.data.user,
        });
        stopLoading();
        window.location.reload();
      })
      .catch((err) => {
        stopLoading();
        // console.log(err.data)
      });
  };
}

export function googleLogin(googleToken, stopLoading) {
  return async function (dispatch) {
    let data = {
      access_token: googleToken,
    };
    let url = `${WEB_URL}/login/google`;
    api
      .post(url, qs.stringify(data))
      .then((data) => {
        setToken(data.data.data.token);
        setUser(data.data.data.user);
        dispatch({
          type: CURRENT_USER,
          payload: data.data.data.user,
        });
        stopLoading();
        window.location.reload();
      })
      .catch((err) => {
        stopLoading();
      });
  };
}

export function requestResetPassword(
  email,
  stopLoading,
  handleSuccess,
  handleErrors
) {
  return async function (dispatch) {
    let data = {
      email: email,
    };

    let url = `${WEB_URL}/password/email`;
    api
      .post(url, qs.stringify(data))
      .then((data) => {
        stopLoading();
        handleSuccess(data);
      })
      .catch((err) => {
        stopLoading();
        handleErrors(err.data);
      });
  };
}

export function loadProfile(stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/profile`;
    api.get(url).then((data) => {
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data,
      });
      stopLoading && stopLoading();
    });
  };
}

export function logout() {
  return async function (dispatch) {
    let url = `${WEB_URL}/logout`;
    api.post(url).then((data) => {
      removeToken();
      dispatch({
        type: LOG_OUT,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    });
  };
}

export function dataDeletionConfirmation(uniqueCode, stopLoading, showError) {
  let url = `${WEB_URL}/social-network-deletion-request?unique_code=${uniqueCode}`;
  api
    .get(url)
    .then((res) => {
      stopLoading();
    })
    .catch(() => showError());
}

export const setUserFcmToken = (token) => (dispatch) => {
  try {
    let formData = new FormData();
    formData.append("type", "web");
    formData.append("fcm_token", token);
    api.post(`${WEB_URL}/devices`, formData);
    // yield call(api.post, `devices`, formData);

    // yield put(userActions.setUserFcmTokenSuccess(action.payload.token));
    dispatch({ type: SET_USER_FCM_TOKEN_SUCCESS, payload: token });
  } catch (error) {
    toast.error(error.data.error, { autoClose: 10000 });
  }
};
