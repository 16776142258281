import { PACKAGE_ITEMS } from "../constants/types";

export default function (state = [], action) {
  switch (action.type) {
    case PACKAGE_ITEMS:
      return action.payload;
    default:
      return state;
  }
}
