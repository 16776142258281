
import React
  from "react";

import { CardForm }
  from './';

class EmptyCards extends React.Component {
  constructor(props) {
    super(props);
    this.props = {
      loading: true
    };
  }

  render() {
    let { formMode, translate } = this.props
    return (
      <div className="settings-payments-wrap">
        <div className="settings-payments-main-title">
          <h2>{translate("shared.cards")}</h2>
        </div>
        <div className="settings-payments-empty">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="wal-result-none-wrap">
                <div className="wal-result-none-icon">
                  <i className="icon-creditcard-cc"></i>
                </div>
                <h1>{translate("shared.no_payment_mehthod")}</h1>
                <p>{translate("shared.no_payment_mehthod_desc")}</p>
                { formMode == "new" &&
                  <div className="wal-cards-add-new">
                    <button onClick={() => this.props.triggerFormMode("create")} className="button button-white">
                      <i className="icon-plus"></i>{translate("settings.payments.add_new_payment_method")}
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        { formMode !== "new"  &&
          <CardForm
            triggerFormMode={this.props.triggerFormMode.bind(this)}
          />
        }
      </div>
    )
  }
};

export default EmptyCards;
