import React, { useState } from "react";
import { Link } from "react-router-dom";

import { bookmarkItem, deleteBookmarkItem } from "../../../helpers/bookmarkItem";

import { WEB_URL } from "../../../constants/types";
import { getToken } from "../../../helpers";
import Image from "../../../components/shared/Image";
import DisplayRating from "../../../components/shared/DisplayRating";
import { getCurrency } from "../../../services/storeCurrency";
import Currencies from "../../../constants/currencies";

const PackageCard = props => {
  const { id, lawyer, title, rating, price, languages, package_items_count, is_purchased } =
    props.item;
  const [isBookmarked, setIsBookmarked] = useState(props.item.is_bookmarked);
  const isLoggedIn = getToken();

  const openPayment = () => {
    props.selectedPackage(props.item);
    if (isLoggedIn) {
      window.$("#payment-modal").modal("toggle");
    } else {
      window.$("#login").modal("toggle");
    }
  };

  const triggerPackageBookmark = () => {
    if (getToken()) {
      isBookmarked
        ? deleteBookmarkItem(id, "package", data => setIsBookmarked(data === 0 ? false : true))
        : bookmarkItem(id, "package", data => setIsBookmarked(data === 0 ? false : true));
    } else {
      window.$("#login").modal("toggle");
    }
  };

  return (
    <div className='contracts-item contracts-item-package'>
      <Link to={`/packages/${id}`} className='contracts-item-wrap'>
        <div className='contracts-left'>
          <div className='contracts-img'>
            <Image src={lawyer.avatar} alt='person' />
          </div>
        </div>
        <div className='contracts-center'>
          <div className='contracts-content'>
            <div className='contracts-icon'>
              <i className='icon-packages'></i>
            </div>
            <div className='contracts-content-wrap'>
              <div className='contracts-title'>
                <h1>{title}</h1>
              </div>
              <div className='contracts-info'>
                <DisplayRating stars={[0, 1, 2, 3, 4]} currentRate={rating} />
                <span>
                  {package_items_count} {props.translate("packages.documents")}
                </span>
                <span>
                  {languages?.map((language, index) => {
                    let comma = index < languages.length - 1 ? ", " : "";
                    return language.lang.name + comma;
                  })}
                </span>
                <span>
                  {price} {Currencies[getCurrency]?.name.toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className='contracts-right'>
        <div className='contracts-bookmark' onClick={() => triggerPackageBookmark()}>
          <a>
            <i className={`${isBookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}`}></i>
          </a>
        </div>
        {is_purchased ? (
          <a
            className='contracts-purchase'
            href={`${WEB_URL}/packages/${id}/download?token=${isLoggedIn}`}
            style={{ cursor: "pointer" }}
          >
            <i className='icon-download'></i>
          </a>
        ) : (
          <a
            style={{ cursor: "pointer" }}
            className='contracts-purchase'
            onClick={() => openPayment()}
          >
            <i className='icon-purchase'></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default PackageCard;
