import {
    PACKAGE_COMMENTS
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case PACKAGE_COMMENTS:
        return action.payload
      default:
        return state
    }
  }
