import React
  from 'react'
import { connect }
  from "react-redux";
import GoogleLogin
  from 'react-google-login';
import { googleLogin }
  from '../../../actions/auth';
import { GOOGLE_CLIENT_ID } from '../../../constants/types';

class GoogleAuthButton extends React.Component {
  displayGoogleButton() {
    let { translate, googleTextButton } = this.props
    return (
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        render={renderProps => (
          <a href={undefined}  onClick={() => renderProps.onClick()}>
           <i className="icon-google-logo"></i>
             {googleTextButton}
          </a>
        )}
        buttonText="Login"
        onSuccess={this.responseGoogleSuccess}
        onFailure={this.responseGoogleError}
        cookiePolicy={'single_host_origin'}
        scope="profile"
      />
    )
  }

  responseGoogleSuccess = (response) => {
    this.props.startLoading()
    this.loginWithGoogle(response.accessToken)
  }

  loginWithGoogle = async (token) =>{
    this.props.googleLogin(token, () => this.props.stopLoading());
  }

  responseGoogleError = (response) => {
    console.log("google error response", response);
  }

  render() {
    return (
      this.displayGoogleButton()
    )
  }
}

export default connect(null, { googleLogin })(GoogleAuthButton);
