import React from "react";

import { connect } from "react-redux";

import { loadProfile } from "../../../actions/auth";

import {
  fetchUserArticlesBookmarks,
  fetchUserContractsBookmarks,
  fetchUserLawyersBookmarks,
  fetchUserContracts,
  getLawyerInteractions,
  getPurchasedPackages,
  emptyRedux,
} from "../../../actions/profile";

import Image from "../../../components/shared/Image";

import { IMAGE_BASE, WEB_URL } from "../../../constants/types";

import Maps from "../../../components/lawyers/Map";

import ReactLoading from "react-loading";

import PurchasesSection from "../../../components/profile/PurchasesSection";

import BookmarksSection from "../../../components/profile/BookmarksSection";

import ContractsSection from "../../../components/profile/ContractsSection";

import PackagesSection from "../../../components/profile/PackagesSection";

import { getTranslate } from "react-localize-redux";

import api from "../../../api";

class CurrentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lawyersPage: 1,
      bookmarkContractsPage: 1,
      contractsPage: 1,
      articlesPerPage: 3,
      purchasesPerPage: 4,
      morePurchasesLoading: false,
      packagesPerPage: 4,
      packagesLoading: false,
      userLawyerStatistics: 0,
    };
  }

  componentDidMount() {
    this.requestData();
    this.userLawyerInteractionStatistics();
  }

  componentWillUnmount() {
    this.props.emptyRedux();
  }

  userLawyerInteractionStatistics() {
    api
      .get(`${WEB_URL}/user-lawyer-interaction-statistics`)
      .then(res =>
        this.setState({ userLawyerStatistics: res.data.data.user_lawyer_interaction_statistics })
      )
      .catch(err => {});
  }

  async requestData() {
    this.props.loadProfile(() => this.stopLoading());
    this.props.fetchUserArticlesBookmarks(this.state.articlesPerPage);
    this.props.fetchUserContractsBookmarks(
      this.lastContractsPage.bind(this),
      this.state.bookmarkContractsPage
    );
    this.props.fetchUserLawyersBookmarks(this.lastLawyerPage.bind(this), this.state.lawyersPage);
    this.props.fetchUserContracts(this.lastUserContractsPage.bind(this), this.state.contractsPage);
    this.props.getLawyerInteractions(this.state.purchasesPerPage, null);
    this.props.getPurchasedPackages(this.state.packagesPerPage, null);
  }

  loadMoreLawyers() {
    this.setState({ lawyersPage: this.state.lawyersPage + 1 }, () => {
      this.props.fetchUserLawyersBookmarks(this.lastLawyerPage.bind(this), this.state.lawyersPage);
    });
  }

  loadMorePurchases() {
    this.props.getLawyerInteractions(this.state.purchasesPerPage + 4, () =>
      this.setState({ morePurchasesLoading: false })
    );
    this.setState(prevState => {
      return { purchasesPerPage: prevState.purchasesPerPage + 4, morePurchasesLoading: true };
    });
  }

  loadMoreArticles() {
    this.setState({ articlesPerPage: this.state.articlesPerPage + 3 }, () => {
      this.props.fetchUserArticlesBookmarks(this.state.articlesPerPage);
    });
  }

  loadMoreUserContracts() {
    this.setState({ contractsPage: this.state.contractsPage + 1 }, () => {
      this.props.fetchUserContracts(this.stopLoading.bind(this), this.state.contractsPage);
    });
  }

  loadMoreContracts() {
    this.setState({ bookmarkContractsPage: this.state.bookmarkContractsPage + 1 }, () => {
      this.props.fetchUserContractsBookmarks(
        this.lastContractsPage.bind(this),
        this.state.bookmarkContractsPage
      );
    });
  }

  loadMorePackages() {
    this.setState(
      { packagesPerPage: this.state.packagesPerPage + 4, packagesLoading: true },
      () => {
        this.props.getPurchasedPackages(this.state.packagesPerPage, () =>
          this.setState({ packagesLoading: false })
        );
      }
    );
  }

  lastContractsPage(data) {
    this.setState({ contractsLastPage: data });
  }

  lastUserContractsPage(data) {
    this.setState({ userContractsLastPage: data });
  }

  lastLawyerPage(data) {
    this.setState({ lawyersLastPage: data });
  }

  lastArticlePage(data) {
    this.setState({ lastArticlePage: data });
  }

  stopLoading() {
    this.setState({ loading: false });
  }
  render() {
    let currentUser = this.props.current_user;
    let { translate } = this.props;
    if (this.state.loading || !currentUser) {
      return (
        <div
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' />
        </div>
      );
    }

    return (
      <div>
        <title>{currentUser && currentUser.name} | WAL</title>
        <main className='main'>
          <section
            className='profile pd-rl'
            style={{
              backgroundImage: `url(${IMAGE_BASE + currentUser.avatar})`,
            }}
          >
            <div className='container-fluid'>
              <div className='profile-wrap'>
                <div className='profile-top'></div>
                <div className='profile-bottom'>
                  <div className='row align-items-end'>
                    <div className='col-md-12 col-lg-8'>
                      <div className='profile-info'>
                        <div className='profile-image'>
                          <Image src={currentUser.avatar} alt='person' />
                        </div>
                        <div className='profile-info-wrap'>
                          <div className='profile-info-top'>
                            <div className='profile-status'>
                              <div
                                className={
                                  currentUser.is_online ? "status active" : "status inactive"
                                }
                              ></div>
                              <span>
                                {currentUser.is_online
                                  ? translate("profile.online")
                                  : translate("profile.offline")}
                              </span>
                            </div>
                          </div>
                          <div className='profile-name'>
                            <h1>{currentUser.name}</h1>
                          </div>
                          <div className='profile-rating-state'>
                            {/* <div className="profile-type">
                              <span>
                                <i className="icon-calendar"></i>14 {translate('profile.appointments')}
                              </span>
                            </div> */}
                            <div className='profile-state'>
                              <i className='icon-contracts'></i>
                              <span>
                                {this.props.contracts.length} {translate("profile.contracts")}
                              </span>
                            </div>
                            <div className='profile-state' style={{ marginLeft: 20 }}>
                              <i className='icon-lawyers'></i>
                              <span>
                                {this.state.userLawyerStatistics} {translate("profile.lawyers")}
                              </span>
                            </div>
                          </div>
                          <div className='profile-type'>
                            <span className='type'>@{currentUser.username}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4'>
                      <div className='profile-buttons'>
                        <a href='/settings' className='button button-primary'>
                          {translate("profile.edit_profile")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='profile-content mg-top-40 pd-rl'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-8'>
                  <ul className='profile-tab nav nav-tabs'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        href='#bookmarks'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='bookmarks'
                      >
                        {translate("profile.favorites")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href='#about'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='about'
                      >
                        {translate("profile.purchases")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href='#contracts'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='contracts'
                      >
                        {translate("profile.my_contracts")}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href='#packages'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='packages'
                      >
                        {translate("packages.packages")}
                      </a>
                    </li>
                  </ul>
                  <div className='profile-tab-content tab-content' id='tabs'>
                    <BookmarksSection
                      contractBookmarks={this.props.contractBookmarks}
                      lawyerBookmarks={this.props.lawyerBookmarks}
                      lawyersLastPage={this.state.lawyersLastPage}
                      lawyersPage={this.state.lawyersPage}
                      articleBookmarks={this.props.articleBookmarks}
                      articlesMoreClick={() => this.loadMoreArticles()}
                      articlesPerPage={this.state.articlesPerPage}
                      lawyerMoreClick={() => this.loadMoreLawyers()}
                      contractsLastPage={this.state.contractsLastPage}
                      bookmarkContractsPage={this.state.bookmarkContractsPage}
                      moreContractsBookmarksClick={() => this.loadMoreContracts()}
                      translate={translate}
                    />
                    <PurchasesSection
                      interactions={this.props.lawyerInteractions}
                      translate={translate}
                      loadMore={() => this.loadMorePurchases()}
                      moreLoading={this.state.morePurchasesLoading}
                    />
                    <ContractsSection
                      contracts={this.props.contracts}
                      userContractsLastPage={this.state.userContractsLastPage}
                      contractsPage={this.state.contractsPage}
                      userMoreContracts={() => this.loadMoreUserContracts()}
                      translate={translate}
                    />
                    <PackagesSection
                      translate={translate}
                      packages={this.props.purchasedPackages}
                      loading={this.state.packagesLoading}
                      loadMore={() => this.loadMorePackages()}
                    />
                  </div>
                </div>
                <div className='col-md-12 col-lg-12 col-xl-4'>
                  <div className='profile-aside'>
                    <div className='profile-aside-content'>
                      <div className='profile-aside-content-item'>
                        <h3>{translate("profile.email")}</h3>
                        <p>{currentUser.email}</p>
                        <a href={"mailto:" + currentUser.email} className='next'>
                          <i className='icon-next'></i>
                        </a>
                      </div>
                      <h3>{translate("profile.language")}</h3>
                      {currentUser.languages.length > 0 ? (
                        currentUser.languages.map(item => <p>{item.name}</p>)
                      ) : (
                        <p>{translate("profile.no_languages")}</p>
                      )}
                      <h3>{translate("lawyers.details.location")}</h3>
                      <p>{currentUser.address}</p>
                    </div>
                    <div className='profile-aside-map'>
                      <Maps
                        lat={currentUser.latitude}
                        long={currentUser.longitude}
                        lawyers={[currentUser]}
                      />
                    </div>
                    {/* <div className="profile-aside-copy">
                      <div className="copy-link">
                        <input
                          type="text"
                          className="wal-form-control"
                          value="wal.worl/as2049sc.12"
                          readOnly
                        />
                        <a className="copy">
                          <i className="icon-copy"></i>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articleBookmarks: state.currentUserBookmarksArticles,
    contractBookmarks: state.currentUserBookmarksContracts,
    lawyerBookmarks: state.currentUserBookmarksLawyers,
    current_user: state.currentUser,
    bookmarks: state.currentUserBookmarks,
    contracts: state.currentUserContracts,
    lawyerInteractions: state.lawyerInteractions,
    purchasedPackages: state.purchasedPackages,
    translate: getTranslate(state.locale),
  };
}

export default connect(mapStateToProps, {
  loadProfile,
  fetchUserArticlesBookmarks,
  fetchUserContractsBookmarks,
  fetchUserLawyersBookmarks,
  fetchUserContracts,
  getLawyerInteractions,
  getPurchasedPackages,
  emptyRedux,
})(CurrentProfile);
