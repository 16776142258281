import React
    from 'react';

import { Link } from 'react-router-dom';

import Image
    from "../../components/shared/Image";


export default class KnowledgeCard extends React.Component {
  
  render() {
    return(
      <div className="col-md-4 col-lg-4">
        <div className="knowledge-item">
          <Image src={this.props.source} alt="knowledge" /> {/* to be replaced with <Image */}
          <div className="knowledge-content">
            <Link to={`/article/` + this.props.id}>
              <span className="tag">{this.props.type || "Type"}</span>
              <h1>{this.props.title}</h1>
              <div className="knowledge-content-bottom">
                <span className="author">by {this.props.author}</span>
                <span className="views"><i className="icon-eye"></i>{this.props.viewed} views</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
