import api from "../api";
import { PACKAGES_BY_ID, PACKAGE_ITEMS, PACKAGE_REVIEWS, PACKAGES, PACKAGE_COMMENTS, LAWYER_PACKAGES, LAWFIRM_PACKAGES } from "../constants/types";
import { WEB_URL } from "../constants/types";

import { getLanguageAsNumber } from "../services/storeLanguage";

export const getPackages = (params, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages?per_page=${10}&`;
    if (params.page) {
      url += `page=${params.page}&`;
    }
    if (params.title) {
      url += `title=${params.title}&`;
    }
    if (params.sort) {
      url += `sort=${params.sort}&`;
    }
    if (params.fromPrice) {
      url += `from_price=${params.fromPrice}&to_price=${params.toPrice}&`;
    }
    if (params.language) {
      url += `language=${params.language}&`;
    }
    if (params.rating) {
      url += `rating=${params.rating}&`;
    }
    if (params.categories && params.categories.length > 0) {
      let categoryLink = "";
      params.categories.map((cat, index) => {
        return (categoryLink = categoryLink + `categories[${index}]=${cat}&`);
      });
      url += categoryLink;
    }
    if (params.jurisdictions && params.jurisdictions.length > 0) {
      let jurisdictions = "";
      params.jurisdictions.map((cat, index) => {
        return (jurisdictions = jurisdictions + `jurisdictions[${index}]=${cat}&`);
      });
      url += jurisdictions;
    }
    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGES, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const getPackagesById = (id, stopLoading, updateBookmark) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages/${id}?language_id=${getLanguageAsNumber}`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGES_BY_ID, payload: res.data.data });
        stopLoading && stopLoading();
        updateBookmark && updateBookmark(res.data.data.is_bookmarked);
      })
      .catch(err => {});
  };
};

export const getLawyerPackages = (params, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages?lawyer=${params.id}&per_page=${params.perPage}&`;
    if (params.title) {
      url += `title=${params.title}&`;
    }
    if (params.sort) {
      url += `sort=${params.sort}&`;
    }
    await api
      .get(url)
      .then(res => {
        dispatch({ type: LAWYER_PACKAGES, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const getLawfirmPackages = (params, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages?company_id=${params.id}&per_page=${params.perPage}&`;
    if (params.title) {
      url += `title=${params.title}&`;
    }
    if (params.sort) {
      url += `sort=${params.sort}&`;
    }
    await api
      .get(url)
      .then(res => {
        dispatch({ type: LAWFIRM_PACKAGES, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const getPackageItems = (id, perPage, name, sort, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/package-items/${id}?per_page=${perPage}&`;
    if (name) {
      url += `contract_name=${name}&`;
    }
    if (sort) {
      url += `sort=${sort}`;
    }
    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGE_ITEMS, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const getPackageReviews = (id, perPage, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages/${id}/reviews?per_page=${perPage}&sort=-updated_at`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGE_REVIEWS, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const getPackageComments = (id, perPage, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/all-packages/${id}/comments?per_page=${perPage}&sort=-updated_at`;
    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGE_COMMENTS, payload: res.data });
        stopLoading && stopLoading();
      })
      .catch(err => {});
  };
};

export const deletePackageReview = async (itemId, reviewId, successResult) => {
  let url = `${WEB_URL}/packages/${itemId}/reviews/${reviewId}`;

  api
    .delete(url)
    .then(data => {
      successResult();
    })
    .catch(err => {});
};

export const deletePackageComment = async (itemId, reviewId, successResult) => {
  let url = `${WEB_URL}/packages/${itemId}/comments/${reviewId}`;

  api
    .delete(url)
    .then(data => {
      successResult();
    })
    .catch(err => {});
};

export const reviewPackage = async (id, state, processData) => {
  let data = {
    comment: state.reviewComment,
    quality: parseInt(state.quality),
    professionality: parseInt(state.professionality),
    usability: parseInt(state.usability),
    understandability: parseInt(state.understandability),
  };
  let url = `${WEB_URL}/packages/${id}/reviews`;
  api
    .post(url, data)
    .then(() => {
      processData && processData();
    })
    .catch(err => {});
};

export const commentOnPackage = async (id, comment, processData) => {
  let url = `${WEB_URL}/packages/${id}/comments`;
  api
  .post(url, {comment})
  .then(() => {
    processData && processData();
  })
  .catch(err => {});
}
