import React from 'react'

import {logout} from "../../actions/auth";

import {getConversations, selectConversation} from '../../actions/chat';

import {connect} from "react-redux";

import Image from "../../components/shared/Image";

import NotificationComponent from '../../components/profile/NotificationComponent'

import ChatNotification from '../chat/notification'

class CurrentUserDropdown extends React.Component {

    componentDidMount() {
      this.props.getConversations();
    }

    render() {
        let { translate, current_user} = this.props
        return (
          <>
          <div className="header-right">
            <div className="header-search click-search">
                <a><i className="icon-search"></i></a>
            </div>

            <ChatNotification {...this.props} />

            <div className="header-notifications">
              <div data-toggle="dropdown" data-display="static">
                <a>
                  <i className="icon-notification"></i>
                  {/* <div className="notifications-number"></div> */}
                </a>
              </div>
              <div className="dropdown-menu header-notifications-menu">
                <div className="header-notifications-top">
                  <h3>{translate('header.notifications')}</h3>
                  <a href="/notifications">{translate('articles.details.view_all')}</a>
                </div>
                <div className="header-notifications-body">
                  <NotificationComponent translate={translate} />
                </div>
              </div>
            </div>
            <div className="header-profile">
              <div className="dropdown-profile header-profile-wrap">
                <div data-toggle="dropdown" className="header-profile-info" data-display="static">
                <div className="header-profile-icon">
                  <i className="icon-users"></i>
                </div>
                  <div className="header-profile-img">
                    <Image src={current_user && current_user.avatar} alt="header"/>
                  </div>
                  <div className="header-profile-name">
                    <h1>{current_user ? current_user.name : null}</h1>
                  </div>
                </div>
                <ul className="dropdown-menu">
                  <li><a href="/profile"><i className="icon-users"></i>{translate('header.profile')}</a></li>
                  <li><a href="/notifications"><i className="icon-notification"></i>{translate('header.notifications')}</a></li>
                  <li><a href="/settings"><i className="icon-settings"></i>{translate('header.settings')}</a></li>
                  <li><a href="#" onClick={() => this.props.logout()} className="log-out"><i className="icon-log-out"></i>{translate('header.sign_out')}</a></li>
                </ul>
              </div>
            </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
    return {
        current_user: state.currentUser,
        conversations: state.chat.conversations
    };
}

export default connect(mapStateToProps, {
    logout, getConversations, selectConversation
})(CurrentUserDropdown);
