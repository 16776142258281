import React from "react";

import Verified from "../../../assets/img/icons/verify.svg";

import ContractsSearched from "../../../components/contracts/ContractsSearched";

import KnowledgeCard from "../../../components/lawyers/KnowledgeCard";

import LawyerReviews from "./LawyerReviews";

import {
  fetchLawyerById,
  lawyerContractsSearch,
  lawyerArticlesSearch,
  getLawyerReviews,
} from "../../../actions/lawyersActions";

import { getLawyerPackages } from "../../../actions/packagesActions";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Image from "../../../components/shared/Image";

import ReactLoading from "react-loading";

import Maps from "../../../components/lawyers/Map";

import { IMAGE_BASE, WEB_URL } from "../../../constants/types";

import DisplayRating from "../../../components/shared/DisplayRating";

import ReportLawyerModal from "../../../components/shared/modals/ReportLawyerModal";

import { getToken, imageUrl } from "../../../helpers";

import ReactHtmlParser from "react-html-parser";

import api from "../../../api";

import CategorySvg from "../../../components/shared/CategorySvg";

import { EmptyResult } from "../../../components/shared/filters";

import { getTranslate } from "react-localize-redux";

import ChatPaymentAlert from "../../../components/shared/modals/ChatPaymentAlert";

import { Modal } from "../../../components/shared/modals/payment";

import { PaymentModal } from "../../../components/shared/modals/payment/chat";

import PackageTab from "./tabs/PackageTab";
import { isThisISOWeek } from "date-fns";

class LawyersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      loading: true,
      loadingContracts: false,
      loadingArticles: false,
      loadingPackages: false,
      searchItemsRelaod: false,
      allPackagesLoading: false,
      morePackagesLoading: false,
      shortCV: true,
      contractInput: "",
      articleInput: "",
      packageInput: "",
      currentTab: "about",
      callType: "",
      paymentType: "",
      packageSort: "-created_at",
      articlesToDisplay: 6,
      contractsToDisplay: 4,
      packagesToDisplay: 4,
      selectedContract: null,
      selectedPackage: null,
    };
  }

  componentDidMount() {
    this.requestData();
  }

  async requestData() {
    let { id, contractsToDisplay, articlesToDisplay } = this.state;

    await this.props.getLawyerReviews(id, 5);
    await this.props.lawyerContractsSearch(
      id,
      this.contractsLoading.bind(this),
      null,
      null,
      contractsToDisplay
    );
    await this.props.lawyerArticlesSearch(
      id,
      this.contractsLoading.bind(this),
      null,
      null,
      articlesToDisplay
    );
    await this.props.fetchLawyerById(id, this.stopLoading.bind(this));
    await this.props.getLawyerPackages({ id, perPage: this.state.packagesToDisplay, sort: this.state.packageSort }, null);
  }

  contractSearchinput(input) {
    let { id, contractsToDisplay } = this.state;
    this.setState({ contractInput: input, searchItemsRelaod: true });
    this.props.lawyerContractsSearch(
      id,
      this.contractsLoading.bind(this),
      input,
      null,
      contractsToDisplay
    );
  }

  articlesSearchinput(input) {
    let { id, articlesToDisplay } = this.state;
    this.setState({ articleInput: input, searchItemsRelaod: true });
    this.props.lawyerArticlesSearch(
      id,
      this.contractsLoading.bind(this),
      input,
      null,
      articlesToDisplay
    );
  }

  packagesSearchInput(input) {
    let { id, packagesToDisplay, packageSort } = this.state;
    this.setState({ packageInput: input, allPackagesLoading: true });
    this.props.getLawyerPackages(
      {
        id,
        title: input,
        perPage: packagesToDisplay,
        sort: packageSort,
      },
      () => this.setState({ allPackagesLoading: false })
    );
  }

  sortContracts(sort) {
    let { id, contractsToDisplay } = this.state;
    let input = this.state.contractInput;
    this.setState({ searchItemsRelaod: true });
    this.props.lawyerContractsSearch(
      id,
      this.contractsLoading.bind(this),
      input,
      sort,
      contractsToDisplay
    );
  }

  sortArticles(sort) {
    let { id, articlesToDisplay } = this.state;
    let input = this.state.articleInput;
    this.setState({ searchItemsRelaod: true });
    this.props.lawyerArticlesSearch(
      id,
      this.contractsLoading.bind(this),
      input,
      sort,
      articlesToDisplay
    );
  }

  sortPackages(sort) {
    let { id, packagesToDisplay, packageInput } = this.state;
    this.setState({ allPackagesLoading: true });
    this.props.getLawyerPackages({
      id,
      perPage: packagesToDisplay,
      title: packageInput,
      sort,
    }, () => this.setState({allPackagesLoading: false}));
  }

  onLoadMoreButton(type) {
    let id = this.state.id;
    if (type === "contracts") {
      this.setState({
        contractsToDisplay: this.state.contractsToDisplay + 4,
        loadingContracts: true,
      });
      this.props.lawyerContractsSearch(
        id,
        () => this.setState({ loadingContracts: false }),
        this.state.contractInput,
        null,
        this.state.contractsToDisplay + 4
      );
    } else if (type === "packages") {
      this.setState({
        packagesToDisplay: this.state.contractsToDisplay + 4,
        loadingPackages: true,
      });
      this.props.getLawyerPackages(
        {
          id,
          perPage: this.state.packagesToDisplay + 4,
          title: this.state.packageInput,
          sort: this.state.packageSort,
        },
        () => this.setState({ loadingPackages: false })
      );
    } else {
      this.setState({
        articlesToDisplay: this.state.articlesToDisplay + 6,
        loadingArticles: true,
      });
      this.props.lawyerArticlesSearch(
        id,
        () => this.setState({ loadingArticles: false }),
        this.state.articleInput,
        null,
        this.state.articlesToDisplay + 6
      );
    }
  }

  stopLoading() {
    this.setState({ loading: false });
  }

  contractsLoading() {
    this.setState({ searchItemsRelaod: false });
  }

  onChatClick = event => {
    event.preventDefault();
    this.goToChat();
  };

  goToChat() {
    let currentLawyer = this.props.lawyerById;
    this.setState({ paymentType: "Message" });
    const _this = this;

    if (currentLawyer.chat_available) {
      api
        .post("chat/conversations", {
          participant_id: currentLawyer.id,
        })
        .then(result => {
          _this.props.history.push("/chat/" + result.data.data.id);
        })
        .catch(err => {});
    } else {
      window.$('#chat-payment-modal').modal('toggle');
    }
  }

  displayCV(cv) {
    if (cv) {
      return (
        <p>
          {this.state.shortCV ? cv.slice(0, 150) + "..." : cv}
          {cv.length > 150 && (
            <button
              onClick={() => this.setState({ shortCV: !this.state.shortCV })}
              className='button button-gray'
              style={{ width: "10%", marginTop: 20 }}
            >
              {this.state.shortCV ? "Read more" : "Read less"}
            </button>
          )}
        </p>
      );
    } else {
      return <p>No CV available.</p>;
    }
  }

  displayCategories(categories) {
    let collectMainCategory = [];
    categories.map(category => {
      if (category.parent_category_relation) {
        collectMainCategory.push(category.parent_category_relation);
      }
    });
    const mainUniqueCategories = [
      ...new Map(collectMainCategory.map(item => [JSON.stringify(item), item])).values(),
    ];
    const categoryIcons = mainUniqueCategories.map((category, index) => {
      return (
        <div
          key={category.id}
          style={index === mainUniqueCategories.length - 1 ? null : { marginRight: 10 }}
        >
          <CategorySvg index={category.id} width={16} />
        </div>
      );
    });
    const categoryIconNames = mainUniqueCategories.map((category, index) => (
      <li key={index}>{category.lang.name}</li>
    ));
    const categoryNames = (
      <div className='lawyers-type-tooltip'>
        <div className='lawyers-type-tooltip-wrap'>
          <ul>{categoryIconNames}</ul>
        </div>
      </div>
    );

    return (
      <>
        {categoryIcons}
        {categoryNames}
      </>
    );
  }

  displayBar() {
    let currentLawyer = this.props.lawyerById;
    let { has_bar, bar_verified, bar } = currentLawyer;

    if (bar_verified !== 0) {
      var addressVerified = [];
      bar.map(b => {
        if (b.approved == 1) {
          addressVerified.push(b.country.name);
        }
      });

      var addressText = "";
      addressVerified.map((address, index) => {
        addressText += `${address}`;
        if (index !== addressVerified.length - 1) {
          addressText += ", ";
        }
      });

      return (
        <div className='profile-state'>
          <i className='icon-world'></i>
          <span>{addressText}</span>
        </div>
      );
    }
  }

  displayContractSearch() {
    if (this.state.searchItemsRelaod) {
      return (
        <div
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' />
        </div>
      );
    } else {
      return (
        <ContractsSearched
          items={this.props.lawyersContracts.data || []}
          selectedContract={item => this.setState({ selectedContract: item, selectedPackage: null })}
        />
      );
    }
  }

  displayKnowledgeSearch() {
    let { translate } = this.props;
    let currentLawyer = this.props.lawyerById;
    let articles = this.props.lawyersArticles.data && this.props.lawyersArticles.data;

    if (this.state.searchItemsRelaod) {
      return (
        <div
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' />
        </div>
      );
    } else {
      if (articles && articles.length > 0) {
        return articles.map((item, index) => {
          if (index < this.state.articlesToDisplay) {
            return (
              <KnowledgeCard
                key={item.id}
                id={item.id}
                author={currentLawyer.name}
                title={item.title}
                source={item.image}
                viewed={item.viewed}
                type={item?.categories[0]?.lang.name}
              />
            );
          }
        });
      } else {
        return (
          <EmptyResult
            icon={"type-contract"}
            title={translate("lawyers.details.empty_title")}
            description={translate("lawyers.details.empty_description")}
            action={{ label: "Go to Knowledge", goTo: "/knowledge" }}
          />
        );
      }
    }
  }

  displayChatButton() {
    return (
      <a className='wal-icons-button message' onClick={this.onChatClick}>
        <i className='icon-chat'></i>
      </a>
    );
  }

  callButtonHandler() {
    if (this.props.lawyerById.call_available) {
      this.navigateToCallScreen();
    } else {
      this.setState({ paymentType: "Call" });
      window.$("#chat-payment-modal").modal("toggle");
    }
  }

  navigateToCallScreen() {
    let currentLawyer = this.props.lawyerById;
    let callType = this.state.callType ? "audio" : "video";
    const id = currentLawyer.id;
    const callWindow = window.open(
      `${window.location.origin}/CallScreen?callType=${callType}&lawyerName=${currentLawyer.name}&lawyerImg=${currentLawyer.avatar}&userImg=${this.props.currentUserAvatar.avatar}`,
      "_blank",
      "height=720, width=960"
    );
    callWindow.onload = () => {
      callWindow.postMessage({ id }, `${window.location.origin}/CallScreen`);
    };
  }

  render() {
    let { translate } = this.props;
    let currentLawyer = this.props.lawyerById;
    let articles = this.props.lawyersArticles && this.props.lawyersArticles;

    if (this.state.loading) {
      return (
        <div
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type='spin' color='#bea58b' />
        </div>
      );
    }

    return (
      <div>
        <title>{currentLawyer.name} | WAL</title>
        <main className='main'>
          <section
            className='profile pd-rl'
            style={{
              backgroundImage: `url(${IMAGE_BASE + currentLawyer.avatar})`,
            }}
          >
            <div className='container-fluid'>
              <div className='profile-wrap'>
                <div className='profile-top'>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                      <div className='profile-top-wrap'>
                        <a
                          onClick={() => this.props.history.goBack()}
                          style={{ color: "#ffffff", cursor: 'pointer' }}
                          className='back-button'
                        >
                          <i className='icon-back'></i>
                          {translate("lawyers.details.back")}
                        </a>
                        {/* <a href="lawyers.html" className="back-button"><i className="icon-back"></i>Back</a> */}
                        {getToken() && (
                          <a href='' className='bookmarks'>
                            <i
                              className={
                                currentLawyer.is_bookmarked
                                  ? "icon-bookmarks-filled"
                                  : "icon-bookmarks"
                              }
                            ></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='profile-bottom'>
                  <div className='row align-items-end'>
                    <div className='col-md-12 col-lg-8'>
                      <div className='profile-info'>
                        <div className='profile-image'>
                          <Image src={currentLawyer.avatar} alt='person' />
                        </div>
                        <div className='profile-info-wrap'>
                          <div className='profile-info-top'>
                            <div className='profile-status'>
                              <div
                                className={
                                  currentLawyer.is_online ? "status active" : "status inactive"
                                }
                              ></div>
                              <span>
                                {currentLawyer.is_online
                                  ? translate("lawyers.details.online")
                                  : translate("lawyers.details.offline")}
                              </span>
                            </div>
                            <div className='lawyers-type'>
                              {this.displayCategories(currentLawyer.categories)}
                            </div>
                          </div>
                          <div className='profile-name'>
                            <h1>
                              {currentLawyer.title?.title || currentLawyer.title}{" "}
                              {currentLawyer.name}
                            </h1>
                            {currentLawyer.bar_verified && (
                              <div className='verify-badge'>
                                <img src={Verified} alt='verify' />
                              </div>
                            )}
                          </div>
                          <div className='profile-rating-state'>
                            <div className='profile-rating'>
                              <div
                                className='rated-stars'
                                onClick={() => window.$("#review-lawyer").modal("toggle")}
                              >
                                <DisplayRating
                                  stars={[0, 1, 2, 3, 4]}
                                  currentRate={currentLawyer.review.overall_rating}
                                />
                              </div>
                              <span>{currentLawyer.review.overall}</span>
                            </div>
                            {this.displayBar()}
                          </div>
                        </div>
                      </div>
                    </div>
                    {getToken() && (
                      <div className='col-md-6 col-lg-4'>
                        <div className='profile-buttons'>
                          {/*<a href="" className="button button-primary">{translate('lawyers.details.call_now')}</a>*/}
                          {currentLawyer.allow_phone_calls && (
                            <a
                              className='button button-primary callsButtons'
                              onClick={() => this.callButtonHandler()}
                              style={{textAlign: 'center'}}
                            >
                              {translate("lawyers.details.audio_call")}
                            </a>
                          )}
                          {currentLawyer.allow_video_calls && (
                            <a
                              className='button button-primary callsButtons'
                              style={{ marginLeft: 24, textAlign: 'center' }}
                              onClick={() => this.callButtonHandler()}
                            >
                              {translate("lawyers.details.video_call")}
                            </a>
                          )}
                          <div className='wal-icons'>
                            {this.displayChatButton()}

                            <div className='dropdown-options profile-options-dropdown'>
                              <div data-toggle='dropdown' data-display='static'>
                                <a className='wal-icons-button options'>
                                  <i className='icon-options'></i>
                                </a>
                              </div>
                              <ul className='dropdown-menu'>
                                <div className='more-options'>
                                  <span>{translate("lawyers.details.more_option")}</span>
                                </div>
                                <li>
                                  <a
                                    href='#'
                                    onClick={() => window.$("#report").modal("toggle")}
                                    className='report-lawyer'
                                    data-toggle='modal'
                                    data-target='#report'
                                  >
                                    <i className='icon-report-lawyer'></i>
                                    {translate("lawyers.details.report_lawyer")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='profile-content mg-top-40 pd-rl'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-8'>
                  <ul className='profile-tab nav nav-tabs'>
                    <li onClick={() => this.setState({ currentTab: "about" })} className='nav-item'>
                      <a
                        className={`nav-link active`}
                        href='#about'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='about'
                      >
                        {translate("lawyers.details.about")}
                      </a>
                    </li>

                    <li
                      onClick={() => this.setState({ currentTab: "contracts" })}
                      className='nav-item'
                    >
                      <a
                        href='#contracts'
                        className='nav-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='contracts'
                      >
                        {translate("lawyers.details.contracts_and_templates")}
                      </a>
                    </li>
                    <li
                      onClick={() => this.setState({ currentTab: "packages" })}
                      className='nav-item'
                    >
                      <a
                        href='#packages'
                        className='nav-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='packages'
                      >
                        {translate("packages.packages")}
                      </a>
                    </li>
                    <li
                      onClick={() => this.setState({ currentTab: "knowledge" })}
                      className='nav-item'
                    >
                      <a
                        href='#knowledge'
                        className='nav-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='knowledge'
                      >
                        {translate("lawyers.details.knowledge")}
                      </a>
                    </li>
                    <li
                      onClick={() => this.setState({ currentTab: "reviews" })}
                      className='nav-item'
                    >
                      <a
                        href='#reviews'
                        className='nav-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='reviews'
                      >
                        {translate("contracts.details.reviews")}
                      </a>
                    </li>
                  </ul>
                  <div className='profile-tab-content tab-content' id='tabs'>
                    <div className='tab-pane active' id='about'>
                      <div className='panel panel-default'>
                        <div className='panel-heading'>
                          <h4 className='panel-title nav-list'>
                            <a
                              data-toggle='collapse'
                              data-target='#collapse1'
                              aria-expanded='true'
                              data-parent='#tabs'
                            >
                              {translate("lawyers.details.about")}
                            </a>
                          </h4>
                        </div>
                        <div
                          id='collapse1'
                          className='panel-collapse collapse show'
                          data-parent='#tabs'
                        >
                          <div className='panel-body nav-content'>
                            <div className='profile-article'>
                              <h3>{translate("lawyers.details.field_of_practice")}</h3>
                              <ul>
                                {currentLawyer.categories
                                  .filter(c => c.parent_id !== null)
                                  .map((item, index) => {
                                    return <li key={index}>{item.lang.name}</li>;
                                  })}
                              </ul>
                              <h3>{translate("lawyers.details.about_lawyer")}</h3>
                              <p>{ReactHtmlParser(currentLawyer.description)}</p>
                              <h3>{translate("lawyers.details.motto")}</h3>
                              <p>{currentLawyer.motto || "No motto available"}</p>
                              <h3>CV</h3>
                              <p>{this.displayCV(currentLawyer.cv)}</p>
                              <h3>{translate("lawyers.details.membership")}</h3>
                              <div>
                                {currentLawyer.memberships.length > 0 ? (
                                  currentLawyer.memberships.map((item, index) => (
                                    <p key={index}>{item.club}</p>
                                  ))
                                ) : (
                                  <p>{translate("lawyers.details.no_membership")}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='tab-pane' id='contracts'>
                      <div className='panel panel-default'>
                        <div className='panel-heading'>
                          <h4 className='panel-title nav-list'>
                            <a
                              data-toggle='collapse'
                              data-target='#collapse2'
                              aria-expanded='false'
                              data-parent='#tabs'
                            >
                              {translate("lawyers.details.contracts_and_templates")}
                            </a>
                          </h4>
                        </div>
                        <div id='collapse2' className='panel-collapse collapse' data-parent='#tabs'>
                          <div className='panel-body nav-content'>
                            <div className='wal-content-head'>
                              <div className='row align-items-center'>
                                <div className='col-md-12 col-lg-2'>
                                  <div className='wal-content-title'>
                                    <h1>
                                      <span>{this.props.lawyersContracts.meta?.total} total</span>
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                  <div className='wal-form wal-form-group wal-form-search'>
                                    <input
                                      type='search'
                                      name='Search Contracts'
                                      placeholder={translate(
                                        "lawyers.details.search_contracts_and_templates"
                                      )}
                                      className='wal-form-control'
                                      value={this.state.contractInput}
                                      onChange={event =>
                                        this.contractSearchinput(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                  <div className='wal-form wal-form-group wal-form-sort'>
                                    <label htmlFor='select'>{translate("lawyers.sort_by")}:</label>
                                    <div className='wal-form-sort-wrap'>
                                      <select
                                        onChange={event => this.sortContracts(event.target.value)}
                                        className='wal-form-control'
                                      >
                                        <option value='-created_at'>
                                          {translate("lawyers.details.newest_first")}
                                        </option>
                                        <option value='created_at'>
                                          {translate("lawyers.details.oldest_first")}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='profile-contracts'>{this.displayContractSearch()}</div>
                            {this.state.loadingContracts ? (
                              <div style={{ marginLeft: "48%" }}>
                                <ReactLoading type='spin' color='#bea58b' />
                              </div>
                            ) : this.props.lawyersContracts.meta?.total >
                              this.state.contractsToDisplay ? (
                              <div
                                onClick={() => this.onLoadMoreButton("contracts")}
                                className='more-button button button-gray'
                              >
                                {translate("lawyers.details.load_more")}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <PackageTab
                      translate={translate}
                      data={this.props.lawyerPackages}
                      packageInput={this.state.packageInput}
                      allPackagesLoading={this.state.allPackagesLoading}
                      loadingPackages={this.state.loadingPackages}
                      packagesToDisplay={this.state.packagesToDisplay}
                      onLoadMoreButton={() => this.onLoadMoreButton("packages")}
                      packagesSearchInput={input => this.packagesSearchInput(input)}
                      sortPackages={event => this.sortPackages(event)}
                      selectedPackage={packageId => this.setState({selectedContract: null, selectedPackage: packageId})}
                    />
                    <div className='tab-pane' id='knowledge'>
                      <div className='panel panel-default'>
                        <div className='panel-heading'>
                          <h4 className='panel-title nav-list'>
                            <a
                              data-toggle='collapse'
                              data-target='#collapse3'
                              aria-expanded='false'
                              data-parent='#tabs'
                            >
                              {translate("lawyers.details.knowledge")}
                            </a>
                          </h4>
                        </div>
                        <div id='collapse3' className='panel-collapse collapse' data-parent='#tabs'>
                          <div className='panel-body nav-content'>
                            <div className='profile-knowledge'>
                              <div className='wal-content-head'>
                                <div className='row align-items-center'>
                                  <div className='col-md-12 col-lg-2'>
                                    <div className='wal-content-title'>
                                      <h1>
                                        <span>
                                          {this.props.lawyersArticles.meta?.total}{" "}
                                          {translate("lawyers.details.total")}
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-lg-5'>
                                    <div className='wal-form wal-form-group wal-form-search'>
                                      <input
                                        type='search'
                                        name='Search articles'
                                        placeholder={translate(
                                          "lawyers.details.search_for_articles"
                                        )}
                                        className='wal-form-control'
                                        value={this.state.articleInput}
                                        onChange={event =>
                                          this.articlesSearchinput(event.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-lg-5'>
                                    <div className='wal-form wal-form-group wal-form-sort'>
                                      <label htmlFor='select'>
                                        {translate("lawyers.sort_by")}:
                                      </label>
                                      <div className='wal-form-sort-wrap'>
                                        <select
                                          onChange={event => this.sortArticles(event.target.value)}
                                          className='wal-form-control'
                                        >
                                          <option value='-created_at'>
                                            {translate("lawyers.details.newest_first")}
                                          </option>
                                          <option value='created_at'>
                                            {translate("lawyers.details.oldest_first")}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>{this.displayKnowledgeSearch()}</div>
                              {this.state.loadingArticles ? (
                                <div style={{ marginLeft: "48%" }}>
                                  <ReactLoading type='spin' color='#bea58b' />
                                </div>
                              ) : this.props.lawyersArticles.meta?.total >
                                this.state.articlesToDisplay ? (
                                <div
                                  onClick={() => this.onLoadMoreButton("articles")}
                                  className='more-button button button-gray'
                                >
                                  {translate("lawyers.details.load_more")}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                   
                    <div className='tab-pane' id='reviews'>
                      <div className='panel panel-default'>
                        <div className='panel-heading'>
                          <h4 className='panel-title nav-list'>
                            <a
                              data-toggle='collapse'
                              data-target='#reviews-collapse'
                              aria-expanded='false'
                              data-parent='#tabs'
                            >
                              {translate("contracts.details.reviews")}
                            </a>
                          </h4>
                        </div>
                        <div
                          id='reviews-collapse'
                          className='panel-collapse collapse'
                          data-parent='#tabs'
                        >
                          <LawyerReviews
                            translate={translate}
                            reviews={currentLawyer.review}
                            id={this.state.id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-12 col-xl-4'>
                  <div className='profile-aside'>
                    <div className='profile-create-item'>
                      <div className='profile-create-right'>
                        <div className='profile-create-img'>
                          <img
                            src={imageUrl(currentLawyer.company?.logo)}
                            alt='company'
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <div className='profile-create-content'>
                          <h1>
                            {(currentLawyer.company && currentLawyer.company.name) ||
                              translate("lawyers.details.no_company")}
                          </h1>
                          <p>{translate("lawyers.details.company")}</p>
                        </div>
                      </div>
                      <div className='profile-create-left'>
                        {currentLawyer.company?.id && (
                          <Link to={`/lawfirms/${currentLawyer.company.id}`}>
                            <i className='icon-next'></i>
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className='profile-aside-content'>
                      <div className='profile-aside-content-item'>
                        <h3>{translate("lawyers.details.email")}</h3>
                        <p>{currentLawyer.email}</p>
                        <a href={"mailto:" + currentLawyer.email} className='next'>
                          <i className='icon-next'></i>
                        </a>
                      </div>
                      <h3>{translate("lawyers.details.language")}</h3>
                      {currentLawyer.languages.map((item, index) => (
                        <p key={index}>{item.name}</p>
                      ))}
                      <h3>{translate("lawyers.details.location")}</h3>
                      <p>{currentLawyer.address}</p>
                    </div>
                    <div className='profile-aside-map'>
                      <Maps
                        lat={currentLawyer.latitude}
                        long={currentLawyer.longitude}
                        lawyers={[currentLawyer]}
                      />
                    </div>
                    {currentLawyer.latitude && currentLawyer.longitude && (
                      <a
                        href={
                          "https://www.google.com/maps/dir//" +
                          currentLawyer.latitude +
                          "," +
                          currentLawyer.longitude
                        }
                        target='_blank'
                        className='button button-white'
                      >
                        <i className='icon-pin'></i>
                        {translate("lawyers.details.get_direction")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ReportLawyerModal lawyerId={currentLawyer.id} translate={translate} />
        </main>
        <PaymentModal
          item={currentLawyer}
          paymentType={this.state.paymentType}
          translate={translate}
          history={this.props.history}
          goToCall={() => this.navigateToCallScreen()}
        />
        <Modal item={this.state.selectedContract} packageItem={this.state.selectedPackage} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lawyerById: state.lawyerById.data,
    lawyersContracts: state.lawyersContracts,
    lawyersArticles: state.lawyersArticles,
    lawyerPackages: state.lawyerPackages,
    translate: getTranslate(state.locale),
  };
}

export default connect(mapStateToProps, {
  fetchLawyerById,
  lawyerContractsSearch,
  lawyerArticlesSearch,
  getLawyerReviews,
  getLawyerPackages,
})(LawyersDetails);
