import React from 'react';
import ReactDOM from 'react-dom';
import {toast} from 'react-toastify';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/index';

import './assets/style/slick.css';
import './assets/style/bootstrap.min.css';
import './assets/style/main.css';
import './assets/style/magnific-popup.css';
import './assets/js/slick.min.js';
import './assets/js/main.js';
import './assets/js/jquery-ui.min.js';
import './assets/style/custom.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {ChatProvider} from './context/socket';
import {Provider} from 'react-redux';

toast.configure({
  autoClose: 5000,
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
});

ReactDOM.render(
  <Provider store={store}>
    <ChatProvider>
      <App />
    </ChatProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
