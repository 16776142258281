import React
  from 'react';

import Person
  from '../../assets/img/person-1.png'

import Verify
  from '../../assets/img/icons/verify.svg'

import Image
  from '../../components/shared/Image'

import DisplayRating
  from '../../components/shared/DisplayRating'

import CategorySvg
  from '../../components/shared/CategorySvg'

import { bookmarkItem, deleteBookmarkItem }
  from '../../helpers/bookmarkItem'

import { Link }
  from "react-router-dom";

import { getToken }
  from "../../helpers";

class LawyerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBookmarked: this.props.item.is_bookmarked
    };
  }

  triggerLawyerBookmark(e) {
    e.preventDefault()
    let { id } = this.props.item
    let { isBookmarked } = this.state
    if(getToken()) {
      isBookmarked ? deleteBookmarkItem(id, "lawyer", this.catchResult.bind(this)) : bookmarkItem(id, "lawyer", this.catchResult.bind(this))
    } else {
      window.$('#login').modal('toggle')
    }
  }

  catchResult(data) {
    data == 0 ? this.setState({isBookmarked: false}) : this.setState({isBookmarked: true})
    this.props.reloadLawyers && this.props.reloadLawyers();
  }

  displayCategories() {
    let { categories } = this.props.item
    let collectMainCategory = []
    categories.map((category) => {
      if(category.parent_category_relation) {
        collectMainCategory.push(category.parent_category_relation)
      }
    })
    const mainUniqueCategories = [...new Map(collectMainCategory.map(item => [JSON.stringify(item), item])).values()]
    return (
      mainUniqueCategories.map((category) => {
        return (
          <div style={{marginRight: 10}} title={category.lang.name} key={category.id}>
            <CategorySvg index={category.id} width={16} />
          </div>
        )
      })
    )
  }

  render() {
    let { id, rating, name, bar_verified, avatar, description, address, approved, region, is_bookmarked, is_online, zip_code, categories } = this.props.item
    let { isBookmarked } = this.state
    let fixedDescription = description && description.replace(/(<([^>]+)>)/ig,' ')

    return(
      <div className="lawyers-item">
          <div className="lawyers-img">
            <Image src={avatar} alt="person"/>
            <div className={`status ${is_online ? 'active ' : 'inactive'}`}></div>
          </div>
          <Link to={'/lawyers/' + id} >
          <div className="lawyers-content">
            <div className="lawyers-title">
              <div className="lawyers-title-wrap">
                <h1>{name}</h1>
                {bar_verified ? (
                  <div className="verify-badge">
                    <img src={Verify} alt="verify" />
                  </div>
                ) : undefined}
              </div>
              <div className="lawyers-type">
                { this.displayCategories() }
              </div>
            </div>
            <div className="lawyers-info">
              <DisplayRating
                stars={[0,1,2,3,4]}
                currentRate={rating}
              />
              <span className="type">{fixedDescription && fixedDescription.length > 75
                ? fixedDescription.slice(0,35) + "..."
                : fixedDescription}</span>
              { region && region !== "null" &&
                <span className="distance">{`${region} km`}</span>
              }
              <span className="city">{address}</span>
            </div>
          </div>
          <div className="lawyers-bookmarks">
            <div onClick={this.triggerLawyerBookmark.bind(this)}>
              <i className={`${isBookmarked ? 'icon-bookmarks-filled' : 'icon-bookmarks'}`}></i>
            </div> 
          </div>
          </Link>
        </div>
      )
    }
  }

export default LawyerCard
