import React
  from "react";

import { connect }
  from "react-redux";

import { subscriptionPlans, mySubscription, cancelSubscription, subscriptionInvoices }
  from '../../helpers/subscription';

import ChooseSubscriptionCompany
  from "../../components/shared/modals/ChooseSubscriptionCompany";

import { ApproveModal }
  from './subscription/index';

import ReactLoading
  from 'react-loading';

import moment
  from 'moment';

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoading: true,
      invoicesLoading: true,
      error: null,
      errors: null,
      priceType: "monthly",
      mySubscription: [],
      plans: [],
      planSelected: {},
      subscribeName: "Free",
      subscribeId: null,
      monthly_price_calc: "10.00",
      annually_price_calc: "120.00",
      successUpdated: false,
      cancelError: "",
      invoices: []
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    await mySubscription(this.fetchMySubscription.bind(this))
    await subscriptionPlans(this.fetchPlans.bind(this), this.props.currentUser?.currency?.toLowerCase())
    await subscriptionInvoices(this.fetchInvoices.bind(this))
  }

  fetchMySubscription(data) {
    if(data.data.length > 0) {
      this.setState({
        mySubscription: data.data,
        subscribeName: data.data[data.data.length -1].subscription_items[0].plan,
        subscribeId: data.data[data.data.length -1].subscription_items[0].id,
      }, () => this.checkSelectedPlan())
    }
  }

  fetchPlans(data) {
    this.setState({plans: data.data, planLoading: false}, () => this.checkSelectedPlan())
  }

  fetchInvoices(data) {
    // debugger
    this.setState({invoices: data.data, invoicesLoading: false})
  }

  selectPlan(plan) {
    let { priceType } = this.state
    this.setState({planSelected: { id: plan.id, name: plan.name, price: plan[`${priceType}_price_calc`]}})
    window.$('#payment-modal').modal('toggle');
  }

  displayUpcomingBills() {
    let { subscribeName, planSelected, priceType } = this.state
    const { translate } = this.props

    if(planSelected.ends_at) {
      return (
        <div className="accordion-body-wrap">
          <div className="settings-subscription-bills">
            <div className="settings-subscription-bills-content">
              <h2>{subscribeName} plan</h2>
              <div className="settings-subscription-bills-info">
                <span>{translate("settings.subscription.subscription_plan")}</span>
                <span>{planSelected.ends_at.split(" ")[0]} / {planSelected.ends_at.split(" ")[1]}</span>
              </div>
            </div>
            <div className="settings-subscription-bills-price">
              <span>$ {this.state[`${priceType}_price_calc`]}-</span>
            </div>
          </div>
          <div className="settings-subscription-info">
            <p>{translate("settings.subscription.bills_description")}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="accordion-body-wrap">
          <div className="settings-subscription-info">
            <p>{translate("settings.subscription.not_have_bills")}</p>
          </div>
        </div>
      )
    }
  }

  displayCancelButton() {
    const { translate } = this.props
    const { planSelected } = this.state

    if(planSelected) {
      return (
        <button className="button button-danger" data-toggle="modal" data-target="#cancel-subscription">{translate("settings.subscription.cancel_subscription_button")}</button>
      )
    } else {
      return (
        <div className="settings-subscription-cancel-content">
          <p>{translate("settings.subscription.not_have_sybscription")}</p>
        </div>
      )
    }
  }

  requestToCancelSubscription(e) {
    e.preventDefault()
    let { subscribeName, mySubscription, priceType } = this.state
    cancelSubscription(mySubscription[0]?.id, this.successCancel.bind(this), this.errorCancel.bind(this))
  }

  async errorCancel(err) {
    this.setState({cancelError: err.data.message})
  }

  async successCancel() {
    let _this = this
    window.$('#cancel-subscription').modal('toggle');
    this.setState({successUpdated: true})

    setTimeout(function(){
      _this.fetchData()
      _this.setState({successUpdated: false})
    }, 1000);
  }

  checkSelectedPlan() {
    let { plans, priceType, mySubscription } = this.state
    var subscribeName = "Free"

    let checkSub = mySubscription
    if(checkSub.length > 0) {
      this.setState({planSelected: checkSub[checkSub.length - 1], subscribeName: checkSub[checkSub.length - 1].subscription_items[0].plan})
    } else {
      this.setState({planSelected: {}, subscribeName: "Free"})
    }
  }

  displaySelectPlanButton(plan) {
    let { priceType } = this.state
    if(plan.name !== "Free") {
      return (
        <button className="button button-bl" onClick={() => this.selectPlan(plan)}>Select this plan</button>
      )
    }
  }

  displaySubscriptionPlans() {
    let { plans, priceType, mySubscription, subscribeName } = this.state

    if(this.state.planLoading){
      return(
        <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      plans.map((plan, index) => {
        return (
          <div key={index} className="subscription-plan-item">
            <div className="subscription-plan-head">
              <h1>{plan.name}</h1>
            </div>
            <div className="subscription-plan-body">
              <ul>
                <li>{plan.description}</li>
              </ul>
            </div>
            { subscribeName?.toLowerCase() == plan.name?.toLowerCase().replace(/\s/g, '-') ?
              <>
                <div className="subscription-plan-footer">
                  <span className="price">$ {plan[`${priceType}_price_calc`]}</span>
                  <button className="button button-white">This plan is active</button>
                </div>
                <div className="info">
                  <i className="icon-done-check"></i>
                  <span>Currently Active</span>
                </div>
              </>
              :
              <div className="subscription-plan-footer">
                <span className="price">$ {plan[`${priceType}_price_calc`]}</span>
                {this.displaySelectPlanButton(plan)}
              </div>
            }
          </div>
        )
      })
    )
  }

  changeInterval(e, interval) {
    e.preventDefault()
    let { location } = this.props
    this.setState({priceType: interval}, () => this.checkSelectedPlan())
  }

  invoicesList() {
    let { invoices, invoicesLoading } = this.state

    if(invoicesLoading){
      return(
        <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      invoices.map((invoice, index) => {
        return (
          <div key={index} className="settings-invoices-item">
            <span>{invoice.created}</span>
            <span>{invoice.subscription_plan.name}</span>
            <span className={`invoices-status ${invoice.status == 'paid' ? 'active' : 'failed'}`}>{invoice.status}</span>
            {/*<div className="settings-invoices-item-action">
              <a href="#" className="wal-icons-button"><i className="icon-download"></i></a>
            </div>*/}
          </div>
        )
      })
    )
  }

  changePlan(plan) {
    window.$('#subscription-company').modal('toggle');
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, type, priceType, successUpdated, planSelected, invoices, mySubscription } = this.state
    let { gender, nationality_id, nationality } = currentUser
    let planSelectedObject = Object.keys(planSelected)
    let currentSubItem = planSelected.subscription_items && planSelected.subscription_items[planSelected.subscription_items.length - 1]
    let subCurrency = invoices[0]?.currency;

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-subscription">
            <div className="settings-subscription-top">
              <div className="settings-subscription-top-wrap">
                <div className="settings-subscription-top-left">
                  <h1>{translate("settings.subscription.your_subscription")}</h1>
                  <span className="plan">{currentSubItem && currentSubItem.subscription_plan.name}</span>
                </div>
                <div className="settings-subscription-top-right">
                  {this.displayCancelButton()}
                  <button className="button button-white" onClick={this.changePlan.bind(this)}>{translate("settings.subscription.change_plan")}</button>
                </div>
              </div>
            </div>
            <div className="settings-subscription-content">
              <div className="settings-subscription-content-wrap">
                <div className="settings-subscription-content-item">
                  <span className="title">{translate("settings.subscription.price")}</span>
                  <p><span className="price">{currentSubItem && currentSubItem.subscription_plan.monthly_price_calc} {currentSubItem && currentSubItem.subscription_plan.currency.toUpperCase()} </span> <span className="month">/{translate("settings.subscription.month")}</span></p>
                </div>
                <div className="settings-subscription-content-item">
                  <span className="title">{translate("settings.subscription.subscription_status")}</span>
                  <span className="status active">{translate("settings.subscription.active")}</span>
                </div>
                <div className="settings-subscription-content-item">
                  <span className="title">{translate("settings.subscription.active_since")}:</span>
                  <p>{planSelectedObject.length !== 0 && moment(planSelected.start_at).format("DD MMM YYYY")}</p>
                </div>
                <div className="settings-subscription-content-item">
                  <span className="title">{translate("settings.subscription.next_payment")}</span>
                  <div className="settings-subscription-content-bottom">
                    <p>{planSelectedObject.length !== 0 && moment(planSelected.ends_at).format("DD MMM YYYY")} </p>
                    <div className="subscription-info-tooltip">
                      <i className="icon-warning-info"></i>
                      <div className="info-tooltip">
                        <div className="info-tooltip-inner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-invoices-title">
              <div className="settings-invoices-title-wrap">
                <h1>{translate("settings.subscription.invoices")} <span>({invoices.length > 0 && invoices.length})</span></h1>
              </div>
            </div>
            <div className="settings-invoices">
              {this.invoicesList()}
            </div>
          </div>
        </div>
        {subCurrency &&
         <ChooseSubscriptionCompany
          translate={translate}
          currentSubItem={currentSubItem}
          subCurrency={subCurrency}
          close
        />
        }
        <ApproveModal
          translate={translate}
          cancelError={this.state.cancelError}
          requestToCancelSubscription={this.requestToCancelSubscription.bind(this)}
        />
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(Subscription);
