import React
  from "react";

const ApproveModal = (props) => {
  return (
    <div className="modal fade modal-ge delete-modal" id="cancel-subscription" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="payment-modal-wrap modal-ge-wrap">
            <div className="modal-ge-content">
              <div className="subscription-modal-icon">
                <i className="icon-subscribtion"></i>
              </div>
              <h1>{props.translate("settings.payments.are_you_sure")}</h1>
              <p>{props.translate("settings.subscription.about_to_cancel")}</p>
              {props.cancelError && <p style={{color: 'red'}}>{props.cancelError}</p>}
              <div className="modal-ge-bottom-buttons">
                <a href="#" className="button button-white" data-dismiss="modal">{props.translate("settings.subscription.keep_subscription")}</a>
                <a href="#" className="button button-danger" onClick={(e) => props.requestToCancelSubscription(e)}>{props.translate("settings.subscription.cancel_subscription_button")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ApproveModal;
