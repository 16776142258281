import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactEchoRedux from "react-echo-redux";
import { socketConfig } from "../config/ws";
import store from "../store";

export const useEcho = () => {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id) {
      try {
        ReactEchoRedux.getEcho();
      } catch (err) {
        ReactEchoRedux.init(store, socketConfig);

        ReactEchoRedux.private("chat.conversations." + user.id, {
          ".chat.conversations-update": "WS_CONVERSATION_UPDATE",
          ".chat.conversations-deleted": "WS_CONVERSATION_DELETE",
          ".chat.conversations-started": "WS_CONVERSATION_CREATED",
        });
      }
    }
    setLoading(false);
  }, [dispatch, user?.id]);

  return loading;
};
