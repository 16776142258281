import React
    from 'react';
import { lawyersRecommended }
  from "../../../actions/lawyersActions";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import ReactLoading
  from 'react-loading';
import LawyerInfoWide
  from '../../../components/shared/LawyerInfoWide';
import { Link }
  from "react-router-dom";
import { fetchArticlesForSlider }
  from "../../../actions/articlesActions";
import Image
  from "../../../components/shared/Image";

class Knowledge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !this.props.articleForSlider.data
    };
  }
   componentDidMount() {
     this.requestData()
     window.slider()
  }

   requestData() {
    if(!this.props.articleForSlider.data) {
      this.props.fetchArticlesForSlider(this.stopLoading.bind(this))
    }
  }

  stopLoading() {
    this.setState({loading: false})
    window.slider()
  }

  truncate(str) {
    return str.length > 30 ? str.substring(0, 35) + "..." : str;
  }

  displayArticlesLinks() {
    let { articleForSlider } = this.props
    let { data } = articleForSlider

    return (
      data && data.map((article) => {
        // const strippedString = article.title.replace(/(<([^>]+)>)/gi, "");
        return (
          <div className="knowledge-item" key={article.id}>
            <Image src={article.image} alt="knowledge" />
            <img src="img/knowledge-1.jpg" alt="knowledge" />
            <div className="knowledge-content">
              <span className="tag">{this.truncate(article.categories.map(x => x.name).join(", "))}</span>
              <Link to={`/article/` + article.id}>
                <h1>{this.truncate(article.title)}</h1>
              </Link>
              <div className="knowledge-content-bottom">
                <span className="author">by {article.lawyer && article.lawyer.name}</span>
                <span className="views"><i className="icon-eye"></i>{article.viewed} views</span>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  render() {
    let { translate, title, viewMore, cover } = this.props
    if(this.state.loading) {
      return (
        <section className={`knowledge-slider ${cover} pd-rl`}>
          <div className="knowledge-slider-wrap" style={{height: 160}}>
            <div style={{ position: 'absolute', width: '100%',
                          display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ReactLoading type="spin" color="#bea58b"/>
            </div>
          </div>
        </section>
      )
    }

    return(
      <section className={`knowledge-slider ${cover} pd-rl`}>
        <div className="knowledge-slider-wrap">
          <div className="container-fluid">
            <div className="main-title">
              <h1>{title}</h1>
              { viewMore && <a href="/knowledge">view more</a> }
            </div>
            <div className="knowledge-slider-items">
              {this.displayArticlesLinks()}
            </div>
            <div className="knowledge-controls slider-controls">
              <button className="knowledge-button-prev"><i className="icon-back"></i></button>
              <button className="knowledge-button-next"><i className="icon-next"></i></button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            articleForSlider: state.articleForSlider
          })
}

export default connect(mapStateToProps, { fetchArticlesForSlider })(Knowledge);
