import React from "react";
import Image from "../../shared/Image";

const PurchasesCard = props => {
  return (
    <div className='contracts-item'>
      <a className='contracts-item-wrap'>
        <div className='contracts-left'>
          <div className='contracts-img'>
            <Image src={props.item.receiver.avatar} alt='person' />
          </div>
        </div>
        <div className='contracts-center'>
          <div className='contracts-content'>
            {/* <div className='contracts-icon'>
              <i className='icon-open-cases-docs'></i>
            </div> */}
            <div className='contracts-content-wrap'>
              <div className='contracts-title'>
                <h1>{props.item.receiver.name}</h1>
              </div>
              <div className='contracts-info'>
                <p>{props.item.type}</p>
                <span>{props.item.time_ago}</span>
              </div>
            </div>
          </div>
        </div>
      </a>
      
    </div>
  );
};

export default PurchasesCard;
