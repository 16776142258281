import api from '../api';

import * as types from '../constants/chat';

import ReactEchoRedux from 'react-echo-redux';

import { WEB_URL } from '../constants/types';
import { result } from 'lodash';

let echo = null;
let channel = null;

export const getConversations =
  (data, query = '') =>
  dispatch => {
    dispatch({ type: types.CONVERSATION_LOADING, payload: true });

    api
      .get(`${WEB_URL}/chat/conversations`, {
        params: data,
      })
      .then(result => {
        dispatch({ type: types.GET_CONVERSATIONS, payload: result.data.data });

        if (result.data.data.length > 0) {
          let conversation = null;

          if (data && data.conversation_id) {
            conversation = result.data.data.find(c => c.id === data.conversation_id);
          }

          if (!conversation || !data.conversation_id) {
            conversation = result.data.data[0];
          }

          dispatch(selectConversation(conversation));
          dispatch(getConversationsSuccess(data, query));
        }

        dispatch({ type: types.CONVERSATION_LOADING, payload: false });
      })
      .catch(error => {
        // console.log(error)
        dispatch({ type: types.CONVERSATION_LOADING, payload: false });
      });
  };

export const refreshConversation = data => dispatch => {
  api
    .get(`${WEB_URL}/chat/conversations`, {
      params: data,
    })
    .then(result => {
      dispatch({ type: types.GET_CONVERSATIONS, payload: result.data.data });
    })
    .catch(error => {
      // console.log(error)
    });
};

export const getConversation =
  (id, search, page = 1) =>
  dispatch => {
    api
      .get(`chat/conversations/${id}/messages?page=${page}`)
      .then(response => {
        dispatch(getConversationSuccess(response.data));
        dispatch(readConversationMessages({ conversation_id: id }));
      })
      .catch(error => {
        console.log(error);
      });
  };

export const selectConversation = conversation => dispatch => {
  dispatch({ type: types.SELECT_CONVERSATION, payload: conversation });

  dispatch({
    type: types.HAS_MORE_MESSAGES,
    payload: {
      hasMoreMessages: true,
      messagesPage: 1,
    },
  });

  echo = ReactEchoRedux.getEcho();

  if (channel) {
    channel.stopListening('.chat.message-added');
    channel.stopListening('.chat.message-deleted');
    channel.stopListening('.client-typing');
    echo.leave(channel.name);
  }

  channel = echo.private('chat.conversation.' + conversation.id + '.message');

  channel
    .listen('.chat.message-added', data => {
      dispatch({ type: types.WS_ADD_MESSAGE, payload: data, test: true });
    })
    .listen('.chat.message-deleted', data => {
      dispatch({ type: types.WS_DELETE_MESSAGE, payload: data });
    })
    .listenForWhisper('typing', data => {
      dispatch(isTyping(true));
    });

  dispatch(getMessages({ conversation_id: conversation.id }));
  if (conversation.unread_count) {
    dispatch(readConversationMessages({ conversation_id: conversation.id }));
  }
};

export const getMessages = data => dispatch => {
  const hasPage = data.page ?? false;

  if (!hasPage) {
    dispatch({ type: types.MESSAGES_LOADING, payload: true });
  }

  api
    .get(`${WEB_URL}/chat/conversations/` + data.conversation_id + '/messages', {
      params: data,
    })
    .then(result => {
      if (hasPage) {
        dispatch({
          type: types.MORE_MESSAGES,
          payload: result.data.data.reverse(),
        });
      } else {
        dispatch({
          type: types.GET_MESSAGES,
          payload: result.data.data.reverse(),
        });
      }

      if (result.data.data.length === 0 || result.data.meta.total === result.data.meta.to) {
        dispatch({
          type: types.HAS_MORE_MESSAGES,
          payload: {
            hasMoreMessages: false,
            messagesPage: result.data.meta.current_page,
          },
        });
      } else {
        dispatch({
          type: types.HAS_MORE_MESSAGES,
          payload: {
            hasMoreMessages: true,
            messagesPage: result.data.meta.current_page,
          },
        });
      }

      if (!hasPage) {
        dispatch({ type: types.MESSAGES_LOADING, payload: false });
      }
    })
    .catch(error => {
      // console.log(error)
      if (!hasPage) {
        dispatch({ type: types.MESSAGES_LOADING, payload: false });
      }
    });
};

export const addMessage = data => dispatch => {
  api
    .post(`${WEB_URL}/chat/conversations/` + data.conversation_id + '/messages', data, {
      headers: {
        'X-Socket-Id': echo.socketId(),
      },
    })
    .then(result => {
      dispatch({ type: types.ADD_MESSAGE, payload: result.data.data });
    })
    .catch(error => {
      // console.log(error)
    });
};

export const addImageMessage = data => dispatch => {
  api
    .post(`${WEB_URL}/chat/conversations/` + data.conversation_id + '/messages', data.form, {
      headers: {
        'X-Socket-Id': echo.socketId(),
        'content-type': 'multipart/form-data',
      },
    })
    .then(result => {
      dispatch({ type: types.ADD_MESSAGE, payload: result.data.data });
    })
    .catch(error => {
      // console.log(error)
    });
};

let typingTimer = null;

export const isTyping = data => dispatch => {
  dispatch({ type: types.WS_TYPING, payload: true });

  clearTimeout(typingTimer); // <-- clear

  typingTimer = setTimeout(() => {
    dispatch({ type: types.WS_TYPING, payload: false });
  }, 500);
};

export const fireTyping = () => dispatch => {
  if (channel) {
    channel.whisper('typing');
  }
};

export const readConversationMessages = data => dispatch => {
  setTimeout(function () {
    api.post(
      `${WEB_URL}/chat/conversations/` + data.conversation_id + '/read',
      {},
      {
        headers: {
          'X-Socket-Id': echo?.socketId(),
        },
      }
    );
  }, 500);
};

export const setChannelId = id => ({
  type: types.SET_CHANNEL_ID,
  id,
});

export const deleteMessage = data => dispatch => {
  api.delete(`${WEB_URL}/chat/conversations/` + data.conversation_id + '/messages/' + data.message_id).then(res => {
    dispatch(closeMessageDeleteModal());
  });
};

export const getConversationsSuccess = (data, query) => ({
  type: types.GET_CONVERSATIONS_SUCCESS,
  payload: { data, query },
});

export const deleteConversation = data => dispatch => {
  api.delete(`${WEB_URL}/chat/conversations/` + data.conversation_id).then(res => {
    dispatch(closeConversationDeleteModal());
  });
};

export const sendReport = data => dispatch => {
  api.post(`${WEB_URL}/reports`, data).then(result => {
    dispatch(closeReportModal());
  });
};

// Modals

export const openMessageDeleteModal = data => dispatch => {
  dispatch({ type: types.MESSAGE_DELETE_MODAL_OPEN, payload: data });
};

export const closeMessageDeleteModal = () => dispatch => {
  dispatch({ type: types.MESSAGE_DELETE_MODAL_CLOSE });
};
export const addNewMessageToConversationSuccess = data => ({
  type: types.ADD_NEW_MESSAGE_TO_CONVERSATION_SUCCESS,
  payload: data,
});

export const addNewMessageToConversationFromNotificationSuccess = data => ({
  type: types.ADD_NEW_MESSAGE_TO_CONVERSATION_FROM_NOTIFICATION_SUCCESS,
  payload: data,
});

export const deleteMessageSuccess = (conversation_id, id) => ({
  type: types.DELETE_MESSAGE_SUCCESS,
  payload: { conversation_id, id },
});

export const getConversationSuccess = data => ({
  type: types.GET_CONVERSATION_SUCCESS,
  payload: data,
});
export const openConversationDeleteModal = data => dispatch => {
  dispatch({ type: types.CONVERSATION_DELETE_MODAL_OPEN, payload: data });
};

export const closeConversationDeleteModal = () => dispatch => {
  dispatch({ type: types.CONVERSATION_DELETE_MODAL_CLOSE });
};

export const openContractModal = () => dispatch => {
  dispatch({ type: types.CONTRACTS_MODAL_OPEN });
};

export const closeContractModal = () => dispatch => {
  dispatch({ type: types.CONTRACTS_MODAL_CLOSE });
};

export const openReportModal = data => dispatch => {
  dispatch({ type: types.MESSAGE_REPORT_MODAL_OPEN, payload: data });
};

export const closeReportModal = () => dispatch => {
  dispatch({ type: types.MESSAGE_REPORT_MODAL_CLOSE });
};

export const getReportReasons = () => dispatch => {
  api.get(`${WEB_URL}/report-reasons`).then(result => {
    dispatch({
      type: types.MESSAGE_REPORT_MODAL_REASONS,
      payload: result.data.data,
    });
  });
};
