import React
  from 'react'
import ReactLoading
  from 'react-loading';

const SelectItem = (props) => {
  let { field } = props
  return (
    <div className={`wal-form wal-form-group ${field.styles}`}>
        <label htmlFor="select">{field.label}</label>
        <div className="wal-form-sort-wrap">
          { field.options && field.options.length > 0 ?
            <select
              className="wal-form-control"
              name={field.name}
              onChange={field.onChange}
              value={field.value} >
              { field.selectLabel ?
                <option value=''>{field.selectLabel}</option>
              :
                <option value=''>{field.selectText} {field.label}</option>
              }
              {field.options.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {field.displayName ? item[field.displayName] : item.lang?.name || item.name }
                  </option>
                )}
              )}
            </select>
            :
            <div style={{marginLeft: "48%"}}>
              <ReactLoading
                type="spin"
                color="red"
                height={'10%'}
                width={'10%'}
              />
            </div>
           }
        </div>
    </div>
  )
}

export default SelectItem
