import jQuery from 'jquery';
import 'slick-carousel';

var $ = jQuery;

function menuMobile() {
  jQuery('#open-menu').on('click', function () {
    var mainNav = jQuery('.header-nav'),
      $self = jQuery(this),
      isOpen = 'is-open';

    if (mainNav.hasClass(isOpen)) {
      mainNav.removeClass(isOpen);
      $self.removeClass(isOpen);
      $('body').removeClass('toggle-open');
    } else {
      mainNav.addClass(isOpen);
      $self.addClass(isOpen);
      $('body').addClass('toggle-open');
    }
  });
  $('.header-nav ul li a').click(function () {
    $('.main-menu-indicator').removeClass('is-open');
    $('.header-nav').removeClass('is-open');
    $('body').removeClass('toggle-open');
  });
}

function stickyNavbar() {
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 50) {
      $('.header').addClass('sticky');
    } else {
      $('.header').removeClass('sticky');
    }
  });
}

function slider() {
  $('.rated-lawyers-wrap').slick({
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    dots: false,
    rows: 0,
    accessibility: false,
    draggable: true,
    nextArrow: '.rated-button-next',
    prevArrow: '.rated-button-prev',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('.rated-lawfirms-wrap').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    dots: false,
    rows: 0,
    accessibility: false,
    draggable: true,
    nextArrow: '.rated-button-next',
    prevArrow: '.rated-button-prev',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('.knowledge-slider-items').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    dots: false,
    rows: 0,
    accessibility: false,
    draggable: true,
    nextArrow: '.knowledge-button-next',
    prevArrow: '.knowledge-button-prev',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function hideshowPass() {
  $('.toggle-password').click(function () {
    $(this).toggleClass('pass-eye-off pass-eye');
    var input = $($(this).attr('toggle'));
    if (input.attr('type') === 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });
}

function tooltip() {
  $('[data-toggle="tooltip"]').tooltip();
}

function search() {
  $('.click-search').click(function () {
    $('.search').addClass('show');
    $('body').addClass('search-on');
  });

  $(document).ready(function () {
    $('.search-close').click(function () {
      $('.search').removeClass('show');
      $('body').removeClass('search-on');
    });
  });
}

function closeModal() {
  $('#dropdown-menu-daterange').dropdown('toggle');
}

function dontCloseDropDown() {
  $('body').on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
  });
}

function cancelDropdown() {
  $('#button-cancel').click(function () {
    $(this).parents('.wal-form-date-dropdown').find('button.wal-form-date-input').dropdown('toggle');
  });
}

function banner_video() {
  $(window).on('scroll', function () {
    if ($(window).scrollTop() >= 200) {
      $('.wal-video').addClass('sticky');
    } else {
      $('.wal-video').removeClass('sticky');
    }

    var scrollBottom = $(document).height() - $(window).height() - $(window).scrollTop();
    if (scrollBottom <= 350) {
      $('.wal-video').addClass('hide');
      $('.wal-video-home').addClass('hide');
    } else {
      $('.wal-video').removeClass('hide');
      $('.wal-video-home').removeClass('hide');
    }
  });
}

function init() {
  menuMobile();
  stickyNavbar();
  // slider();
  hideshowPass();
  tooltip();
  search();
  dontCloseDropDown();
  cancelDropdown();
  banner_video();
}

window.slider = slider;
window.closeModal = closeModal;

jQuery(function ($) {
  init();
});
