import React, { useState, useEffect, useRef } from "react";
import Video, { Room } from "twilio-video";
import { v4 as uuidV4 } from "uuid";

import api from "../../../api";
import { WEB_URL } from "../../../constants/types";
import Image from "../../../components/shared/Image";
import dialingSound from "../../../assets/media/dialingSound.mp3";

import Participant from "./Participant";

const CallScreen = () => {
  const callType = new URLSearchParams(window.location.search).get("callType");

  const [room, setRoom] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [token, setToken] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [muteAudio, setMuteAudio] = useState(false);
  const [muteMic, setMuteMic] = useState(false);
  const [videoOff, setVideoOff] = useState(callType === 'audio');
  const [remoteVideoOff, setRemoteVideoOff] = useState(callType === 'audio');
  const [remoteAudioOff, setRemoteAudioOff] = useState(false);
  const [callTimer, setCallTimer] = useState(0);
  const [lawyerId, setLawyerId] = useState(null);
  const [callEnded, setCallEnded] = useState(false);

  const dialRef = useRef();
  const callTimeInterval = useRef();
  
  const uuid = uuidV4();

  useEffect(() => {
    window.addEventListener(
      "message",
      function _listener(event) {
        if (event.origin !== window.location.origin) {
          return;
        }

        if (!event.data.source) {
          setLawyerId(event.data.id);
          window.removeEventListener("message", _listener, false);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!roomName && lawyerId && window.performance.getEntriesByType("navigation")[0].type !== 'reload') {
      setCallEnded(false);
      api
        .get(
          `${WEB_URL}/twilio-offer-call?user_id=${lawyerId}&uuid=${uuid}&call_type=video`
        )
        .then((res) => {
          connectToCall(res.data.data.token, res.data.data.room_name);
          setRoomName(res.data.data.room_name);
          // console.log(res.data.data.token);
          // setToken(res.data.data.token);
        });
    } else {
      // connectToCall(token, roomName);
      setCallEnded(true);
    }
  }, [lawyerId]);

  const lawyerImage = new URLSearchParams(window.location.search).get(
    "lawyerImg"
    );
    const lawyerName = new URLSearchParams(window.location.search).get(
      "lawyerName"
      );
      const callerImage = new URLSearchParams(window.location.search).get(
        "userImg"
        );
        
        const connectToCall = (connectToken, connectRoomName) => {
          const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
      if (callTimer === 0) {
        callTimeInterval.current = setInterval(() => {
          setCallTimer((prevSeconds) => prevSeconds + 1);
        }, 1000);
      }
      dialRef.current.pause();
      // console.log("participant connected");
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
      // console.log("participant disconnected");
      endCallHandler();
    };

    Video.connect(connectToken, {
      name: connectRoomName,
    }).then((room) => {
      setRoom(room);      
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    dialRef.current.play();
    // setTimeout(() => {
    //   if(participants.length === 0) {
    //     api.post(`${WEB_URL}/twilio-end-call`, {
    //       uuid: uuid,
    //       user_id: lawyerId,
    //     });
    //     endCallHandler();
    //   }
    // }, 30000)

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  };


  const remoteParticipants = participants.map((participant) => {
    return (
      <Participant
        key={participant.sid}
        participant={participant}
        muteAudio={muteAudio}
        remoteTracks={(type, value) => remoteTracks(type, value)}
        participantDisconnect={() => endCallHandler}
      />
    );
  });

  const audioMuteHandler = () => {
    setMuteAudio(!muteAudio);
  };

  const muteMicHandler = () => {
    room.localParticipant.audioTracks.forEach((track) => {
      muteMic ? track.track.enable() : track.track.disable();
    });
    setMuteMic((prevState) => !prevState);
  };

  const videoOffHandler = () => {
    room.localParticipant.videoTracks.forEach((track) => {
      videoOff ? track.track.enable() : track.track.disable();
    });
    setVideoOff(!videoOff);
  };

  const remoteTracks = (type, value) => {
    if (type === "video") {
      setRemoteVideoOff(value);
    } else {
      setRemoteAudioOff(value);
    }
  };

  const endCallHandler = () => {
    // currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
    //   trackPublication.track.stop();
    // });
    // currentRoom.disconnect();
    room?.localParticipant.tracks.forEach(function (trackPublication) {
      trackPublication.track.stop();
    });
    room?.disconnect();
    clearInterval(callTimeInterval.current);
    dialRef.current.pause();
    setCallEnded(true);
    setVideoOff(true);
    // window.close();
  };

  const timer = () => {
      const minutes = Math.floor(callTimer / 60);
      const seconds = callTimer % 60;

      let displaySeconds = (number) => (number <= 9 ? `0${number}` : number);

      return (
        <>
          {minutes}:{displaySeconds(seconds)} min
        </>
      );
  };

  document.body.style.cssText = "overflow-y: hidden";
  document.title = `Call with ${lawyerName}`;
  let videoStyle = videoOff ? { position: "static" } : { position: "absolute" };

  return (
    <div
      className="video-calls-modal"
      id="video-calls-open"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ position: "static" }}
      >
        <div className="modal-content video-calls-wrap">
          <div className="video-calls-top">
            <div className="video-calls-profile">
              <div className="video-calls-profile-img">
                <Image src={lawyerImage} alt="person" />
              </div>
              <div className="video-calls-profile-content">
                <h1>
                  {lawyerName}
                  <span className="muted">
                    {remoteAudioOff && <i className="icon-mute"></i>}
                  </span>
                </h1>
              </div>
            </div>
            <div className="action"></div>
          </div>
          <div
            className={`video-call video-large ${
              remoteVideoOff && "video-disabled"
            }`}
          >
            {!callEnded && (
              <div className={remoteVideoOff && "video-overlay"}>
                {remoteVideoOff ? (
                  <i className="icon-video-call-off"></i>
                ) : (
                  remoteParticipants
                )}
              </div>
            )}
          </div>
          <div
            className={`video-call video-small ${videoOff && "video-disabled"}`}
          >
            <div className="video-overlay">
              <div className="video-small-profile">
                {videoOff && (
                  <Image
                    src={callerImage}
                    alt="person"
                    style={{ position: "absolute" }}
                  />
                )}
                {room && !videoOff? (
                  <Participant
                    key={room.localParticipant.sid}
                    participant={room.localParticipant}
                    videoStyle={videoStyle}
                  />
                ) : (
                  <Image src={callerImage} alt="person" />
                )}
              </div>
            </div>
          </div>
          <div className="video-calls-bottom">
            <audio preload="auto" ref={dialRef} loop>
              <source src={dialingSound} type="audio/mp3" />
            </audio>
            {callTimer !== 0 && !callEnded? (
              <span>{timer()}</span>
            ) : !callEnded ? (
              <span className="animate-typing">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </span>
            ) : <span>Call ended in {timer()}</span>}
            <div className="calls-action">
              {/* <button
                className="icon"
                data-toggle="tooltip"
                data-placement="top"
              >
                <i className="icon-calls-general"></i>
              </button> */}
              {!callEnded ? (
                <>
                  <button
                    className={`icon ${videoOff && "active"}`}
                    onClick={() => videoOffHandler()}
                  >
                    <i
                      className={
                        videoOff ? "icon-video-call-off" : "icon-video-call"
                      }
                    ></i>
                  </button>
                  <button
                    className={`icon ${muteAudio && "active"}`}
                    onClick={() => audioMuteHandler()}
                  >
                    <i
                      className={
                        muteAudio ? "icon-speaker-off" : "icon-speaker"
                      }
                    ></i>
                  </button>
                  <button
                    className={`icon ${muteMic && "active"}`}
                    onClick={() => muteMicHandler()}
                  >
                    <i className="icon-mute"></i>
                  </button>
                  <button
                    className="icon close-call"
                    data-dismiss="modal"
                    onClick={() => endCallHandler()}
                  >
                    <i className="icon-x"></i>
                  </button>{" "}
                </>
              ) : (
                <button
                  className="icon close-call"
                  data-dismiss="modal"
                  onClick={() => window.close()}
                  title="Close"
                >
                  <i className="icon-x"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallScreen;
