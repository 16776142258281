
import React from "react";
import { setSubInfo }
  from "../../../helpers";
import { subscriptionPlans }
  from '../../../helpers/subscription';
import ReactLoading
  from 'react-loading';
import CompanyModal
  from '../../../components/shared/modals/payment/company/CompanyModal';
import ReactHtmlParser
  from 'react-html-parser';
import ChangeSubscriptionCurrencyModal
  from "./ChangeSubscriptionCurrencyModal";

class ChooseSubscriptionCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoading: true,
      plans: [],
      priceType: "monthly"
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const reqCurrency = this.props.subCurrency
    await subscriptionPlans(this.fetchPlans.bind(this), reqCurrency)
  }

  fetchPlans(data) {
    this.setState({plans: data.data, planLoading: false})
  }

  goToSubscription() {
    setSubInfo("choose")
    window.$('#choose_subscription').modal('toggle');
    window.location.href = "settings#subscription";
  }

  checkFreePlan() {
    setSubInfo("free")
    window.$('#choose_subscription').modal('toggle');
  }

  selectPlan(plan) {
    let { priceType } = this.state
    this.setState({planSelected: { id: plan.id, name: plan.name, price: plan[`${priceType}_price_calc`]}})
    window.$('#subscription-company').modal('toggle');

    setTimeout(function () {
      window.$('#subscrption-company-modal').modal('toggle');
    }, 100);

  }

  changeCurrencyHandler() {
    window.$('#subscription-company').modal('toggle');
    setTimeout(function () {
      window.$('#change-subscription-currency').modal('toggle');
    }, 100);
  }

  displaySubscriptionModal() {
    setTimeout(function () {
      window.$('#subscription-company').modal('toggle');
    }, 100);
  }

  displayPlans()  {
    let { plans, planLoading } = this.state
    let { translate, currentSubItem } = this.props

    let subName = currentSubItem?.subscription_plan.name.toLowerCase()

    return (
      <div className="subscription-plan-wrap">
        {planLoading && <ReactLoading type="spin" color="#bea58b" height={'7%'} width={'7%'} />}
        { plans.map((plan, index) => {
          return (
            <div key={index} className="subscription-plan-item">
              <div className="subscription-plan-head">
                <h1>{plan.lang.name}</h1>
                {index == 0 && <span className="sub-info">{translate("modals.company_subscription.free_for_month")}</span>}
              </div>
              <div className="subscription-plan-body">
                <ul>
                  <li>{ReactHtmlParser(plan.lang.description)}</li>
                </ul>
              </div>
              { currentSubItem !== undefined && subName === plan.name.toLowerCase() ?
                <>
                  <div className="subscription-plan-footer">
                    <span className="price">
                      {plan.currency === 'usd' && '$'}{plan.monthly_price_calc} {plan.currency !== 'usd' && plan.currency.toUpperCase()}
                    </span>
                    <span className="month">/{translate("modals.company_subscription.month")}</span>
                    <button className="button button-white">This plan is active</button>
                  </div>
                  <div className="info">
                    <i className="icon-done-check"></i>
                    <span>Currently Active</span>
                  </div>
                </>
                :
                <div className="subscription-plan-footer">
                  <span className="price">{plan.currency === 'usd' && '$'}{plan.monthly_price_calc} {plan.currency !== 'usd' && plan.currency.toUpperCase()}</span><span className="month">/{translate("modals.company_subscription.month")}</span>
                  <button className="button button-bl" onClick={() => this.selectPlan(plan)}>{translate("modals.company_subscription.select_this_plan")}</button>
                </div>
              }
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    let { priceType, planSelected } = this.state
    let { translate, close, subCurrency, firstTime } = this.props

    return (
      <>
        <div className="modal fade modal-ge subscription-modal subscription-modal-plan" id="subscription-company" data-keyboard={this.props.close} data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content subscription-modal-wrap">
              <div className="modal-ge-top">
                <h1>{translate("modals.company_subscription.choose_subscription_plan")}</h1>
               {firstTime !== undefined &&  <p>{translate("modals.company_subscription.choose_subscription_plan_line")}</p> }
                <br/>
                {firstTime ?
                  <>
                  <p>{translate("settings.subscription.first_time_payment_currency")}</p>
                  <p onClick={() => this.changeCurrencyHandler()} style={{fontWeight: 'bold', cursor: 'pointer'}}>{translate("shared.select_currency")}</p>
                  </>
                   :
                  <p>{subCurrency.toUpperCase()} {translate("settings.subscription.payment_currency")}</p>
                }
              </div>
              <div className="subscription-modal-plan">
                {this.displayPlans()}
                <div className="subscription-modal-info">
                  <p>{translate("modals.company_subscription.choose_subscription_plan_description")}</p>
                </div>
                {this.props.close && <div className="modal-ge-close" data-dismiss="modal"><i className="icon-x"></i></div>}
              </div>
            </div>
          </div>
        </div>
        <CompanyModal
          subscription
          plan={planSelected}
          interval={priceType}
          close={close}
          subCurrency={subCurrency}
          firstTime={firstTime}
        />
        <ChangeSubscriptionCurrencyModal />
      </>
    )
  }
};

export default ChooseSubscriptionCompany;
