import React from "react";

import { Modal } from "../shared/modals/payment";

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { fetchContractsPerPage }
  from "../../actions/contractsActions";

import TopRatedContracts
  from '../../components/contracts/TopRatedContracts';

class ContractAndTemplatesOfUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selecetedContract: null
    };
  }

  async componentDidMount() {
    await this.requestData()
  }

  async requestData() {
    await this.props.fetchContractsPerPage(6, this.stopTopRatingLoading.bind(this))
  }

  stopTopRatingLoading() {
    this.setState({loading:false})
  }

  render() {
    let { translate, contractsPerPage } = this.props
    return (
      <>
      <TopRatedContracts
        searchTopRatedLoading={this.state.loading}
        items={contractsPerPage.data}
        cover={"mg-top-112"}
        selectedContract={item => this.setState({selectedContract: item})}
        viewMore
      />
      <Modal item={this.state.selectedContract} />
      </>
    );
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            contractsPerPage: state.allContracts
          })
}

export default connect(mapStateToProps, { fetchContractsPerPage })(ContractAndTemplatesOfUser);
