import React from 'react'
import { Category1, Category2, Category3, Category4, Category5, Category6, Category7, Category8, Category9, Category10, Category11, Category12, Category13, Category58, Category60, Category63, Category69 }
  from '../../assets/img/icons/categories'

const SvgImage = (props) => {
  const indices = [1,2,3,4,5,6,7,8,9,10,11,12,13,58,60,69];

  const svgs = { "1": Category1, "2": Category2, "3": Category3 ,"4": Category4, "5": Category5, "6": Category6, "7": Category7, "8": Category8, "9": Category9,
                 "10": Category10, "11": Category11, "12": Category12, "13": Category13, "58": Category58, "60": Category60, "63": Category63, "69": Category69}
  return(
      <span>{indices.includes(props.index) && <img src={svgs[props.index]} width={props?.width || "20px"} alt="category"/>}</span>
  )
}

export default SvgImage
