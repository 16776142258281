import { WEB_URL } from "../constants/types";

import api from "../api";

import { getLanguageAsNumber } from "../services/storeLanguage";

export async function singleArticle(id, getData, stopLoading) {
    let url = `${WEB_URL}/all-articles/${id}?language_id=${getLanguageAsNumber}`;

    api.get(url)
      .then((data) => {
        getData(data.data)
        stopLoading()
      })
      .catch((err) => {
      });
  }

  export async function articleWhenLogged(id, getData, stopLoading) {
    let url = `${WEB_URL}/all-articles/${id}?language_id=${getLanguageAsNumber}`;

    api.get(url)
      .then((data) => {
        getData(data.data)
        stopLoading()
      })
      .catch((err) => {
      });
  }
