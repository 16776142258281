import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from "qs";

import { getLanguageAsNumber } from "../services/storeLanguage";

export async function subscriptionPlans(fetchSubscriptions, currency) {
  let url = `${WEB_URL}/subscription_plans?type=company&language_id=${getLanguageAsNumber}&`;
  if (currency) {
    url += `currency=${currency}`;
  }

  api
    .get(url)
    .then(data => {
      fetchSubscriptions(data.data);
    })
    .catch(err => {});
}

export async function mySubscription(fetchMySubscription) {
  let url = `${WEB_URL}/my_subscription`;

  api
    .get(url)
    .then(data => {
      fetchMySubscription(data.data);
    })
    .catch(err => {});
}

export async function cancelSubscription(sub_id, successMessage, errorMessage) {
  let url = `${WEB_URL}/subscriptions/${sub_id}`;

  api
    .delete(url)
    .then(data => {
      successMessage();
    })
    .catch(err => {
      errorMessage(err);
    });
}

export async function addSubscription(params, successCatchResult, errorCatchResult) {
  let url = `${WEB_URL}/subscription-company`;
  let data = {
    user_card_id: params.card_id,
    subscription_plan_id: params.plan,
    interval: params.interval,
  };

  api
    .post(url, qs.stringify(data))
    .then(data => {
      successCatchResult();
    })
    .catch(err => {
      errorCatchResult(err.data);
    });
}

export async function subscriptionInvoices(fetchInvoices) {
  let url = `${WEB_URL}/invoices`;

  api
    .get(url)
    .then(data => {
      fetchInvoices(data.data);
    })
    .catch(err => {});
}
