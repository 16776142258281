import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput }
  from '../../components/shared/forms';

import { SuccessAlert, ErrorAlert }
  from '../../components/settings/shared';

import { reportItem }
  from '../../helpers/reportItem';

import ReactLoading
  from 'react-loading';

class ReportIssue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      errors: null,
      description: "",
      successUpdated: false,
      errorUpdated: false,
      avatar: null
    };
  }

  submitReport() {
    let _this = this
    let { description, avatar } = this.state
    this.setState({loading: true})
    reportItem({description: description, avatar: avatar}, this.successResponse.bind(this))
  }

  successResponse() {
    let _this = this
    this.setState({loading: false, successUpdated: true, description: ""})
    setTimeout(function(){ _this.setState({successUpdated: false}) }, 3000);
  }

  displaySubmitButton() {
    const { translate } = this.props
    if(this.state.loading) {
      return <ReactLoading height={40} width={40}  type="spin" color="#bea58b"/>
    } else {
      return <button className="button button-primary" onClick={() => this.submitReport()} disabled={this.state.description.trim() == ""}>{translate("settings.report_issue.send")}</button>
    }
  }

  changeImage(event) {
    if(event.target.files[0] !== undefined) {
      this.setState({
        avatar: event.target.files[0],
      })
    } else {
      this.setState({
        avatar: '',
      })
    }
  }

  render() {
    const { currentUser, translate } = this.props
    let { successUpdated, errorUpdated, avatar } = this.state

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-report">
            <div className="settings-report-top">
              <div className="settings-report-top-wrap">
                <h2>{translate("settings.report_issue.title")}</h2>
                <p>{translate("settings.report_issue.description")}</p>
              </div>
            </div>
            <div className="settings-report-form">
              <div className="settings-report-form-wrap">
                <div className="wal-form wal-form-group">
                  <label>{translate("settings.report_issue.description_label")}</label>
                  <textarea
                    name="comment"
                    className="wal-form-control"
                    placeholder={translate("settings.report_issue.textarea_type")}
                    value={this.state.description}
                    onChange={(e) => this.setState({description: e.target.value})}
                  />
                </div>
                <div className="settings-report-upload">
                  { avatar ?
                    <span className="info">{avatar.name}</span>
                    :
                    <span className="info">{translate("settings.report_issue.max_jpg")}</span>
                  }
                  <div className="wal-form-profile-upload">
                    <button className="button button-white">
                      <input
                        type="file"
                        className="wal-form-profile-file"
                        accept="image/*"
                        onChange={e => this.changeImage(e)}
                        id="f02"
                        name="avatar"
                        accept="image/png, image/jpeg"/>
                      <i className="icon-plus"></i><span>{translate("settings.report_issue.upload_image")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-report-footer">
              <div className="settings-report-footer-wrap">
                {this.displaySubmitButton()}
              </div>
            </div>
          </div>
        </div>
        { successUpdated &&
          <SuccessAlert
            message={translate("settings.report_issue.report_created")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(ReportIssue);
