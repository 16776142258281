
import {ukFlag, deFlag, esFlag, trFlag, ptFlag, itFlag, frFlag} from '../assets/img/flags';

export const storeLanguage = (lang) => {
  localStorage.setItem('language', lang);
}

export const getLanguage = localStorage.getItem('language')
export let getLanguageAsNumber;
export let getLanguageName;
export let getLanguageFlag;
switch(getLanguage){
  case 'en':
    getLanguageAsNumber = 1;
    getLanguageName = 'English';
    getLanguageFlag = ukFlag;
    break;
  case 'de':
    getLanguageAsNumber = 2;
    getLanguageName = 'German';
    getLanguageFlag = deFlag;
    break;
  case 'fr':
    getLanguageAsNumber = 3;
    getLanguageName = 'French';
    getLanguageFlag = frFlag;
    break;
  case 'it':
    getLanguageAsNumber = 60;
    getLanguageName = 'Italian';
    getLanguageFlag = itFlag;
    break;
  case 'tr':
    getLanguageAsNumber = 140;
    getLanguageName = 'Turkish';
    getLanguageFlag = trFlag;
    break;
  case 'es':
    getLanguageAsNumber = 36;
    getLanguageName = 'Spanish';
    getLanguageFlag = esFlag;
    break;
  case 'por':
    getLanguageAsNumber = 105;
    getLanguageName = 'Portugese';
    getLanguageFlag = ptFlag;
    break;
  default:
    getLanguageAsNumber = 1;
    getLanguageName = 'English';
    getLanguageFlag = ukFlag;
} 
