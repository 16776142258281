import React from 'react';

import LawyerCard from '../../../components/lawyers/LawyerCard';

import LawyerInfoWide from '../../../components/shared/LawyerInfoWide';

import { getTranslate } from 'react-localize-redux';

import { connect } from 'react-redux';

import { fetchLawyersByRegion, fetchLawyersByFilter, fetchTopRatedLawyers } from '../../../actions/lawyersActions';

import ReactLoading from 'react-loading';

import _ from 'lodash';

import {
  getCategories,
  getLanguages,
  getCountries,
  getSubCategories,
  getJurisdictions,
} from '../../../helpers/getFilterData';

import LawyersInYourRegion from '../../../components/shared/LawyersInYourRegion';

import { ContentForm, EmptyResult } from '../../../components/shared/filters';

import PaginateItem from '../../../components/shared/PaginateItem';

import { SelectItem } from '../../../components/shared/forms';

const FILTERS = {
  selectedLanguage: '',
  selectedBar: '',
  selectedCountry: '',
  selectedCategory: '',
  selectedCategories: [],
  selectedJurisdictions: [],
  rating: null,
  radius: 30,
};

class Lawyers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topRatedLawyersLoading: this.props.topRatedLawyers.length === 0,
      reloadData: false,
      filterCategories: [],
      filterSubCategories: [],
      filterJurisdictions: [],
      filterCountries: [],
      filterLanguages: [],
      searchQuery: '',
      laywerSearchLoading: !this.props.lawyersByFilter.data,
      filtersApplied: false,
      action: 'link',
      page: 1,
      filterSorts: [
        { id: 'created_at', name: this.props.translate('lawyers.oldest') },
        { id: '-created_at', name: this.props.translate('lawyers.newest') },
      ],
      sort: '-created_at',
      selectedSort: '',
      pathParams: props.history.location.search,
      ...FILTERS,
    };
  }

  componentDidMount() {
    this.requestData();

    if (this.state.pathParams !== '') {
      let onlyParams = this.state.pathParams.split('?')[1];
      let currentItem = '';
      let selectedLanguage = '';
      let selectedCountry = '';
      let selectedCategory = '';
      let rating = null;
      let radius = 30;
      let searchQuery = '';
      let selectedSort = '';
      let filtersApplied = false;
      let page = 1;
      let selectedCategories = [];
      let selectedBar = '';
      let selectedJurisdictions = [];

      onlyParams.split('&').forEach(function (kv) {
        currentItem = kv.split('=', 2);

        if (currentItem[0] === 'page') {
          page = currentItem[1];
        }
        if (currentItem[0] === 'rating') {
          rating = currentItem[1];
          filtersApplied = true;
        }
        if (currentItem[0] === 'bar_verified') {
          selectedBar = currentItem[1];
          filtersApplied = true;
        }
        // if(currentItem[0] === "category") { selectedCategory = currentItem[1]; filtersApplied = true }
        if (currentItem[0].includes('categories')) {
          selectedCategories.push(parseInt(currentItem[1]));
          filtersApplied = true;
        }
        if (currentItem[0].includes('jurisdictions')) {
          selectedJurisdictions.push(parseInt(currentItem[1]));
          filtersApplied = true;
        }
        if (currentItem[0] === 'language') {
          selectedLanguage = currentItem[1];
          filtersApplied = true;
        }
        if (currentItem[0] === 'radius') {
          radius = currentItem[1];
          filtersApplied = true;
        }
        if (currentItem[0] === 'name') {
          searchQuery = currentItem[1].replace(/%20/g, ' ');
        }
        if (currentItem[0] === 'sort') {
          selectedSort = currentItem[1];
        }
      });
      this.setState({
        page,
        selectedCategory,
        selectedLanguage,
        rating,
        radius,
        searchQuery,
        filtersApplied,
        selectedCategories,
        selectedSort,
        selectedBar,
        selectedJurisdictions,
      });
      this.requestWithFilters({
        page: page,
        rating: rating,
        category: selectedCategory,
        language: selectedLanguage,
        radius: radius,
        name: searchQuery,
        categories: selectedCategories,
        bar_verified: selectedBar,
        sort: selectedSort,
        jurisdictions: selectedJurisdictions,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.reloadData || this.state.filtersApplied) {
      this.props.fetchLawyersByFilter(
        { page: this.state.page }
        // this.laywerSearchLoadingFunction.bind(this)
      );
    }
  }

  resetFilterState() {
    let { page, selectedCategory, selectedLanguage, radius, searchQuery, rating } = this.state;
    this.setState({ laywerSearchLoading: true, ...FILTERS });
    this.requestWithFilters({ category: '', language: '', name: searchQuery, page: page });
    this.setState({ action: 'nav', filtersApplied: false });
  }

  requestData() {
    if (this.props.topRatedLawyers.length === 0) {
      this.props.fetchTopRatedLawyers(this.topRatedLawyersStopLoading.bind(this));
    }
    if (!this.props.lawyersByFilter.data) {
      this.props.fetchLawyersByFilter({ page: this.state.page }, this.laywerSearchLoadingFunction.bind(this));
    }
    getCategories(this.fillCategories.bind(this));
    getLanguages(this.fillLanguages.bind(this));
    getCountries(this.fillCountries.bind(this));
    getSubCategories(this.fillSubCategories.bind(this));
    getJurisdictions(this.fillJurisdictions.bind(this));
  }

  fillCategories(data) {
    this.setState({
      filterCategories: data.data,
    });
  }

  fillLanguages(data) {
    this.setState({
      filterLanguages: data.data,
    });
  }

  fillSubCategories(data) {
    this.setState({
      filterSubCategories: data.data,
    });
  }

  fillCountries(data) {
    this.setState({
      filterCountries: data.data.lang,
    });
  }

  topRatedLawyersStopLoading() {
    // NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
    this.setState({
      topRatedLawyersLoading: false,
    });
  }

  fillJurisdictions(data) {
    this.setState({
      filterJurisdictions: data.data,
    });
  }

  displaySliderItems() {
    return this.props.topRatedLawyers?.data.map(i => {
      return <LawyerInfoWide id={i.id} name={i.name} key={i.id} imageSource={i.avatar} rating={i.rating} />;
    });
  }

  changeStateValues(event) {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  }

  handleOnChange = value => {
    this.setState({
      radius: value,
    });
  };

  searchInputFinishedWriting(input) {
    let {
      page,
      selectedCategory,
      selectedLanguage,
      radius,
      searchQuery,
      rating,
      selectedCategories,
      selectedSort,
      selectedBar,
      selectedJurisdictions,
    } = this.state;
    this.setState({ action: 'nav', searchQuery: input, laywerSearchLoading: true });
    input.length > 0 && this.setState({ reloadData: true });
    this.requestWithFilters({
      page: page,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: input,
      categories: selectedCategories,
      bar_verified: selectedBar,
      sort: selectedSort,
      jurisdictions: selectedJurisdictions,
    });
  }

  pressApplyFilters() {
    let {
      page,
      selectedCategory,
      selectedLanguage,
      radius,
      searchQuery,
      rating,
      selectedCategories,
      selectedBar,
      selectedSort,
      selectedJurisdictions,
    } = this.state;
    this.setState({ laywerSearchLoading: true, filtersApplied: true });
    this.requestWithFilters({
      page: 1,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: searchQuery,
      categories: selectedCategories,
      bar_verified: selectedBar,
      sort: selectedSort,
      jurisdictions: selectedJurisdictions,
    });
  }

  sortLawywers(sortSelected) {
    if (sortSelected == 'Oldest') {
      this.setState({ action: 'nav', sort: '-created_at' });
    } else if (sortSelected == 'Newest') {
      this.setState({ action: 'nav', sort: 'created_at' });
    }
  }

  requestWithFilters(filterParams) {
    this.props.fetchLawyersByFilter(filterParams, this.laywerSearchLoadingFunction.bind(this));
  }

  laywerSearchLoadingFunction(url) {
    let seperateParams = url.split('?')[1];

    if (this.state.action !== 'link') {
      this.props.history.push(`/lawyers?${seperateParams}`);
    }

    setTimeout(() => {
      this.setState({ laywerSearchLoading: false });
    }, 200);
  }

  displayLawyersResult() {
    let { translate } = this.props;

    if (this.state.laywerSearchLoading) {
      return (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type='spin' color='#bea58b' />
        </div>
      );
    } else if (this.props.lawyersByFilter.data) {
      if (this.props.lawyersByFilter.data.length > 0) {
        return (
          <div className='wal-results-wrap'>
            {this.props.lawyersByFilter.data.map(item => {
              return <LawyerCard item={item} key={item.id} reloadLawyers={() => this.setState({ reloadData: true })} />;
            })}
          </div>
        );
      } else {
        return (
          <EmptyResult
            icon='filters'
            title={translate('articles.no_results_found')}
            description={translate('articles.empty_description')}
          />
        );
      }
    }
  }

  requestNewPageData(e, page) {
    e.preventDefault();
    let {
      selectedCategory,
      selectedLanguage,
      radius,
      searchQuery,
      rating,
      selectedCategories,
      selectedBar,
      selectedJurisdictions,
    } = this.state;
    this.setState({ action: 'nav', laywerSearchLoading: true, page: page });
    let filterParams = {
      page: page,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: searchQuery,
      categories: selectedCategories,
      bar_verified: selectedBar,
      jurisdictions: selectedJurisdictions,
    };
    this.props.fetchLawyersByFilter(filterParams, this.laywerSearchLoadingFunction.bind(this));
  }

  changeStateMultiSelectedCategories(selectedItems) {
    let selectedIds = selectedItems.map(function (item) {
      return item.id;
    });
    this.setState({ selectedCategories: selectedIds });
  }

  changeSortBy(event) {
    let {
      page,
      selectedCategory,
      selectedLanguage,
      radius,
      searchQuery,
      rating,
      selectedCategories,
      selectedBar,
      selectedSort,
      selectedJurisdictions,
    } = this.state;
    const { name, value } = event.currentTarget;
    this.setState({ action: 'nav', selectedSort: value, laywerSearchLoading: true });
    this.requestWithFilters({
      page: page,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: searchQuery,
      categories: selectedCategories,
      bar_verified: selectedBar,
      sort: value,
      jurisdictions: selectedJurisdictions,
    });
  }

  changeStateMultiSelectedJurisdiction(selectedItems) {
    let selectedIds = selectedItems.map(function (item) {
      return item.id;
    });
    this.setState({ action: 'nav', selectedJurisdictions: selectedIds });
  }

  render() {
    let { translate } = this.props;
    let { radius } = this.state;

    const barOptions = [
      { id: 0, name: translate('lawyers.dont_show') },
      { id: 1, name: translate('lawyers.show_only') },
    ];

    return (
      <div>
        <title>{translate('lawyers.app_title')} | WAL</title>
        <main className='main'>
          <section className='rated-lawyers mg-top-180 pd-rl'>
            <div className='container-fluid'>
              <div className='main-title'>
                <h1>{translate('lawyers.top_rated_lawyers')}</h1>
              </div>
              <div className='rated-lawyers-wrap'>
                {this.state.topRatedLawyersLoading ? (
                  <div style={{ marginLeft: '48%' }}>
                    <ReactLoading type='spin' color='#bea58b' />
                  </div>
                ) : (
                  this.displaySliderItems()
                )}
              </div>
            </div>
          </section>

          <section className='wal-content mg-top-112 pd-rl'>
            <div className='container-fluid'>
              <div className='wal-content-head'>
                <div className='row align-items-center'>
                  <div className='col-md-12 col-lg-4'>
                    <div className='wal-content-title'>
                      <h1>
                        {translate('lawyers.all_lawyers')} <span>({this.props.lawyersByFilter.meta?.total})</span>
                      </h1>
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-4'>
                    <div className='wal-form wal-form-group wal-form-search'>
                      <input
                        type='search'
                        name='Search lawyer'
                        placeholder={translate('settings.order_history.filters.search_lawyer_placeholder')}
                        className='wal-form-control'
                        value={this.state.searchQuery}
                        onChange={event => this.searchInputFinishedWriting(event.target.value)}
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='none'
                      />
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-4'>
                    <SelectItem
                      field={{
                        styles: 'wal-form-sort',
                        label: translate('contracts.sort_by'),
                        name: 'selectedSort',
                        value: this.state.selectedSort,
                        onChange: this.changeSortBy.bind(this),
                        options: this.state.filterSorts,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='wal-content-body'>
                <div className='row'>
                  <ContentForm
                    selectedFields={[
                      // {label: translate('lawyers.categories'), name: "selectedCategory", value: this.state.selectedCategory, onChange: this.changeStateValues.bind(this), options: this.state.filterCategories},
                      {
                        label: translate('lawyers.languages'),
                        name: 'selectedLanguage',
                        value: this.state.selectedLanguage,
                        onChange: this.changeStateValues.bind(this),
                        options: this.state.filterLanguages,
                      },
                      {
                        label: translate('lawyers.admitted_to_the_bar'),
                        selectLabel: translate('lawyers.show_all'),
                        name: 'selectedBar',
                        value: this.state.selectedBar,
                        onChange: this.changeStateValues.bind(this),
                        options: barOptions,
                      },
                    ]}
                    multiSelect={
                      this.state.filterSubCategories.length > 0
                        ? {
                            items: this.state.filterSubCategories,
                            onChange: this.changeStateMultiSelectedCategories.bind(this),
                            currentSelected: this.state.selectedCategories,
                          }
                        : null
                    }
                    translate={translate}
                    radius={this.state.radius}
                    rateObject={{
                      rating: this.state.rating,
                      changeRating: value => this.setState({ rating: value }),
                    }}
                    rangeObject={{
                      radius: this.state.radius,
                      handleOnChange: this.handleOnChange.bind(this),
                    }}
                    pressApplyFilters={this.pressApplyFilters.bind(this)}
                    resetFilterState={this.resetFilterState.bind(this)}
                    filtersApplied={this.state.filtersApplied}
                    jurisdictionsMultiSelect={
                      this.state.filterJurisdictions.length > 0
                        ? {
                            items: this.state.filterJurisdictions,
                            onChange: this.changeStateMultiSelectedJurisdiction.bind(this),
                            currentSelected: this.state.selectedJurisdictions,
                          }
                        : null
                    }
                  />
                  <div className='col-md-12 col-lg-12 col-xl-8'>
                    <div className='wal-results'>{this.displayLawyersResult()}</div>
                    <PaginateItem
                      item={this.props.lawyersByFilter}
                      requestNewPageData={this.requestNewPageData.bind(this)}
                      searchLoading={this.state.laywerSearchLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <LawyersInYourRegion withSearchSection cover={'mg-top-112'} />
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    topRatedLawyers: state.topRatedLawyers,
    lawyersByFilter: state.lawyersByFilter,
    lawyersByRegion: state.lawyersByRegion,
    lawyersForMap: state.lawyersForMap,
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  fetchLawyersByRegion,
  fetchTopRatedLawyers,
  fetchLawyersByFilter,
})(Lawyers);
