import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { dataDeletionConfirmation } from '../../actions/auth';

const DataDeletion = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const uniqueCode = new URLSearchParams(window.location.search).get('unique_code');

  useEffect(() => {
    if (uniqueCode) {
      dataDeletionConfirmation(
        uniqueCode,
        () => setLoading(false),
        () => setError(true)
      );
    }
  }, []);

  return (
    <div>
      <title>Data deletion</title>
      <main className='main'>
        <section className='wal-content mg-top-112 pd-rl'>
          <div className='container-fluid'>
            <div className='data-deletion-container'>
              <h1 className='data-deletion-title'>Data deletion process:</h1>
              {loading ? (
                <ReactLoading type='spin' color='#bea58b' height={50} width={50} />
              ) : error ? (
                <h5 className='data-deletion-error'>
                  Something went wrong. Please refresh this page or check back later.
                </h5>
              ) : (
                <h5 className='data-deletion-text'>Data deleted successfully</h5>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DataDeletion;
