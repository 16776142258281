import React, { createContext, useContext, useEffect, useRef } from "react";
import Echo from "laravel-echo";
import { useStore, useSelector } from "react-redux";
import Pusher from "pusher-js";
import { getToken } from "../helpers/index";

import api from "../api/index";
import { BASE_DEV } from "../constants/types";

const config = {
  PUSHER_APP_KEY: "9a7d98as7da98d7",
  PUSHER_HOST: BASE_DEV, // BASE_APP
  PUSHER_PORT: 6001,
  PUSHER_CLUSTER: "mt1",
};

export const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const user = useSelector((s) => s.currentUser);
  let echo = useRef(null);
  const store = useStore();
  const callbacks = {
    onConnect: console.log,
    onDisconnect: console.log,
    onReconnect: console.log,
  };

  useEffect(() => {
    let token = getToken();
    if (!user || !token) {
      return;
      // return console.log("Not authenticated!");
    }

    if (echo.current != null) {
      return;
      // return console.log("Echo has already been initialized!");
    }

    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_CLUSTER,
      wsHost: config.PUSHER_HOST,
      wsPort: config.PUSHER_PORT,
      forceTLS: true,
      encrypted: true,
      wssPort: 443,
      authEndpoint: `https://${config.PUSHER_HOST}/api/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-App-ID": "456456",
        },
      },
      enabledTransports: ["ws", "wss", "flash"],
    });

    try {
      echo.current = new Echo({
        broadcaster: "pusher",
        client: pusher,
      });

      getEcho().connector.pusher.connection.bind("connected", (e) => {
        onConnect(e);
      });
      getEcho().connector.pusher.connection.bind("disconnected", (e) => {
        onDisconnect(e);
      });
      getEcho().connector.pusher.connection.bind("reconnected", (e) => {
        onReconnect(e);
      });

      privateChannel(`chat.conversations.${user?.id}`, {
        ".chat.conversations-update": "WS_CONVERSATION_UPDATE",
        ".chat.conversations-started": "WS_CONVERSATION_STARTED",
        ".chat.conversations-deleted": "WS_CONVERSATIONS_DELETED",
      });
    } catch (e) {
      console.log({ e });
      throw e;
    }
  }, [user?.id]);

  const onConnect = (e) => {
    api.interceptors.request.use((config) => {
      config["X-Socket-Id"] = e.socket_id;
      return config;
    });
    // callbacks.onConnect(e);
  };

  const onDisconnect = (e) => {
    callbacks.onDisconnect(e);
  };

  const onReconnect = (e) => {
    callbacks.onReconnect(e);
  };

  const getEcho = () => {
    if (!echo) {
      throw new Error("Echo is not initialized!");
    }

    return echo.current;
  };

  const privateChannel = (channel, events, whispers) => {
    const echo = getEcho();
    const privateChannel = echo.private(channel);
    for (let key in events) {
      privateChannel.listen(key, (data) => {
        chatDispatch(data, events[key]);
      });
    }
    for (let key in whispers) {
      privateChannel.listenForWhisper(key, (data) => {
        chatDispatch(data, whispers[key]);
      });
    }
  };

  const channel = (channel, events) => {
    const echo = getEcho();
    const publicChannel = echo.channel(channel);
    for (let key in events) {
      publicChannel.listen(key, (data) => {
        chatDispatch(data, events[key]);
      });
    }
  };

  const getChannel = (channel) => {
    const echo = getEcho();
    const isSubscribed = Object.keys(echo.connector.channels).includes(channel);
    if (!isSubscribed) {
      console.log("Channel does not exists", channel);
      return;
    }
    return echo.connector.channels[channel];
  };

  const leave = (channel) => {
    const echo = getEcho();
    echo?.leave(channel);
  };

  const chatDispatch = (data, action) => {
    store.dispatch({ type: action, data });
  };

  const getSocketId = () => {
    const socketId = getEcho().socketId();
    return socketId;
  };

  return (
    <ChatContext.Provider
      value={{
        echo,
        getEcho,
        getSocketId,
        leave,
        chatDispatch,
        getChannel,
        channel,
        privateChannel,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
