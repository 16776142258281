import React from 'react';
import MessageActions from "./MessageActions";
import moment from "moment";
import { imageUrl } from '../../../helpers';


class Image extends React.Component {
    render() {
        return (
            <div className={this.props.self ? "messages-item self" : "messages-item"}>
                <div className="messages-item-wrap">
                    <div className="messages-image">
                        <img src={imageUrl(this.props.body)} alt="media"/>
                    </div>
                    <div className="messages-time">
                        <span>{moment(this.props.created_at).fromNow(true)}</span>
                    </div>
                    <MessageActions {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Image
