import React from 'react';

import moment from "moment";

import MessageActions from "./MessageActions";

import DeletedMessage from "./DeletedMessage";

class Text extends React.Component {
    render() {
        return (
            <div className={this.props.self ? "messages-item self" : "messages-item"}>
                <div className="messages-item-wrap">
                    <div className="messages-content" style={(this.props.status === 'deleted') ? {background: "none"} : {}}>
                        {
                            this.props.status === 'deleted'
                                ? <DeletedMessage/>
                                : <p id={"message-text-" + this.props.id}>{this.props.body}</p>
                        }
                    </div>
                    <div className="messages-time">
                        <span>{moment(this.props.created_at).fromNow(true)}</span>
                    </div>
                    <MessageActions {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Text
