import React from "react";

import { getTranslate } from "react-localize-redux";

import { connect } from "react-redux";

import { getToken } from "../../../../helpers";

import { WEB_URL } from "../../../../constants/types";

import { Link } from "react-router-dom";

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  reloadPage() {
    let { subscription } = this.props;
    window.location.reload();
  }

  async requestToDownloadContract(e) {
    let { item } = this.props;
    e.preventDefault();
    window.open(`${WEB_URL}/contracts/${item.id}/download?token=${getToken()}`);

    setTimeout(function () {
      window.open(`${WEB_URL}/contracts/${item.id}/download-doc?token=${getToken()}`);
    }, 1000);
  }

  render() {
    let { item, subscription, translate, packageItem } = this.props;
    const isLoggedIn = getToken();

    return (
      <div className='payment-modal-wrap modal-ge-wrap'>
        <div className='modal-ge-content'>
          <div className='modal-ge-icon'>
            <i className='icon-done-check'></i>
          </div>
          <h1>{translate("payment.success")}</h1>
          {subscription ? (
            <p>
              {translate("payment.subscribe_success")} {this.props.plan && this.props.plan.name}
            </p>
          ) : packageItem ? (
            <>
              <p>{translate("payment.bought_package")}</p>
              <a href={`${WEB_URL}/packages/${packageItem.id}/download?token=${isLoggedIn}}`} className='button button-bl' onClick={() => window.$("#payment-modal").modal("toggle")}>
                <i className='icon-download'></i>
                {translate("packages.download_package")}
              </a>
            </>
          ) : (
            <>
              <p>
                {translate("payment.bought_contract")}
                <br />
                {translate("payment.wish_to_proceed")}
              </p>
              <a
                onClick={this.requestToDownloadContract.bind(this)}
                download
                className='button button-bl'
              >
                <i className='icon-download'></i>
                {translate("payment.download_and_use_contract")}
              </a>
            </>
          )}
        </div>
        <div className='modal-ge-close' data-dismiss='modal' onClick={() => this.reloadPage()}>
          <i className='icon-x'></i>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
  };
}

export default connect(mapStateToProps, {})(Success);
