import api from "../api";

import {
  CURRENT_USER_BOOKMARKS_LAWYERS,
  CURRENT_USER_BOOKMARKS_ARTICLES,
  CURRENT_USER_BOOKMARKS_CONTRACTS,
  CURRENT_USER_CONTRACTS,
  WEB_URL,
  EMPTY_STATE,
  USER_LAWYER_INTERACTIONS,
  PACKAGES_PURCHASED,
  PACKAGES_BOOKMARKED,
  LAWFIRMS_BOOKMARKED
} from "../constants/types";

import { getLanguageAsNumber } from "../services/storeLanguage";

import qs from "qs";

export function fetchUserContractsBookmarks(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-contracts?per_page=3&page=${currentPage}`;
    api
      .get(url)
      .then(data => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_CONTRACTS,
          payload: data.data.data,
        });
        addLastPage(data.data.meta.last_page);
      })
      .catch(err => {});
  };
}

export function fetchUserArticlesBookmarks(perPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-articles?per_page=${perPage}`;
    api
      .get(url)
      .then(data => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_ARTICLES,
          payload: data.data,
        });
      })
      .catch(err => {});
  };
}

export function fetchUserLawyersBookmarks(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-lawyers?per_page=3&page=${currentPage}`;
    api
      .get(url)
      .then(data => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_LAWYERS,
          payload: data.data.data,
        });
        addLastPage(data.data.meta.last_page);
      })
      .catch(err => {});
  };
}

export function fetchUserContracts(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/contracts-purchased?per_page=5&page=${currentPage}`;
    api
      .get(url)
      .then(data => {
        dispatch({
          type: CURRENT_USER_CONTRACTS,
          payload: data.data.data,
        });
        addLastPage(data.data.meta.last_page);
      })
      .catch(err => {});
  };
}

export function updateProfile(updateParams, avatar, stopLoading, handleErrors) {
  return async function (dispatch) {
    let url = `${WEB_URL}/profile`;

    var form = new FormData();

    if (avatar) {
      await form.append("avatar", avatar);
    }

    Object.keys(updateParams).map(async item => {
      await form.append(item, updateParams[item]);
    });

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      mimeType: "multipart/form-data",
      contentType: false,
    };

    api
      .post(url, form, config)
      .then(data => {
        stopLoading();
      })
      .catch(err => {
        handleErrors(err.data);
      });
  };
}

export function updatePassword(passwordParams, stopLoading, handleErrors) {
  return async function (dispatch) {
    let url = `${WEB_URL}/change_password`;

    api
      .post(url, qs.stringify(passwordParams))
      .then(data => {
        stopLoading();
      })
      .catch(err => {
        handleErrors(err.data);
      });
  };
}

export function getLawyerInteractions(perPage, stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/user-lawyer-interactions?language_id=${getLanguageAsNumber}&per_page=${perPage}`;
    api
      .get(url)
      .then(data => {
        dispatch({
          type: USER_LAWYER_INTERACTIONS,
          payload: data.data,
        });
        stopLoading();
      })
      .catch(err => {});
  };
}

export const getPurchasedPackages = (perPage, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/packages-purchased?per_page=${perPage}`;

    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGES_PURCHASED, payload: res.data });
        stopLoading();
      })
      .catch(err => {});
  };
};

export const getBookmarkedPackages = (perPage, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/bookmarks-packages?per_page=${perPage}`;

    await api
      .get(url)
      .then(res => {
        dispatch({ type: PACKAGES_BOOKMARKED, payload: res.data });
        stopLoading();
      })
      .catch(err => {});
  };
};

export const getBookmarkedLawfirms = (perPage, stopLoading) => {
  return async dispatch => {
    let url = `${WEB_URL}/bookmarks-companies?per_page=${perPage}`;

    await api
      .get(url)
      .then(res => {
        dispatch({ type: LAWFIRMS_BOOKMARKED, payload: res.data });
        stopLoading();
      })
      .catch(err => {});
  };
};

export function emptyRedux() {
  return async function (dispatch) {
    dispatch({
      type: EMPTY_STATE,
      payload: "",
    });
  };
}
