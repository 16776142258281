import React
  from 'react';

import Person
  from '../../assets/img/person-1.png'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { fetchContracts }
  from "../../actions/contractsActions";

import ContractCard
  from '../../components/contracts/ContractCard'

import ReactLoading
  from 'react-loading';

import { Link }
  from "react-router-dom";

class TopRatedContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractsLoading: true
    };
  }

  contractsStopLoading(){
    this.setState({contractsLoading:false})
  }

  displayContracts() {
    let { items, searchTopRatedLoading, selectedContract, reloadContracts } = this.props
    if(searchTopRatedLoading) {
      return (
        <div style={{ position: 'absolute', height: '50%', width: '100%',
                      display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }
    if(items && items.length > 0) {
      return (
        items.map((item) => {
          return (
            <div key={item.id} className="col-md-12 col-lg-12 col-xl-6">
              <ContractCard
                item={item}
                key={item.id}
                selectedContract={selectedContract}
                reloadContracts={reloadContracts}
              />
            </div>
          )
        })
      )
    } else {
      return (
        <div style={{ position: 'absolute', height: '20%', width: '100%',
                      display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p>Not found any contracts</p>
        </div>
      )
    }
  }

  render() {
    let { translate, viewMore } = this.props
    return(
      <section className={`rated-lawyers ${this.props.cover} pd-rl`}>
        <div className="container-fluid">
          <div className="main-title">
            <h1>{translate('contracts.top_rated_contracts')}</h1>
            { viewMore && <Link to={`/contracts`}>{translate("home.view_more")}</Link> }
          </div>
          <div className="row">
            {this.displayContracts()}
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            allContracts: state.allContracts
          })
}

export default connect(mapStateToProps, { fetchContracts })(TopRatedContracts);
