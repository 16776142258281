import React, { useEffect, useState, useCallback } from "react";
import { getTranslate } from "react-localize-redux";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import debounce from "lodash.debounce";

import { getTopLawfirms, getLawfirmsByFilter } from "../../../actions/lawfirmsActions";
import { getSubCategories, getLanguages } from "../../../helpers/getFilterData";

import LawfirmCard from "./LawfirmCard";
import PaginateItem from "../../../components/shared/PaginateItem";
import { ContentForm } from "../../../components/shared/filters";
import { TopLawfirms } from "../../../components/shared/sliders";
import { SelectItem } from "../../../components/shared/forms";

const LawFirms = () => {
  const dispatch = useDispatch();
  const translate = useSelector(s => getTranslate(s.locale));
  const topLawfirms = useSelector(s => s.topLawfirms);
  const filteredLawfirms = useSelector(s => s.filteredLawfirms);

  const [page, setPage] = useState(1);
  const [reloadData, setReloadData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [radius, setRadius] = useState(30);
  const [rating, setRating] = useState(null);
  const [selectedBar, setSelectedBar] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [filterLanguages, setFilterLanguages] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [selectedSort, setSelectedSort] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredLawfirmsLoading, setFilteredLawfirmsLoading] = useState(
    filteredLawfirms.length === 0
  );

  useEffect(() => {
    if (topLawfirms.length === 0) {
      dispatch(getTopLawfirms());
    }
    if (filteredLawfirms.length === 0) {
      dispatch(getLawfirmsByFilter({ sort: "-created_at" }, stopLoading));
    }
    getLanguages(fillLanguages);
    getSubCategories(fillSubCategories);
    if(reloadData || filtersApplied){
      return () => {
        dispatch(getTopLawfirms());
        dispatch(getLawfirmsByFilter({ sort: "-created_at" }, stopLoading));
      }
    }
  }, [reloadData, filtersApplied]);

  const stopLoading = () => {
    setFilteredLawfirmsLoading(false);
  };

  const fillLanguages = data => {
    setFilterLanguages(data.data);
  };

  const fillSubCategories = data => {
    setFilterSubCategories(data.data);
  };

  const requestNewPageData = (e, page) => {
    e.preventDefault();
    setPage(page);
    setFilteredLawfirmsLoading(true);
    let filterParams = filtersApplied
      ? {
          page,
          rating,
          radius,
          category: selectedCategory,
          language: selectedLanguage,
          name: searchQuery,
          categories: selectedCategories,
          bar_verified: selectedBar,
          jurisdictions: selectedJurisdictions,
        }
      : { page };
    dispatch(getLawfirmsByFilter(filterParams, stopLoading));
  };

  const requestWithFilters = params => {
    dispatch(getLawfirmsByFilter(params, stopLoading));
  };

  const pressApplyFilters = () => {
    setFiltersApplied(true);
    setFilteredLawfirmsLoading(true);
    requestWithFilters({
      page: 1,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: searchQuery,
      categories: selectedCategories,
      bar_verified: selectedBar,
      sort: selectedSort,
      jurisdictions: selectedJurisdictions,
    });
  };

  const resetFilterState = () => {
    setFilteredLawfirmsLoading(true);
    setSelectedCategory("");
    setRadius(30);
    setRating(null);
    setSelectedBar("");
    setSelectedLanguage("");
    setSelectedCategories([]);
    setSelectedJurisdictions([]);
    requestWithFilters({
      category: "",
      language: "",
      name: searchQuery,
      page: page,
    });
    setFiltersApplied(false);
  };

  const changeSortBy = event => {
    const { name, value } = event.currentTarget;

    setSelectedSort(value);
    setFilteredLawfirmsLoading(true);

    requestWithFilters({
      page: page,
      rating: rating,
      category: selectedCategory,
      language: selectedLanguage,
      radius: radius,
      name: searchQuery,
      categories: selectedCategories,
      bar_verified: selectedBar,
      sort: value,
      jurisdictions: selectedJurisdictions,
    });
  };

  const searchInputFinishedWriting = input => {
    input.length > 0 && setReloadData(true)
    setSearchQuery(input);
    searchInputFilter(input);
  };

  const searchInputFilter = useCallback(
    debounce(input => {
      setFilteredLawfirmsLoading(true);
      requestWithFilters({
        page: 1,
        rating: rating,
        category: selectedCategory,
        language: selectedLanguage,
        radius: radius,
        name: input,
        categories: selectedCategories,
        bar_verified: selectedBar,
        sort: selectedSort,
        jurisdictions: selectedJurisdictions,
      });
    }, 500),
    []
  );

  const changeStateValues = event => {
    const { value } = event.currentTarget;
    setSelectedLanguage(value);
  };

  const changeStateMultiSelectedCategories = selectedItems => {
    const selectedIds = selectedItems.map(item => item.id);
    setSelectedCategories(selectedIds);
  };

  const displayAllLawfirms = () => {
    const mappedLawfirms = filteredLawfirms.data.map(lawfirm => {
      return <LawfirmCard key={lawfirm.id} item={lawfirm} reloadLawfirms={() => setReloadData(true)} />;
    });
    return mappedLawfirms;
  };

  return (
    <>
      <title>{translate("header.tabs.lawfirms")} | WAL</title>
      <main className='main'>
        <section className='rated-lawyers mg-top-180 pd-rl'>
          <div className='container-fluid'>
            <div className='main-title'>
              <h1>{translate("lawfirms.topLawfirms")}</h1>
            </div>
            {topLawfirms.length > 0 ? (
              <TopLawfirms lawfirms={topLawfirms} translate={translate} />
            ) : (
              <div style={{ marginLeft: "48%" }}>
                <ReactLoading type='spin' color='#bea58b' />
              </div>
            )}
          </div>
        </section>

        <section className='wal-content mg-top-112 pd-rl'>
          <div className='container-fluid'>
            <div className='wal-content-head'>
              <div className='row align-items-center'>
                <div className='col-md-12 col-lg-4'>
                  <div className='wal-content-title'>
                    <h1>
                      {translate("header.tabs.lawfirms")}{" "}
                      <span>({filteredLawfirms.meta?.total})</span>
                    </h1>
                  </div>
                </div>
                <div className='col-md-6 col-lg-4'>
                  <div className='wal-form wal-form-group wal-form-search'>
                    <input
                      type='search'
                      name='Search lawfirm'
                      placeholder={translate("lawfirms.searchLawfirms")}
                      className='wal-form-control'
                      value={searchQuery}
                      onChange={event => {
                        searchInputFinishedWriting(event.target.value);
                      }}
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='none'
                    />
                  </div>
                </div>
                <div className='col-md-6 col-lg-4'>
                  <div className='wal-form wal-form-group wal-form-sort'>
                    <SelectItem
                      field={{
                        styles: "wal-form-sort",
                        label: translate("contracts.sort_by"),
                        name: "selectedSort",
                        value: selectedSort,
                        onChange: changeSortBy,
                        options: [
                          { id: "created_at", name: translate("lawyers.oldest") },
                          { id: "-created_at", name: translate("lawyers.newest") },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <ContentForm
                selectedFields={[
                  {
                    label: translate("lawyers.languages"),
                    name: "selectedLanguage",
                    value: selectedLanguage,
                    onChange: changeStateValues,
                    options: filterLanguages,
                  },
                ]}
                translate={translate}
                radius={radius}
                pressApplyFilters={pressApplyFilters}
                resetFilterState={resetFilterState}
                filtersApplied={filtersApplied}
                multiSelect={
                  filterSubCategories.length > 0 && {
                    items: filterSubCategories,
                    onChange: changeStateMultiSelectedCategories,
                    currentSelected: selectedCategories,
                  }
                }
                rateObject={{
                  rating: rating,
                  changeRating: value => setRating(value),
                }}
                rangeObject={{
                  radius: radius,
                  handleOnChange: value => setRadius(value),
                }}
              />
              <div className='col-md-12 col-lg-12 col-xl-8'>
                <div className='wal-results'>
                  {filteredLawfirmsLoading ? (
                    <div
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ReactLoading type='spin' color='#bea58b' />
                    </div>
                  ) : (
                    <div className='wal-results-wrap'>{displayAllLawfirms()}</div>
                  )}
                </div>
                {filteredLawfirms && (
                  <PaginateItem
                    item={filteredLawfirms}
                    requestNewPageData={requestNewPageData}
                    searchLoading={filteredLawfirmsLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wal-content mg-top-112 pd-rl">
                <div className="container-fluid">
                    <div className="wal-content-head">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-6">
                                <div className="wal-content-title">
                                    <h1>Lawfirms in your region</h1>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="wal-form wal-form-group wal-form-search">
                                    <input type="search" name="Search lawyer" placeholder="Search for Country, City, Location" className="wal-form-control" />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="wal-content-button">
                                    <a href="#" className="button button-primary">Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wal-content-body">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-4">
                                <div className="wal-results lawyers-region">
                                    <div className="wal-results-wrap">
                                        <div className="lawyers-item lawyers-item-region">
                                            <div className="lawyers-img lawyers-img-alt">
                                            </div>
                                            <div className="lawyers-content">
                                                <div className="lawyers-title">
                                                    <div className="lawyers-title-wrap">
                                                        <h1>Velocity9</h1>
                                                        <div className="verify-badge">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lawyers-info lawyers-info-region">
                                                    <div className="rated-stars">
                                                        <i className="icon-star-filled"></i>
                                                        <i className="icon-star-filled"></i>
                                                        <i className="icon-star-filled"></i>
                                                        <i className="icon-star-filled"></i>
                                                        <i className="icon-star-filled"></i>
                                                    </div>
                                                    <div className="lawyers-info-region-bottom">
                                                        <span className="distance">14 km</span>
                                                        <span className="type">Privates sowie öffentliches Arbeitsrecht</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lawyers-bookmarks">
                                                <a href="#"><i className="icon-bookmarks-filled"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-8">
                                <div className="lawyers-region-map">
                                    <div className="lawyers-region-map-button">
                                        <a href="#" className="button button-bl">Search on this area</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      </main>
    </>
  );
};

export default LawFirms;
