import React from 'react';

const WaleXplainedVideoPopup = ({ handleVideoClick }) => {
  return (
    <div>
      <div className='mfp-bg mfp-fade mfp-ready' />
      <div
        class='mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready'
        tabindex='-1'
        style={{ overflow: 'hidden auto' }}
      >
        <div class='mfp-container mfp-iframe-holder'>
          <div class='mfp-content'>
            <div class='mfp-iframe-scaler'>
              <div class='mfp-close-wrap'>
                <div title='Close (Esc)' class='mfp-close mfp-close-btn' onClick={() => handleVideoClick()}></div>
              </div>
              <iframe
                class='mfp-iframe'
                src='//www.youtube.com/embed/wIekLj5Eehs?autoplay=1'
                frameborder='0'
                allowfullscreen=''
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaleXplainedVideoPopup;
