import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-white.svg";
import {
  storeLanguage,
  getLanguage,
  getLanguageName,
  getLanguageFlag,
} from "../../services/storeLanguage";
import { getCurrency } from "../../services/storeCurrency";
import { changeLanguage } from "../../actions/languageAction";
import { getToken } from "../../helpers";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import ChangeCurrencyModal from "../../components/shared/modals/ChangeCurrencyModal";
import CURRENCIES from "../../constants/currencies";
import { ukFlag, deFlag, esFlag, trFlag, ptFlag, itFlag, frFlag } from "../../assets/img/flags";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeLanguage(lang) {
    storeLanguage(lang);
    this.props.changeLanguage(this.state.languageValue);
  }

  displayAuthLink() {
    let { translate } = this.props;
    if (getToken()) {
      return (
        <div className='footer-register'>
          <a onClick={() => this.props.logout()}>{translate("header.sign_out")}</a>
        </div>
      );
    } else {
      return (
        <div className='footer-register'>
          <p>{translate("footer.register_as")}:</p>
          <a
            href=''
            onClick={() => this.props.modalClick(1)}
            data-toggle='modal'
            data-target='#login'
          >
            {translate("footer.enduser")}
          </a>
          {/*<a href="">{translate('footer.lawyer')}</a>*/}
        </div>
      );
    }
  }

  render() {
    let { translate } = this.props;
    return (
      <footer className='footer mg-top-145 pd-rl'>
        <div className='container-fluid'>
          <div className='footer-top'>
            <div className='row'>
              <div className='col-md-12 col-lg-3 col-xl-4'>
                <div className='footer-info'>
                  {/* <img src={Logo} alt="logo" />
                            <p>{translate('footer.title')}</p> */}
                  <div className='footer-info-wrap'>
                    <img src={Logo} alt='logo' />
                    <p>{translate("footer.title")}</p>
                  </div>
                  <div className='footer-social'>
                    <ul>
                      <li>
                        <a href='https://www.facebook.com/WAL-101145718529076' target='_blank'>
                          <i className='icon-facebook-alt'></i>
                        </a>
                      </li>
                      <li>
                        <a href='https://www.instagram.com/wal_app_official' target='_blank'>
                          <i className='icon-instagram'></i>
                        </a>
                      </li>
                      <li>
                        <a href='https://www.linkedin.com/company/wal-app' target='_blank'>
                          <i className='icon-linkedin-alt'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='footer-website'>
                    <p>
                      <a href='https://www.apper.ch/'>{translate("footer.webapp_by")}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-6 col-md-4 col-lg-3 col-xl-3'>
                <div className='footer-links'>
                  <span>{translate("footer.links.title")}</span>
                  <ul>
                    <li>
                      <a href='https://landing.wal.world/legal-notice/' target='_blank'>
                        {translate("footer.links.legal_notice")}
                      </a>
                    </li>
                    <li>
                      <a href='https://landing.wal.world/privacy-policy/' target='_blank'>
                        {translate("footer.links.privacy_policy")}
                      </a>
                    </li>
                    {/* <li><a href="https://landing.wal.world/faqs/" target="_blank">{translate('footer.links.faq')}</a></li> */}
                    <li>
                      <a href='https://landing.wal.world/terms-and-conditions/' target='_blank'>
                        {translate("footer.links.terms_and_conditions")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-6 col-md-4 col-lg-3 col-xl-3'>
                <div className='footer-links'>
                  <span>{translate("footer.navigation.title")}</span>
                  <ul>
                    <li>
                      <Link to={`/`}>{translate("header.tabs.explore")}</Link>
                    </li>
                    <li>
                      <Link to={`/lawyers`}>{translate("header.tabs.lawyers")}</Link>
                    </li>
                    <li>
                      <Link to={`/lawfirms`}>{translate("header.tabs.lawfirms")}</Link>
                    </li>
                    <li>
                      <Link to={`/contracts`}>{translate("header.tabs.contracts")}</Link>
                    </li>
                    <li>
                      <Link to={`/knowledge`}>{translate("header.tabs.knowledge")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-4 col-lg-3 col-xl-2'>
                <div className='footer-links center'>
                  <span>{translate("footer.downloads.title")}</span>
                  <ul className='footer-downloads'>
                    <a
                      href='https://apps.apple.com/us/app/wal-app/id1521842254'
                      target='_blank'
                      className='button button-transparent button-transparent-border'
                    >
                      <i className='icon-appstore'></i>
                      {translate("footer.downloads.app_store")}
                    </a>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.walmobile'
                      target='_blank'
                      className='button button-transparent button-transparent-border'
                    >
                      <i className='icon-playstore'></i>
                      {translate("footer.downloads.play_store")}
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <div className='row'>
              <div className='col-md-12 col-lg-4 col-xl-4 footer-order'>
                <div className='footer-copyright'>
                  {/* <p>{translate('footer.copyright')}</p> */}
                  <p>Copyright © WAL International AG - 2022</p>
                </div>
              </div>
              <div className='col-md-12 col-lg-4 col-xl-4'>{this.displayAuthLink()}</div>
              <div className='col-md-12 col-lg-4 col-xl-4'>
                <div className='footer-bottom-right'>
                  <div className='footer-language'>
                    <div className='language-dropdown dropup'>
                      <div
                        data-toggle='dropdown'
                        className='language-dropdown-info'
                        data-display='static'
                      >
                        <div className='language-img'>
                          <img src={getLanguageFlag} alt='flag' />
                        </div>
                        <div className='language-dropdown-name'>
                          <span>{translate(`footer.languages.${getLanguage || 'en'}`)}</span>
                        </div>
                        <div className='dot'></div>
                      </div>
                      <ul className='dropdown-menu language-dropdown-menu'>
                        <li className={getLanguage == "en" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("en")}>
                            <img src={ukFlag} alt='' />
                            {translate("footer.languages.en")}
                          </a>
                        </li>
                        <li className={getLanguage == "de" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("de")}>
                            <img src={deFlag} alt='' />
                            {translate("footer.languages.de")}
                          </a>
                        </li>
                        <li className={getLanguage == "tr" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("tr")}>
                            <img src={trFlag} alt='' />
                            {translate("footer.languages.tr")}
                          </a>
                        </li>
                        <li className={getLanguage == "es" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("es")}>
                            <img src={esFlag} alt='' />
                            {translate("footer.languages.es")}
                          </a>
                        </li>
                        <li className={getLanguage == "it" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("it")}>
                            <img src={itFlag} alt='' />
                            {translate("footer.languages.it")}
                          </a>
                        </li>
                        <li className={getLanguage == "fr" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("fr")}>
                            <img src={frFlag} alt='' />
                            {translate("footer.languages.fr")}
                          </a>
                        </li>
                        <li className={getLanguage == "por" ? `active` : ""}>
                          <a href='' onClick={() => this.changeLanguage("por")}>
                            <img src={ptFlag} alt='' />
                            {translate("footer.languages.por")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='footer-currency'>
                    <a href='#' data-toggle='modal' data-target='#change-currency'>
                      <img src={CURRENCIES[getCurrency]?.image} />
                      <span>{CURRENCIES[getCurrency]?.name}</span>
                      <div className='dot'></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangeCurrencyModal />
      </footer>
    );
  }
}

export default connect(null, { logout, changeLanguage })(Footer);
