import React, { useCallback, useRef } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import ChatConversations from '../../../components/chat/ChatConversations';
import ChatMessageBox from '../../../components/chat/ChatMessageBox';
import * as chatActions from '../../../actions/chat';
import { purchasedContracts } from '../../../actions/contractsActions';

import ConfirmModal from '../../../components/chat/modals/ConfirmModal';
import ContractsModal from '../../../components/chat/modals/ContractsModal';
import ReportMessageModal from '../../../components/chat/modals/ReportMessageModal';
import { useEffect } from 'react';
import { useChatContext } from '../../../context/socket';

const Chat = props => {
  // const { conversation_id } = props.match.params;
  let conversation = useSelector(s => s.chat.conversation);
  let user = useSelector(s => s.currentUser);

  // const conversation = useSelector(chatActiveConversationSelector);

  const channel = useRef(null);
  const dispatch = useDispatch();
  const { leave, getEcho } = useChatContext();

  const getConversationMessages = useCallback(() => {
    if (conversation?.id && !conversation?.messages?.message_cache) {
      dispatch(chatActions.getConversation(conversation?.id));
      // dispatch(chatActions.selectConversation(conversation));
    }
  }, [conversation, dispatch]);

  const startListeningOnThisChat = useCallback(() => {
    if (conversation?.id) {
      channel.current = getEcho()?.private(`chat.conversation.${conversation?.id}.message`);

      channel?.current
        ?.listen('.chat.message-added', data => {
          if (data?.message?.participant?.user_id !== user.id) {
            dispatch(chatActions.addNewMessageToConversationSuccess(data.message));

            dispatch(
              chatActions.readConversationMessages({
                conversation_id: conversation?.id,
              })
            );
          }
        })
        .listen('.chat.message-deleted', data => {
          dispatch(chatActions.deleteMessageSuccess(data.message.conversation_id, data.message.id));
          // dispatch({ type: WS.WS_DELETE_MESSAGE, message: data });
        })
        .listenForWhisper('typing', data => {
          // dispatch(AC.isTyping(true));
        });

      dispatch(chatActions.setChannelId(conversation.id));
    }
  }, [dispatch, getEcho, conversation?.id]);
  const stopListeningOnThisChat = useCallback(() => {
    if (conversation?.id) {
      leave(`chat.conversation.${conversation?.id}.message`);
      dispatch(chatActions.setChannelId(null));
    }
  }, [dispatch, leave, conversation?.id]);

  useEffect(() => {
    getConversationMessages();
  }, [getConversationMessages]);
  useEffect(() => {
    startListeningOnThisChat();

    return () => {
      stopListeningOnThisChat();
    };
  }, [startListeningOnThisChat, stopListeningOnThisChat]);

  const onDeleteMessage = event => {
    event.preventDefault();

    props.deleteMessage(props.deleteData);
  };

  const onConversationDelete = event => {
    event.preventDefault();

    props.deleteConversation(props.deleteData);
  };

  const onReportMessage = data => {
    props.sendReport({
      ...props.reportData,
      ...data,
    });
  };

  return (
    <div>
      <title>Chat | Wal</title>
      <main className='main'>
        <section className='messages pd-rl'>
          <div className='messages-wrap'>
            <div className='container-fluid'>
              <div className='row'>
                <ChatConversations {...props} />
                {props.conversation !== null && <ChatMessageBox {...props} />}
              </div>
            </div>
          </div>
        </section>
      </main>

      <ConfirmModal
        open={props.messageDeleteModal}
        onCancel={props.closeMessageDeleteModal}
        onSuccess={onDeleteMessage}
        title={'Delete message?'}
        description={'Are you sure you want to delete message? Lawyer can see that you deleted something.'}
      />

      <ConfirmModal
        open={props.conversationDeleteModal}
        onCancel={props.closeConversationDeleteModal}
        onSuccess={onConversationDelete}
        title={'Delete chat?'}
        description={'Are you sure you want to delete chat? Lawyer can see that you deleted something.'}
      />

      <ContractsModal open={props.contractsModal} {...props} />

      <ReportMessageModal
        open={props.reportModal}
        onCancel={props.closeReportModal}
        onSuccess={onReportMessage}
        title={'Report Message'}
        description={
          'Please fill form on why you are reporting this message, and our team will look into it and update you via notification.'
        }
        reasons={props.reportReasons}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    ...state.chat,
    contracts: state.purchasedContracts,
  };
}

export default connect(mapStateToProps, { ...chatActions, purchasedContracts })(Chat);
