import {
  CURRENT_USER,
  LOG_OUT,
  SET_USER_FCM_TOKEN_SUCCESS,
} from "../constants";
import api from "../api";

const userReducer = (state = null, action) => {
  switch (action.type) {
    case CURRENT_USER:
      return { ...state, ...action.payload };
    case LOG_OUT:
      return null;
    case SET_USER_FCM_TOKEN_SUCCESS: {
      return {
        ...state,
        fcm_toke: action.payload.token,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
