import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomSelect }
  from '../../../components/shared/forms';

import VisaWhite
  from '../../../assets/img/visa-white.svg'

import MasterCardWhite
  from '../../../assets/img/master-card-white.svg'

class CardItem extends React.Component {

  render() {
    let { card } = this.props
    let { card_number, card_owner, exp_month, exp_year, id, type } = card
    return (
      <div className="wal-cards-item">
        <div className="wal-cards-icon">
          <i className={type = "Visa" ? "icon-visa-cc" : "icon-mastercard"}></i>
        </div>
        <div className="wal-cards-number">
          <span>Acc number</span>
          <h5>**** **** **** {card_number}</h5>
        </div>
        <div className="wal-cards-info">
          <span>Card holder</span>
          <h5>{card_owner}</h5>
        </div>
        <div className="wal-cards-date">
          <span>Expire date</span>
          <h5>{exp_month} / {exp_year}</h5>
        </div>
        <div className="wal-cards-delete">
          <a href="#" onClick={() => this.props.setDeleteItem(id)}>
            <i className="icon-delete"></i>
          </a>
        </div>
      </div>
    )
  }
};

export default CardItem;
