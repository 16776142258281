import React, {useState} from "react";

import ReactLoading from "react-loading";

import PackageCard from "../../pages/inside/packages/PackageCard";

const PackagesSection = props => {
  const packages = props.packages.data;
  const [selectedPackage, setSelectedPackage] = useState(null);

  return (
    <div className='tab-pane' id='packages'>
      <div className='panel panel-default'>
        <div className='panel-heading'>
          <h4 className='panel-title nav-list'>
            <a
              data-toggle='collapse'
              data-target='#collapse4'
              aria-expanded='false'
              data-parent='#tabs'
            >
              {props.translate("packages.packages")}
            </a>
          </h4>
        </div>
        <div id='collapse4' className='panel-collapse collapse' data-parent='#tabs'>
          <div className='panel-body nav-content'>
            {packages?.length > 0 ? (
              <>
                {packages?.map(item => (
                  <PackageCard item={item} key={item.id} translate={props.translate} />
                ))}
                {props.packages.meta?.total > packages?.length && (
                  <>
                    {props.loading ? (
                      <div style={{ marginLeft: "48%" }}>
                        <ReactLoading type='spin' color='#bea58b' />
                      </div>
                    ) : (
                      <a onClick={props.loadMore} className='more-button button button-gray'>
                        {props.translate("lawyers.details.load_more")}
                      </a>
                    )}
                  </>
                )}
              </>
            ) : (
              <div id='collapse3' className='panel-collapse collapse' data-parent='#tabs'>
                <div className='panel-body nav-content'>
                  <div className='wal-result-none'>
                    <div className='row justify-content-center'>
                      <div className='col-md-8 col-lg-6'>
                        <div className='wal-result-none-wrap'>
                          <div className='wal-result-none-icon'>
                            <i className='icon-packages'></i>
                          </div>
                          <h1>{props.translate("profile.no_packages_purchased")}</h1>
                          <p>{props.translate("profile.package_purchase_info")}</p>
                          <a href='/contracts' className='button button-white'>
                            {props.translate("profile.go_to_contract_and_templates")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesSection;
