import React from "react";

class RadioSelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipClicked: false,
    };
  }

  displayOptions() {
    let { mainObject, typeError, selectType, selectName, noIcon, tooltip, tooltipText } = this.props;
    return this.props.options.map((opt, index) => {
      return (
        <div key={index} className='col-md-6 col-lg-6'>
          <div className='wal-form-type'>
            <input
              type='radio'
              className='radio'
              name={selectName}
              value={opt.value}
              onChange={e => {}}
              checked={mainObject === opt.value ? true : false}
            />
            <label
              htmlFor='private'
              style={
                ({ borderColor: typeError ? "#d92344" : "" },
                this.props.options.length > 2 ? { marginBottom: "8px", textAlign: "center" } : {})
              }
              onClick={() => selectType(opt.value, false)}
            >
              {tooltip && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    selectType(opt.value, true);
                  }}
                  className='wal-form-warning'
                >
                  <i className='icon-warning-info'></i>
                  <div className='info-tooltip'>
                    <div className='info-tooltip-inner'>
                      {tooltipText && tooltipText}
                    </div>
                  </div>
                </div>
              )}
              {!noIcon && <i className={opt.logo}></i>}
              {opt.label}
            </label>
          </div>
        </div>
      );
    });
  }

  render() {
    let { textError, radioTitle, typeError, translate, requestParams, errors, selectType } =
      this.props;
    return (
      <div className='col-md-12 col-lg-12'>
        <div className='wal-form wal-form-group'>
          <label htmlFor='type'>{radioTitle}</label>
          <div className='row'>{this.displayOptions()}</div>
          {typeError && (
            <div style={{ marginTop: 10, marginLeft: "32%" }}>
              <span style={{ color: "#d92344", fontSize: 12 }}>{textError}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RadioSelectInput;
