import React from "react";
import moment
  from 'moment';

const NotFoundItems = (props) => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-5">
        <div className="wal-result-none-wrap messages-empty">
          <div className="wal-result-none-icon">
            <i className={`icon-${props.icon}`}></i>
          </div>
          <h1>{props.title}</h1>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  )
}

export default NotFoundItems;
