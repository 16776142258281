import React from "react";

import { getCurrency } from '../../../services/storeCurrency';

import Currencies from "../../../constants/currencies";

class ChatPaymentAlert extends React.Component {
  goToPayment() {
    window.$('#message-chat-alert').modal('toggle');
    setTimeout(function(){
      window.$('#chat-payment-modal').modal('toggle');
    }, 250);
  }

  render() {
    let { translate } = this.props
    let currentCurrency = Currencies[getCurrency].name.toUpperCase();
    return (
      <div className="modal fade modal-ge message-modal" id="message-chat-alert" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="message-modal-wrap modal-ge-wrap">
              <div className="modal-ge-content">
                <div className="modal-ge-icon">
                  <i className="icon-chat"></i>
                </div>
                <h1>{translate('modals.chat_payment_title')} 6 {currentCurrency}</h1>
                <p>{translate('modals.chat_payment_description')}</p>
                <div className="modal-ge-bottom-buttons">
                  <a href="#" className="button button-white" data-dismiss="modal">{translate('modals.cancel')}</a>
                  <a href="#" className="button button-bl" onClick={this.goToPayment.bind(this)}>{translate('modals.continue_to_pay')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default ChatPaymentAlert;
