import React
    from 'react';

import Person
    from '../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../components/auth'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

class DeleteAlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
  }

  render() {
    let { translate, title, description } = this.props
    let { active } = this.state
    return (
      <div className="modal fade modal-ge delete-modal" id="delete" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="payment-modal-wrap modal-ge-wrap">
              <div className="modal-ge-content">
                <div className="modal-ge-icon">
                  <i className="icon-delete"></i>
                </div>
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="modal-ge-bottom-buttons">
                  <a className="button button-white" data-dismiss="modal">{translate("settings.edit_profile.cancel")}</a>
                  <a className="button button-danger" onClick={(e) => this.props.confirmDelete(e)}>{translate("packages.delete")}</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(DeleteAlertModal);
