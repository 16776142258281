import React from "react";

import DefaultAvatar from '../../../assets/img/users.svg'
import {imageUrl} from "../../../helpers";
import moment from "moment";
import DeletedMessage from "../messages/DeletedMessage";


class ConversationRow extends React.Component {

    getLastMessage = (message) => {
        if (message.status === 'deleted') {
            return <DeletedMessage/>
        }

        if (message.type === 'text') {
            return <p>{message.body}</p>
        } else {

            return <p><i className="icon-image"></i> {message.type.charAt(0).toUpperCase() + message.type.slice(1)}</p>
        }
    }

    onConversationClick = (item) => {
        this.props.history.push('/chat/' + item.id)
        this.props.selectConversation(item)
    }

    onDeleteConversation = (event) => {
        this.props.openConversationDeleteModal({
            conversation_id: this.props.conversation?.id
        })
    }

    render() {
        const item = this.props.item

        const participant = item.participants[0];

        return (
            <li onClick={() => this.onConversationClick(item)}
                className={"messages-list-item" + (item.id === this.props.conversation?.id ? " open" : "") + (item.unread_count > 0 ? " unread" : "")}>
                <div className="messages-list-img">
                    {
                        participant?.user.avatar
                            ? <img src={imageUrl(participant?.user.avatar)} alt={participant?.user.name}/>
                            : <img src={DefaultAvatar} alt={participant?.user.name}/>
                    }
                    <div className={"status " + (participant?.user.is_online ? "active" : "inactive")}></div>
                </div>
                <div className="messages-list-content">
                    <div className="messages-list-info">
                        <div className="messages-list-title">
                            <h1>{participant?.user.title?.title} {participant?.user.name}</h1>
                        </div>
                        {
                            item.last_message && this.getLastMessage(item.last_message)
                        }
                    </div>
                    {
                        item.last_message &&
                        <div className="messages-list-time">
                            <span>{moment(item.last_message.created_at).fromNow(true)}</span>
                        </div>
                    }
                    <div className="messages-list-options more-options">
                        <div
                            className="more-options-icon"
                            data-toggle="dropdown"
                            data-display="static"
                            aria-expanded="true"
                        >
                            <a href="#">
                                <i className="icon-options"></i>
                            </a>
                        </div>
                        <ul className="dropdown-menu more-options-menu">
                            <li>
                                <a href="#">
                                    <i className="icon-notification-off"></i>Mute chat
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="icon-report-lawyer"></i>Report user
                                </a>
                            </li>
                            <li>
                                <a href="#" className="delete" onClick={this.onDeleteConversation}>
                                    <i className="icon-delete"></i>Delete chat
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="icon-error-circle"></i>Block user
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        );
    }
}

export default ConversationRow;
