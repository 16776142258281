import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function reportLawyer(reason, description, type, lawyerId) {
  let data = {
    "title":reason,
    "description":description,
    "entity_id": lawyerId,
    "entity_type": type
  }
  let url = `${WEB_URL}/reports`;

  api.post(url, qs.stringify(data))
    .then((data) => {
    })
    .catch((err) => {
    });
}
