import React from "react";
import walIcon from '../../assets/img/icons/icon-wal.svg';
import walIconColor from '../../assets/img/icons/icon-wal-color.svg';

const AuthButtonsSection = props => {
  let { translate } = props;

  const icon = window.location.pathname !== '/' ? walIconColor : walIcon;
  return (
    <>
      {/* <div className='header-about-us'>
        <a href='https://landing.wal.world/' target='_blank'>
          {translate("header.tabs.aboutus")} &nbsp;&nbsp;↗
        </a>
      </div> */}
      <div className='header-button'>
        <a href='https://landing.wal.world/' target='_blank' className='link-about'>
          <img src={icon} alt='icon-wal' />
          <div className='info-tooltip'>
            <div className='info-tooltip-inner'>{translate("header.tabs.clickHereAboutApp")}</div>
          </div>
        </a>
        <a
          href=''
          className='button button-transparent button-login'
          onClick={() => props.modalClick(0)}
          data-toggle='modal'
          data-target='#login'
        >
          {translate("header.tabs.buttons.login")}
        </a>
        <a
          href=''
          className='button button-primary button-register'
          onClick={() => props.modalClick(1)}
          data-toggle='modal'
          data-target='#login'
        >
          <i className='icon-plus'></i>
          {translate("header.tabs.buttons.register")}
        </a>
      </div>
    </>
  );
};

export default AuthButtonsSection;
