import React from "react";

import Image
  from '../../components/shared/Image'

class MapDisabled extends React.Component {
  render() {
    let { translate } = this.props
    return (
      <>
        <div className="wal-content-head">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6">
              <div className="wal-content-title">
                <h1>{translate("shared.lawyers_in_your_region")}</h1>
              </div>
            </div>
            {/*<div className="col-md-6 col-lg-4">
              <div className="wal-form wal-form-group wal-form-search">
                <input type="search" name="Search lawyer" placeholder="Search for Country, City, Location" className="wal-form-control" />
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="wal-content-button">
                <a href="#" className="button button-primary">Search</a>
              </div>
            </div>*/}
          </div>
        </div>
        <div className="wal-content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-4">
              <div className="wal-results lawyers-region">
                <div className="wal-results-wrap">
                  <div className="lawyers-item lawyers-item-region lawyers-item-region-disabled"></div>
                  <div className="lawyers-item lawyers-item-region lawyers-item-region-disabled"></div>
                  <div className="lawyers-item lawyers-item-region lawyers-item-region-disabled"></div>
                  <div className="lawyers-item lawyers-item-region lawyers-item-region-disabled"></div>
                  <div className="lawyers-item lawyers-item-region lawyers-item-region-disabled"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-8">
              <div className="lawyers-region-map">
                <div className="lawyers-region-map-disabled">
                  <div className="col-md-8 col-lg-6">
                    <div className="wal-result-none-wrap">
                      <div className="wal-result-none-icon">
                          <i className="icon-locations"></i>
                      </div>
                      <h1>{translate("shared.map_enable_title")}</h1>
                      <p>{translate("shared.map_enable_description")}</p>
                      {/*<button className="button button-bl">Enable location</button>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MapDisabled
