import React from "react";
import FlagCh from '../../../assets/img/flag-ch.svg'
import FlagEu from '../../../assets/img/flag-eu.svg'
import FlagUS from '../../../assets/img/flag-us.svg'
import Currencies from "../../../constants/currencies";
import api from '../../../api'
import { WEB_URL } from "../../../constants/types"
import { storeCurrency, getCurrency }
  from '../../../services/storeCurrency'
import { getTranslate }
  from 'react-localize-redux';
import { connect }
  from 'react-redux';

class ChangeSubscriptionCurrencyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: getCurrency,
      currencyToSend: Currencies[getCurrency].name
    };
  }

  changeCurrency(valOne, valTwo) {
    this.setState({currency: valOne})
    this.setState({currencyToSend: valTwo})
  }

  saveCurrency() {
    let { currency } = this.state
    api.post(`${WEB_URL}/change-currency`, {currency: this.state.currencyToSend.toUpperCase()})
    .then(res => {
        window.location.reload()
        storeCurrency(currency)
    })
    .catch(err => {
        // console.log(err)
    })
    
    
  }

  closeModalHandler() {
    window.$('#change-subscription-currency').modal('toggle');   
    setTimeout(function () {
        window.$('#subscription-company').modal('toggle');
    }, 400);
  }

  render() {
    let { translate } = this.props
    let { currency } = this.state
    return (
      <div className="modal fade modal-ge currency-modal" id="change-subscription-currency" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="currency-modal-wrap modal-ge-wrap">
              <div className="modal-ge-top payment-modal-top">
                <h1>{translate("shared.select_currency")}</h1>
              </div>
              <div className="modal-ge-body currency-modal-body">
                <div className={`currency-modal-item ${currency == "ch" && 'active'}`} onClick={() => this.changeCurrency("ch", "chf")}>
                  <div className="currency-modal-img">
                    <img src={FlagCh} />
                  </div>
                  <div className="currency-modal-content">
                    <h1>Switzerland</h1>
                    <span>CHF- FR</span>
                  </div>
                </div>
                <div className={`currency-modal-item ${currency == "eu" && 'active'}`} onClick={() => this.changeCurrency("eu", "eur")}>
                  <div className="currency-modal-img">
                    <img src={FlagEu} />
                  </div>
                  <div className="currency-modal-content">
                    <h1>Europe</h1>
                    <span>€ - Euro</span>
                  </div>
                </div>
                <div className={`currency-modal-item ${currency == "us" && 'active'}`} onClick={() => this.changeCurrency("us", "usd")}>
                  <div className="currency-modal-img">
                    <img src={FlagUS} />
                  </div>
                  <div className="currency-modal-content">
                    <h1>United States Dollar</h1>
                    <span>$ - USD</span>
                  </div>
                </div>
              </div>
              <div className="modal-ge-bottom">
                <button className="button button-primary" onClick={this.saveCurrency.bind(this)}>{translate("shared.done")}</button>
              </div>
              <div className="modal-ge-close" data-dismiss="modal" onClick={() => this.closeModalHandler()}><i className="icon-x"></i></div>        
            </div>
          </div>
        </div>
      </div>
    )
  }
};


function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
          })
}

export default connect(mapStateToProps, { })(ChangeSubscriptionCurrencyModal);
